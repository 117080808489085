import React from "react";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import Portfolio from "../../components/Portfolio";
import { useDocumentTitle } from "../../hooks/setDocumentTitle";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDefaultChainId } from "config/useDefaultChainId";


export default function DashBoard() {
  const chainId = useDefaultChainId();
  useDocumentTitle("Dashboard");

  return (
    <BaseLayout
    title={"list"}
    >
    <Portfolio />

    </BaseLayout>
  );
}
