import React from "react";
import chartImage from "./images/homeV2/tokenomic.png"
import statisticImage from "./images/icons/steps.png"
import "./animate.css";
import "./responsive.css";

export default function Tokenomics() {
  return (
    <div class="Tokenomics_section main-project-area">
            <div class="container">
                <div class="sec-inner align-items-center mb-30">
                    <div class="sec-heading">
                        <div class="flex  sub-inner mb-15">
                            <span class="sub-title">statistics</span>
                            <img class="heading-left-image" src={statisticImage} alt="Steps-Image" />
                        </div>
                        <h2 class="title mb-0">Tokenomics</h2>
                    </div>
                    <div class="TokenomicsContents">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="TokenomicsContents wow fadeInLeft" data-wow-delay="0.2s" data-wow-duration="0.4s">
                                <h3 class="counter-number">Token Name :  <span class="counter">BULLPAD TOKEN</span></h3>
                                <h3 class="counter-number">Token Symbol :  <span class="counter">BULL</span></h3>
                                    <h3 class="counter-number">Total Supply :  <span class="counter">1,000,000,000 BULL</span></h3>
                                    {/*<h3 class="counter-number">Listing Price :  $<span class="counter">0.002</span></h3>*/}

                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="TokenomicsContentsright wow fadeInRight" data-wow-delay="0.2s" data-wow-duration="0.4s">
                                    <div>
                                        <img src="/images/tokenomics.png" alt="img" class="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
    }
