import React, { useContext, useState } from "react";
import PreviewHeader from "../../Common/PreviewHeader";
import Info from "./Subcomponents/Info";
import TabSwitch from "./Subcomponents/TabSwitch";
import DonutChart from "./Subcomponents/DonutChart";
import TwitterSVG from "svgs/Socials/twitter";
import DribbleSVG from "svgs/Socials/dribble";
import PreviewDetails from "components/Common/PreviewDetails";
import DiscordSVG from "svgs/Socials/discord";
import TelegramSVG from "svgs/Socials/telegram";
import AuditSVG from "svgs/Socials/audit";
import KycSVG from "svgs/Socials/kyc";
import { formatBigToNum } from "utils/numberFormat";
import { Link } from "react-router-dom";
import GithubSVG from "svgs/Socials/github";
import { ThemeContext } from "context/ThemeContext/ThemeProvider";
import YouTubeEmbed from "./Subcomponents/YoutubeImbed";
import ProjectDetails from "../CreateSale/ProjectDetails";
import ConfirmModal from "../Admin/subComponents/ConfirmModal";

export default function Preview({
  pool,
  icon,
  name,
  saleType,
  currency,
  is_private,
  token,
  tags,
  tags2,
  description,
  address,
  starts_on,
  ends_on,
  soft_cap,
  hard_cap,
  first_release,
  unsold_tokens,
  presalePrice,
  fairAmount,
  listingPrice,
  liquidity,
  lockup,
  percent1,
  percent2,
  percent3,
  percent4,
  tgeRelaseDate,
  releaseDate1,
  releaseDate2,
  releaseDate3,
  releaseDate4,
  objId,
  admin,
  finished,
}) {
  const { theme } = useContext(ThemeContext);
  const [slide, setSlide] = useState("Info");
  const [edit, setEdit] = useState(false);
  const [saleData, setSaleData] = useState({ ...pool });
  const [showModal, setShowModal] = useState(false);
  const supply = parseFloat(token.tokenSupply) / 10 ** token.tokenDecimals;
  return (
    <>
      {edit ? (
        <ProjectDetails
          setActive={setEdit}
          saleData={saleData}
          setSaleData={setSaleData}
          edit={edit}
          objId={objId}
        />
      ) : (
        <div className="px-9 py-9 relative ">
          <div className="flex flex-col">
          <div className="overflow-hidden ml-auto md:mb-0 mb-2">
            {tags2 && tags2?.split(",").map(
              (tag) =>
                tag !== "" &&
                tag === "Migration" ? (
                  <span
                    key={tag}
                    className="absolute text-sm right-[-60px] top-[0px] w-[170px] transform rotate-45 dark:bg-red-600 bg-pink-500 font-bold text-center text-white py-0"
                  >
                    {tag}
                  </span>
                ) : tag === "KYC" ? (
                  <span
                    key={tag}
                    className="text-[10px] font-bold bg-primary-green py-[2px] px-2 text-white rounded-[5px] mr-2 "
                  >
                    {tag}
                  </span>
                ) : tag === "SAFU" ? (
                  <span
                    key={tag}
                    className="text-[10px] font-bold bg-purple-400 py-[2px] px-2 text-white rounded-[5px] mr-2 "
                  >
                    {tag}
                  </span>
                ) : tag === "AUDIT" ? (
                  <span
                    key={tag}
                    className="text-[10px] font-bold bg-blue-400 py-[2px] px-2 text-white rounded-[5px] mr-2 "
                  >
                    {tag}
                  </span>
                ): null
            )}

          </div>

          <img
            src={pool.banner || "https://cdna.artstation.com/p/assets/images/images/060/746/238/original/feon-cityundermoon-pixel-art.gif"}
            className="w-full h-[150px] border border-2px border-black rounded-[20px]"
            />

          <Info
            name={name}
            icon={icon}
            tags={tags}
            pool={pool}
            setEdit={setShowModal}
            edit={showModal}
            admin={admin}
          />
          </div>
          {/*{showModal && (
            <ConfirmModal
              runFunction={setEdit}
              description={"Edit sale details?"}
              title={"Edit Sale"}
              setShowModal={setShowModal}
            />
          )} */}

          <div className="mt-6 flex md:hidden gap-5 ml-[70px]">
            {pool.github !== "" && (
              <Link to={pool.github} target="_blank" rel="noopener noreferrer">
                <GithubSVG
                  className="w-5 h-5"
                  outer={`${theme === "dark" ? "#fff" : "#464754"}`}
                  inner={`${theme === "dark" ? "#464754" : "#fff"}`}
                />
              </Link>
            )}
            {pool.discord !== "" && (
              <Link to={pool.discord} target="_blank" rel="noopener noreferrer">
                <DiscordSVG className="fill-dark-text dark:fill-light-text " />
              </Link>
            )}
            {/*pool.kyc !== "" && (
              <Link to={pool.kyc} target="_blank"rel="noopener noreferrer">
                <KycSVG className="fill-dark-text dark:fill-light-text " />
              </Link>
            )}
            {pool.audit !== "" && (
              <Link to={pool.audit} target="_blank" rel="noopener noreferrer">
                <AuditSVG className="fill-dark-text dark:fill-light-text " />
              </Link>
            )*/}
            {pool.telegram !== "" && (
              <Link
                to={pool.telegram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TelegramSVG className="fill-dark-text dark:fill-light-text " />
              </Link>
            )}
            {pool.twitter !== "" && (
              <Link to={pool.twitter} target="_blank" rel="noopener noreferrer">
                <TwitterSVG className="fill-dark-text dark:fill-light-text" />
              </Link>
            )}{" "}
            {pool.website !== "" && (
              <Link to={pool.website} target="_blank" rel="noopener noreferrer">
                <DribbleSVG className="fill-dark-text dark:fill-light-text " />
              </Link>
            )}
          </div>

          <TabSwitch slide={slide} setSlide={setSlide} />

          {slide === "Info" && (
            <div className="mt-5">
            <div className="mt-10">
              <span className="font-medium text-sm text-gray dark:text-gray-dark">
                {description}
                {pool.youtube && pool.youtube !== "" && (
                  <YouTubeEmbed embedCode={pool.youtube} />
                )}
              </span>
            </div>
            </div>
          )}

          {slide === "Presale" && (
            <div className="mt-5">
              <PreviewDetails
                name={"Presale Address"}
                value={address}
                enable_copy
                address={true}
              />
              <PreviewDetails
                name={"Presale Starts on"}
                value={new Date(starts_on * 1000)
                  .toUTCString()
                  .replace(" GMT", " UTC")}
              />
              <PreviewDetails
                name={!finished? "Presale Ends on" : "Presale Ended on"}
                value={new Date(ends_on * 1000)
                  .toUTCString()
                  .replace(" GMT", " UTC")}
              />
              <PreviewDetails
                name={"Soft Cap"}
                value={
                  soft_cap && soft_cap.toLocaleString() + " " + currency.symbol
                }
              />
              {(pool.saleType === "private" || pool.saleType === "public" || pool.saleType === "publicvesting" ) && (
                <PreviewDetails
                  name={"Hard Cap"}
                  value={
                    hard_cap && hard_cap.toLocaleString() + " " + currency.symbol
                  }
                />
              )}
              {(pool.saleType === "public" || pool.saleType === "fairlaunch" || pool.saleType === "publicvesting" ) && unsold_tokens && (
                <PreviewDetails name={"Unsold Tokens"} value={unsold_tokens} />
              )}
              {(pool.saleType === "public" || pool.saleType === "fairlaunch" || pool.saleType === "publicvesting" ) && (
              <PreviewDetails
                name={"To be listed on"}
                value={pool.dex.name}
                icon={pool.dex.icon}
              />
              )}
              {(pool.saleType === "public" || pool.saleType === "publicvesting" ) && liquidity && (
                <PreviewDetails
                  name={"Tokens for Liquidity"}
                  value={(listingPrice * (liquidity / 100) * hard_cap ).toLocaleString() + " " + token.tokenSymbol}
                />
              )}
              {pool.saleType === "fairlaunch" && (
                <PreviewDetails
                  name={"Tokens for Sale"}
                  value={
                    (
                      presalePrice  ) +
                    " " +
                    token.tokenSymbol
                  }
                />
                )}
              {hard_cap && presalePrice && (
                <PreviewDetails
                  name={"Tokens for Presale"}
                  value={
                    (hard_cap * presalePrice).toLocaleString() +
                    " " +
                    token.tokenSymbol
                  }
                />
              )}
              {liquidity && (
                <PreviewDetails
                  name={"Liquidity (%)"}
                  value={liquidity + "%"}
                />
              )}
              {lockup && (
                <PreviewDetails
                  name={"Liquidity Lockup Time"}
                  value={new Date(lockup * 1000)
                    .toUTCString()
                    .replace(" GMT", " UTC")}
                />
              )}

              <PreviewHeader heading={"Vesting Details"} />

              {pool.saleType === "publicvesting"  && first_release && ends_on && (
                <PreviewDetails
                name={"First Release"}
                value={`${first_release}%, After Sale Finalize`}
                />
              )}
              {pool.saleType === "private" && first_release && ends_on && (
                <PreviewDetails
                name={"First Release"}
                value={`${first_release}%, ${new Date(releaseDate1 * 1000).toUTCString().replace(" GMT", " UTC")}`}
                />
              )}
              {(pool.saleType === "private" || pool.saleType === "publicvesting" ) && percent1 && releaseDate1 && (
                <PreviewDetails
                  name={"Round 1 & Release Time"}
                  value={`${percent1}% , ${new Date(releaseDate1 * 1000).toUTCString().replace(" GMT", " UTC")}`}
                />
              )}
              {(pool.saleType === "private" || pool.saleType === "publicvesting" ) && percent2 && releaseDate2 && (
                <PreviewDetails
                  name={"Round 2 & Release Time"}
                  value={`${percent2}% , ${new Date(releaseDate2 * 1000).toUTCString().replace(" GMT", " UTC")}`}
                />
              )}
              {(pool.saleType === "private" || pool.saleType === "publicvesting" ) && percent3 && releaseDate3 && (
                <PreviewDetails
                  name={"Round 3 & Release Time"}
                  value={`${percent3}% , ${new Date(releaseDate3 * 1000).toUTCString().replace(" GMT", " UTC")}`}
                />
              )}
              {(pool.saleType === "private" || pool.saleType === "publicvesting" ) && percent4 && releaseDate4 && (
                <PreviewDetails
                  name={"Round 4 & Release Time"}
                  value={`${percent4}% , ${new Date(releaseDate4 * 1000).toUTCString().replace(" GMT", " UTC")}`}
                />
              )}
            </div>
          )}
          {slide === "Token" && (
            <div className="mt-5">
              <PreviewDetails name={"Token Name"} value={token.tokenName} />
              <PreviewDetails name={"Token Symbol"} value={token.tokenSymbol} />
              <PreviewDetails
                name={"Token Decimals"}
                value={token.tokenDecimals}
              />
              <PreviewDetails
                name={"Total Supply"}
                value={
                  token.tokenSupply &&
                  formatBigToNum(token.tokenSupply, token.tokenDecimals)
                }
              />
              <PreviewDetails
                name={"Token Address"}
                value={token.tokenAddress}
                enable_copy={true}
                address={true}
              />

              <div className="mt-10">
                <span className="font-semibold text-dark-text dark:text-light-text">
                  Token Metrics
                </span>
                <div className="flex items-center mt-7">
                  <div className="w-full ">
                    {(pool.saleType === "public" || pool.saleType === "publicvesting" ) && (
                      <DonutChart
                        presale={pool.presalePrice * hard_cap}
                        liquidity={pool.listing * (liquidity / 100) * hard_cap}
                        supply={supply}
                        burned={pool.burned || 0}
                        locked={pool.locked || 0}
                        sale={pool || 0}
                      />
                    )}
                      {pool.saleType === "private" && (
                        <DonutChart
                          presale={pool.presalePrice * hard_cap}
                          supply={supply}
                          sale={pool || 0}
                        />
                      )}
                    {pool.saleType === "fairlaunch" && (
                      <DonutChart
                        presale={pool.presalePrice}
                        liquidity={pool.presalePrice * (liquidity / 100)}
                        supply={supply}
                        burned={pool.burned || 0}
                        locked={pool.locked || 0}
                        sale={pool || 0}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
