import Options from 'components/LockedAsset/Preview/Subcomponents/Options'
import { ThemeContext } from 'context/ThemeContext/ThemeProvider'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import DribbleSVG from 'svgs/Socials/dribble'
import GithubSVG from 'svgs/Socials/github'
import TelegramSVG from 'svgs/Socials/telegram'
import WhitePaperSVG from 'svgs/Socials/whitepaper'
import TwitterSVG from 'svgs/Socials/twitter'
import DiscordSVG from 'svgs/Socials/discord'
import { useDefaultChainId } from "config/useDefaultChainId";
import baseLogo from "./Logo/Base.png";
import coreLogo from "./Logo/core.png";
import vanarLogo from "./Logo/Vanar.png";

export default function Info({ icon1, icon2, name1, name2, is_private, currency, tags, stake, setEdit,edit,admin }) {
  const { theme } = useContext(ThemeContext);
  const chainId = useDefaultChainId();

  return (
    <div className="items-center">
      <div className="items-center justify-center gap-2">
      <div className="flex items-center justify-center">
        <img src={icon1} alt={name1} className="w-[55px] h-[55px]  -translate-y-1/2 rounded-[50px] border border-solid border-2px border-black relative z-0"/>
        <img src={icon2} alt={name2} className="w-[55px] h-[55px] -translate-y-1/2 rounded-[50px] border border-solid border-2px border-black relative z-0" />
        </div>
        <div className="mt-[-20px] items-center justify-center">
          {stake.chainID === 1116 && stake.image1 && (
            <span className="flex items-center justify-center text-[12px] font-bold">
              <img className="w-4 h-4" src={coreLogo} alt="Currency Icon" />
              &nbsp;CORE
            </span>
          )}
          {stake.chainID === 2040 && stake.image1 && (
            <span className="flex items-center justify-center text-[12px] font-bold">
              <img className="w-4 h-4" src={vanarLogo} alt="Currency Icon" />
              &nbsp;VANAR
            </span>
          )}
        </div>
        <div className="flex items-center justify-center gap-2">
          <div className="flex items-center">
            <h3 className=" text-lg font-bold text-black">Stake {name1} Earn {name2}</h3>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-center gap-2">
          {stake.github !== "" &&
          <Link to={stake.github} target="_blank" rel="noopener noreferrer" >
          <GithubSVG
            className="w-5 h-5 hidden md:block  "
            outer={`${theme === "dark" ? "#fff" : "#464754"}`}
            inner={`${theme === "dark" ? "#464754" : "#fff"}`}
          />
          </Link>
          }
        {stake.twitter !== "" &&
        <Link to={stake.twitter} target="_blank" rel="noopener noreferrer" >
          <TwitterSVG className="fill-dark-text dark:fill-light-text hidden md:block" />
          </Link>
        }
        {stake.website !== "" &&
        <Link to={stake.website} target="_blank" rel="noopener noreferrer" >
        <DribbleSVG className="fill-dark-text dark:fill-light-text hidden md:block" />
        </Link>
        }
        {stake.whitepaper !== "" &&
        <Link to={stake.whitepaper} target="_blank" rel="noopener noreferrer" >
        <WhitePaperSVG className="fill-dark-text dark:fill-light-text hidden md:block" />
        </Link>
        }
        {stake.telegram !== "" &&
        <Link to={stake.telegram} target="_blank" rel="noopener noreferrer" >
          <TelegramSVG className="fill-dark-text dark:fill-light-text hidden md:block" />
          </Link>
        }
        {stake.discord !== "" &&
        <Link to={stake.discord} target="_blank" rel="noopener noreferrer" >
          <DiscordSVG className="fill-dark-text dark:fill-light-text hidden md:block" />
          </Link>
        }
      </div>
    </div>
  )
}
