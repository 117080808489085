// import { BSC } from '@usedapp/core'
import { Vanar, CoreChain } from './constants/testchain'

const checkNetwork = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`);
    // console.log(`Successfully connected to ${url}`);
  } catch (error) {
    // console.error(`Failed to connect to ${url}:`, error);
  }
};

export const getNetworkConfig = () => {
  const urls = {
    [CoreChain.chainId]: 'https://1rpc.io/core',
    [Vanar.chainId]: "https://rpc-partners.vanarchain.com",
  };

  Object.values(urls).forEach(checkNetwork);

  return {
    readOnlyChainId: Vanar.chainId,
    readOnlyUrls: urls,
    networks: [Vanar, CoreChain],
    noMetamaskDeactivate: true,
    refresh: 'never',
    pollingInterval: 15000,
  };
};
