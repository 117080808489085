import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ConnectButton from './ConnectButton';
import NetworkDropdown from './NetworkDropdown';
import BurgerIcon from 'svgs/Topbar/Burger';
import CloseIcon from 'svgs/Topbar/Close';
import TwitterSVG from 'svgs/Socials/twitter';
import TelegramSVG from 'svgs/Socials/telegram';
import './Menu.css';
import { useRBAPrice } from 'hooks/useRBAPrice';

const socials = [
  {
    id: 1,
    icon: <TelegramSVG className="fill-dark-text dark:fill-light-text" />,
    link: 'https://t.me/bullpad_official',
  },
  {
    id: 2,
    icon: <TwitterSVG className="fill-dark-text dark:fill-light-text" />,
    link: 'https://twitter.com/bulllaunchpad',
  },
];

const menuItems = [
  {
    title: 'LAUNCHPAD',
    link: '/pools',
    subMenu: [
      { title: 'Launchpad List', link: '/pools' },
      { title: 'Launch Sale', link: '/create-sale' }
    ]
  },
  {
    title: 'EXCHANGE',
    link: 'https://app.bullpad.org',
    subMenu: [
     { title: 'Swap BULL', link: 'https://app.bullpad.org/swap' },
    { title: 'Add Liquidity', link: 'https://app.bullpad.org/add' }
    ]
  },
  {
    title: 'STAKING',
    link: '/create-staking',
    subMenu: [
      { title: 'Staking List', link: '/stakes' },
      { title: 'Launch Staking', link: '/create-staking' },
    ]
  },
  // {
  //   title: 'DASHBOARD',
  //   link: '/dashboard',
  //   subMenu: []
  // },
  // {
  //   title: 'LOCK',
  //   link: '/locked-assets',
  //   subMenu: [
  //     { title: 'Lock Asset', link: '/locked-assets' },
  //     { title: 'Token Locker', link: '/locker/token-locker' },
  //     { title: 'LP Locker', link: '/locker/lp-locker' }
  //   ]
  // }
];

export default function Menu() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const rbaPrice = useRBAPrice();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleSubMenu = (index) => {
    setOpenSubMenu(openSubMenu === index ? null : index);
  };

  return (
    <div className="box h-[60px] flex items-center justify-between pl-[3%] pr-[3%] bg-[#072f2d] relative">
      <div className="flex items-center">
        <div className="logo-div flex mt-3">
          <Link to="/">
            <img src="https://i.ibb.co.com/CQy2mcp/Logo-Text3.png" alt="logo" className='hidden md:block w-40 h-10' />
            <img src="https://i.ibb.co.com/wdhpDbb/Logo1.png" alt="logo" className='block md:hidden w-12 h-12' />
          </Link>
        </div>
        <div className="hidden md:flex items-center space-x-4 ml-6">
          <ul className="nav-menu">
            {menuItems.map((item, index) => (
              <li key={index} className="current-menu-item">
                <div className="flex justify-between items-center w-full">
                  <a className="text-white" href={item.link}>{item.title}</a>
                  <span className="plus-icon" onClick={() => toggleSubMenu(index)}>
                    {openSubMenu === index ? '-' : '+'}
                  </span>
                </div>
                <ul className={`sub-menu ${openSubMenu === index ? 'block' : ''}`}>
                  {item.subMenu.map((subItem, subIndex) => (
                    <li key={subIndex}><a href={subItem.link}>{subItem.title}</a></li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="hidden md:flex items-center space-x-4">
        {socials.map((social) => (
          <a key={social.id} href={social.link} className="ml-[14px] first:ml-0" target="_blank" rel='noreferrer'>
            <div className="twitter flex items-center justify-center bg-[#F5F1EB] dark:bg-dark-3 w-[34px] h-[34px] rounded-md">
              {social.icon}
            </div>
          </a>
        ))}
        <div className="bg-[white] rounded-[14px] flex px-2 py-1 ml-8">
          <img src="https://i.ibb.co.com/wdhpDbb/Logo1.png" alt="logo" className='w-6 h-6'/>
          <span className="font-gilroySemiBold ml-2 font-semibold text-orange">
            {"$" + (rbaPrice?.ripple?.usd?.toFixed(2))}
          </span>
        </div>
        <NetworkDropdown />
        <ConnectButton />
      </div>
      <div className="md:hidden flex items-center">
        <NetworkDropdown />
        <ConnectButton />
        <button onClick={toggleMobileMenu} className="text-black ml-2">
          {isMobileMenuOpen ? <CloseIcon className="w-10 h-10" /> : <BurgerIcon className="w-10 h-10" />}
        </button>
      </div>
      {isMobileMenuOpen && (
        <div className="md:hidden absolute top-[60px] left-0 w-full flex flex-col items-start space-y-2 p-4 bg-[#EDE9FE] z-50">
          <ul className="nav-menu">
            {menuItems.map((item, index) => (
              <li key={index} className={`current-menu-item ${openSubMenu === index ? 'open' : ''}`}>
                <div className="flex justify-between items-center w-full" onClick={() => toggleSubMenu(index)}>
                  <a href={item.link}>{item.title}</a>
                  <span className="plus-icon">
                    {openSubMenu === index ? '-' : '+'}
                  </span>
                </div>
                {openSubMenu === index && (
                  <ul className="sub-menu">
                    {item.subMenu.map((subItem, subIndex) => (
                      <li key={subIndex}><a href={subItem.link}>{subItem.title}</a></li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <div className="socials-container">
            {socials.map((social) => (
              <a key={social.id} href={social.link} className="social-link" target="_blank" rel="noreferrer">
                <div className="social-icon">
                  {social.icon}
                </div>
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
