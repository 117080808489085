import React, { useState, useEffect } from 'react'
import Ndropdown from './Ndropdown'
import { Networkswitch } from 'data/networkswitch';
import { useEthers } from '@usedapp/core'
import { Vanar, CoreChain } from 'config/constants/testchain'
import { useDefaultChainId } from "config/useDefaultChainId";


const MenuButton = () => {
    const chainId = useDefaultChainId();
    // console.log('chainId:', chainId)
    const { switchNetwork, account, activate, activateBrowserWallet } = useEthers()
    // console.log('chainId:', switchNetwork, account, activate, activateBrowserWallet )
    const [itemData, setItemData] = useState([]);
    const [toggle, setToggle] = useState(false)
    const [activeItem, setActiveItem] = useState({
        img: '/images/cards/Vanar.png',
        title: 'VANAR'
    })

    useEffect(()=>{
        if (chainId === CoreChain.chainId){
            setActiveItem({
                img: '/images/cards/core.png',
                title: 'CORE'
            })
        } else if(chainId === Vanar.chainId){
            setActiveItem({
                img: '/images/cards/Vanar.png',
                title: 'VANAR'
            })
        }
    }, [chainId])


    useEffect(() => {
        // localStorage.removeItem('network-switch');
        const nSwitch = window.localStorage.getItem('network-switch')

        if (nSwitch) {
            const tm = JSON.parse(nSwitch)
            if (Array.isArray(tm)) {
                setItemData(tm)
                tm.forEach(elm => {
                    if (elm.isActive) {
                        setActiveItem({
                            img: elm.iconimg,
                            title: elm.title
                        })
                    }
                });
            } else {
                // Handle the case where tm is not an array
                console.error("Unexpected format in localStorage");
            }
        } else {
            setItemData(Networkswitch)
        }
    }, []);


    const toggleNetworkDropdown = ()=>{

        if(toggle){
            setToggle(false)
        }else{
            setToggle(true)
        }
    }

    const updateMenuItem = async (index) =>{

        if (itemData[index].title === 'CORE'){
            await switchNetwork(CoreChain.chainId);
        }
        if (itemData[index].title === 'VANAR'){
            await switchNetwork(Vanar.chainId);
        }

        let temItem = [...itemData]
        temItem = temItem.map((item)=>{
            return {...item, isActive: false, status: false, subtitle: "Not Connected",  }
        })

        temItem[index].isActive = true;
        temItem[index].status = true;
        temItem[index].subtitle = "Connected";

        setItemData(temItem);

        setActiveItem({
            img: itemData[index].iconimg,
            title: itemData[index].title
        })

        setToggle(false)


        localStorage.setItem('network-switch', JSON.stringify(temItem) )
        window.dispatchEvent( new Event('storage') ) // <-----

    }

  return (
        <div className='relative'>
            <div className='rounded-[10px] menu-items-div items-center' onClick={toggleNetworkDropdown}>
                    <span className='item-icon'>
                        <img width="26" src={activeItem.img} alt="" />
                    </span>
                </div>
            {toggle ? <Ndropdown updateMenuItem={updateMenuItem} itemData={itemData} /> : ''}
        </div>
  )
}

export default MenuButton
