import React from 'react'
import BaseLayout from '../../components/BaseLayout/BaseLayout'
import Panel from '../../components/Staking/CreateStaking/Panel'
import LaunchpadSVG from '../../svgs/Sidebar/launchpad'
import { useState } from 'react'
import { useDocumentTitle } from 'hooks/setDocumentTitle'

export default function CreateSale() {
    useDocumentTitle("Create Staking")
    const initSaleState = {
        name: '',
        isValid: false,
        type:  '',
        rewardToken: '',
        stakingToken: '',
        image1: '',
        image2: '',
        banner: '',
        description: '',
        website: '',
        twitter: '',
        whitepaper: '',
        github: '',
        telegram: '',
        discord : '',
        youtube: '',
        userBalance: '',
        userAllowance: '0',
    }
    const [stakingData, setStakingData] = useState({ ...initSaleState, showLanding: true, showDetails: false })
    return (
        <BaseLayout title={"BullPad"} title_img={<LaunchpadSVG className="md:hidden fill-dim-text" />}
          page_name={"Launch"}
          page_description={"Launch your project with BullPad."}>
          <Panel stakingData={stakingData} setStakingData={setStakingData} initSaleState={initSaleState}/>
        </BaseLayout>
    )
}
