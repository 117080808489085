import React from "react";
import { Link } from "react-router-dom";
import Timer from "./Subcomponents/Timer";
import { useEffect, useState } from "react";
import PreviewDetails from "components/Common/PreviewDetails";
import getSaleInfo from "utils/getSaleInfo";

import PercentFilled from "./Subcomponents/PercentFilled";
import FairPercentFilled from "./Subcomponents/FairPercentFilled";

export default function PoolsBase({
  pools,
  pool,
  address,
  soft_cap,
  status,
  loading,
  icon, finished,
  isCancelled,
  saleAddress,
}) {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-5 mt-4">
      {!loading &&
        pools?.map((pool) => (
          <Link to={`/pools/${pool._id}`} key={pool._id}>
            <div className="flex flex-col relative">
              <div className="bg-white dark:bg-dark-1 rounded-[10px] border-2 border-[#00bb41] pb-4 pr-4 pl-4 transition duration-150 ease-in-out" style={{boxShadow: '2px 2px 4px 2px #a1a1aa'}}>
              <div className="flex items-center justify-between pt-2 pb-2 ">
              <div className="col-end-7 col-span-2">
              {pool.sale.saleType === "public" && (
                <span className="right-[20px] text-[14px] font-bold bg-[#86efac] border-[1px 1px 1px 0px] border-[black] py-[3px] px-2 text-black -translate-x-1/2 relative z-0">
                  PublicSale
                </span>
              )}
              {pool.sale.saleType === "publicvesting"  && (
                <span className="right-[20px] text-[14px] font-bold bg-[#86efac] border-[1px 1px 1px 0px] border-[black] py-[3px] px-2 text-black -translate-x-1/2 relative z-0">
                  PublicSale&nbsp;+&nbsp;Vesting
                </span>
              )}
              {pool.sale.saleType === "private" && (
                <span className="right-[20px] text-[14px] font-bold bg-[#86efac] border-[1px 1px 1px 0px] border-[black] py-[3px] px-2 text-black -translate-x-1/2 relative z-0">
                  PrivateSale
                </span>
              )}
              {pool.sale.saleType === "fairlaunch" && (
                <span className="right-[20px] text-[14px] font-bold bg-[#86efac] border-[1px 1px 1px 0px] border-[black] py-[3px] px-2 text-black -translate-x-1/2 relative z-0">
                  FairLaunch
                </span>
              )}
              </div>
              <div className="col-end-7 col-span-2">
              {pool.sale.status === "Live" && (
                <span className="left-[20px] ml-4 text-[14px] font-bold bg-[#d7fcf0] dark:bg-[#e72ab0] py-[3px] px-2 text-black -translate-x-1/2 relative z-0">
                  <span className="inline-flex animate-ping">🟢</span>
                  &nbsp;Live
                  </span>
              )}
              {pool.sale.status === "Upcoming" && (
                <span className="left-[20px] ml-4 text-[14px] font-bold bg-[#d7fcf0] dark:bg-[#e72ab0] py-[3px] px-2 text-black -translate-x-1/2 relative z-0">
                <span className="inline-flex animate-ping">🟠</span>
                &nbsp;Upcoming
                </span>
              )}

              {pool.sale.status === "Ended" && (
                  <span className="left-[20px] ml-4 text-[14px] font-bold bg-[#d7fcf0] dark:bg-[#e72ab0] py-[3px] px-2 text-black -translate-x-1/2 relative z-0">
                  <span className="inline-flex animate-ping">🔴</span>
                  &nbsp;Ended
                  </span>
              )}

              </div>
              </div>
              <img
                src={pool.sale.banner || "https://cdna.artstation.com/p/assets/images/images/060/746/238/original/feon-cityundermoon-pixel-art.gif"}
                alt={pool.sale.name}
                className="w-full h-[100px] rounded-[10px] border border-2px border-black"
                />
              <div >
              <div className="col-end-7 col-span-2">
              <div className="items-center relative">
                <img
                  src={pool.sale.image}
                  alt={pool.sale.name}
                  className="w-[75px] h-[75px] left-[50%] -translate-x-1/2 -translate-y-1/2 rounded-[50px] border border-solid border-2px border-black relative z-0"
                />
                {pool.sale.chainID === 1116 && pool.sale.currency.icon && (
                  <img className="absolute top-2 left-[165px] w-6 h-6 m-1 z-10" src={pool.sale.currency.icon} alt="Currency Icon" />
                )}
                {pool.sale.chainID === 2040 && pool.sale.currency.icon && (
                  <img className="absolute top-2 left-[165px] w-6 h-6 m-1 z-10" src={pool.sale.currency.icon} alt="Currency Icon" />
                )}

                <div className="flex justify-center items-start absolute top-[45px] w-full">
                  <div className="text-center">
                    <h3 className="font-bold text-black text-[15px]">
                      {pool.sale.name} ({pool.sale.token.tokenSymbol})
                    </h3>
                    <div className="flex flex-col mt-2">
                      <div className="flex justify-center space-x-2">
                        {pool.sale.audit !== "" && (
                          <Link to={pool.sale.audit} target="_blank" rel="noopener noreferrer">
                            <span className="py-1 px-4 text-[12px] font-bold bg-[#79cdff] dark:bg-[#79cdff] py-[3px] px-2 text-black rounded-[4px]">AUDIT<span className="inline-flex animate-ping">✅</span></span>
                          </Link>
                        )}
                        {pool.sale.kyc !== "" && (
                          <Link to={pool.sale.kyc} target="_blank" rel="noopener noreferrer">
                            <span className="py-1 px-4 text-[12px] font-bold bg-[#79ff82] dark:bg-[#79ff82] py-[3px] px-2 text-black rounded-[4px]">KYC<span className="inline-flex animate-ping">✅</span></span>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              </div>
              </div>

              <div className='border-2 border-[#d4d4d8] rounded-md shadow-md shadow-gray-700 p-2' style={{margin: '10px 0px 0px 0px'}}>

              { (pool.sale.saleType === "private" || pool.sale.saleType === "public" || pool.sale.saleType === "publicvesting" ) && (
              <div className="flex items-center justify-between mt-2">
                <span className="text-xs  text-gray dark:text-gray-dark">
                  Sold:
                  {/* idk where to get filled perccentage */}
                </span>

                <span className="text-xs text-dark-text dark:text-light-text font-semibold">
                  {pool.sale.hardCap * (pool.sale.filledPercent / 100)} {pool.sale.currency.symbol}
                </span>
              </div>
            )}

            {(pool.sale.saleType === "fairlaunch") && (
            <div className="flex items-center justify-between mt-2">
              <span className="text-xs  text-gray dark:text-gray-dark">
                Sold:
              </span>

              <span className="text-xs  text-dim-text dark:text-dim-text-dark">
                {pool.sale.softCap * (pool.sale.filledPercent / 100)} {pool.sale.currency.symbol}
              </span>
            </div>
          )}

              <PercentFilled
              address={pool.sale.saleAddress}
              item={pool}
              sale = {pool.sale}
              isCancelled={pool.isCancelled}
              isFinished={pool.isFinished}
              saleType = {pool.sale.saleType}
              />

              </div>

                {pool.sale.saleType === "fairlaunch" && (
                <div className="flex items-center justify-between mt-2">
                  <span className="text-xs font-medium text-gray dark:text-gray-dark">
                    SoftCap
                  </span>
                  <div className="flex items-center">
                    <span className="text-sm ml-2 font-bold text-dark-text dark:text-light-text">
                      {pool.sale.softCap} {pool.sale.currency.symbol}
                    </span>
                  </div>
                </div>
                )}
                {(pool.sale.saleType === "private" || pool.sale.saleType === "public" || pool.sale.saleType === "publicvesting" ) && (
                <div className="flex items-center justify-between mt-2">
                  <span className="text-xs font-medium text-gray dark:text-gray-dark">
                    Soft/Hard Caps
                  </span>
                  <div className="flex items-center">
                    <span className="text-sm ml-2 font-bold text-dark-text dark:text-light-text">
                      {pool.sale.softCap} {pool.sale.currency.symbol} / {pool.sale.hardCap} {pool.sale.currency.symbol}
                    </span>
                  </div>
                </div>
                )}
                {/* (pool.sale.saleType === "fairlaunch") && (
                <div className="flex items-center justify-between mt-2">
                  <span className="text-xs font-medium text-gray dark:text-gray-dark">
                    Min Buy
                  </span>
                  <div className="flex items-center">
                    <span className="text-sm ml-2 font-bold text-dark-text dark:text-light-text">
                      {pool.sale.minAllocation} {pool.sale.currency.symbol}
                    </span>
                  </div>
                </div>
              ) */}
                { (pool.sale.saleType === "fairlaunch" || pool.sale.saleType === "public" || pool.sale.saleType === "publicvesting" ) && (
                <div className="flex items-center justify-between mt-2">
                  <span className="text-xs font-medium text-gray dark:text-gray-dark">
                    Liquidity
                  </span>
                  <div className="flex items-center">
                    <span className="text-sm ml-2 font-bold text-dark-text dark:text-light-text">
                      <span className="text-sm text-dark-text dark:text-light-text font-semibold">
                        {pool.sale.amountLiquidity
                          ? pool.sale.amountLiquidity
                          : "---"}
                      </span>{" "}
                      %
                    </span>
                  </div>
                </div>
                )}



                <div className="flex items-center justify-between">
                    <span className="text-xs font-medium text-gray dark:text-gray-dark">
                      Lockup Time
                    </span>
                    {/* Mengonversi timestamp ke format tanggal-bulan-tahun */}
                    <PreviewDetails
                      value={new Date(pool.sale.lockup * 1000).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    />
                </div>
                {pool.sale.status !== "Ended" && (
                  <div className="flex items-center justify-between">
                    <span className="text-xs font-medium text-gray dark:text-gray-dark">
                      {pool.sale.status === "Live" ? "Ends in" : "Starts in"}
                    </span>
                    <Timer
                      date={
                        pool.sale.status === "Live"
                          ? new Date(pool.sale.endDate * 1000)
                          : new Date(pool.sale.startDate * 1000)
                      }
                    />
                  </div>
                )}


              </div>
            </div>
          </Link>
        ))}
    </div>
  );
}
