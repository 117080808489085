import React from 'react'
import DisconnectSVG from 'svgs/Topbar/Disconnect'
import { useEthers } from '@usedapp/core'


export default function Dropdown() {

  const { deactivate, account } = useEthers()

  const onDisconnect = async () => {
    try {
      deactivate()
    } catch (error) {
      console.error(error)
    }
  }


  return (
    <div className="absolute w-[250px] rounded-[20px] border border-[#e7e3ff] dark:border-dim-text-dark bg-white dark:bg-dark-1">
      <div
      onClick={() => onDisconnect()}
      className="rounded-[20px] flex items-center text-gray dark:text-gray-dark cursor-pointer justify-between border border-gray  hover:text-light dark:border-gray-dark border-opacity-20 px-2 py-2">
        <span className="font-medium">Disconnect</span>

        <DisconnectSVG className="fill-dark-text dark:fill-light-text" />
      </div>
    </div>
  )
}
