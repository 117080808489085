import React, { useState, useContext } from "react";
import TwitterSVG from "../../../svgs/Socials/twitter";
import WhitePaperSVG from "../../../svgs/Socials/whitepaper";
import DribbleSVG from "../../../svgs/Socials/dribble";
import HeadingTags from "../../TokenLocker/Subcomponents/HeadingTags";
import BackArrowSVG from "../../../svgs/back_arrow";
import LinkedinSVG from "../../../svgs/Socials/linkedin";
import { ThemeContext } from "../../../context/ThemeContext/ThemeProvider";
import GithubSVG from "../../../svgs/Socials/github";
import TelegramSVG from "svgs/Socials/telegram";
import { isValidUrl } from "utils/numberFormat";
import PreviewHeader from "components/Common/PreviewHeader";
import DiscordSVG from "svgs/Socials/discord";
import YoutubeSVG from "svgs/Socials/youtube";
import { toast } from "react-toastify";
import { useModal } from "react-simple-modal-provider";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";
import axios from "axios";

export default function ProjectDetails({ setActive, setStakingData, stakingData, edit, objId }) {
  console.log('stakingData:', stakingData);
  const { theme } = useContext(ThemeContext);
  const [validWebsite, setValidWebsite] = useState(true);
  const [validTwitter, setValidTwitter] = useState(true);
  const [validLinkedin, setValidLinkedin] = useState(true);
  const [validGithub, setValidGithub] = useState(true);
  const [validTelegram, setValidTelegram] = useState(true);
  const [validDiscord, setValidDiscord] = useState(true);
  const [validYoutube, setValidYoutube] = useState(true);
  const [validWhitePaper, setValidWhitePaper] = useState(true);
  const [validBanner, setValidBanner] = useState(true);
  const [validImage1, setValidImage1] = useState(true);
  const [validImage2, setValidImage2] = useState(true);
  const [valid, setValid] = useState(true);
  const [show, setShow] = useState([]);
  const { open: openLoadingModal, close: closeLoadingModal } =
  useModal("LoadingModal");

  const handleMouseEnter = (e) => {
    const id = e.target.id;
    setShow((prevState) => [...prevState, id]);
  };

  const handleMouseLeave = (e) => {
    const id = e.target.id;
    setShow((prevState) => prevState.filter((item) => item !== id));
  };

  const handleSubmit = async () => {
    console.log("handleSubmit called");
    //if no image or website is provided, display error
    if (stakingData.image1 === "" ) {
      toast.error("Please fill in the image field");
      return;
    }
    if (stakingData.website === "") {
      toast.error("Please fill in the website field");
      return;
    }
    if (stakingData.image1 !== "") {
    if (!isValidUrl(stakingData.image1)) {
      setValidImage1(false);
      return;
    } else {
      setValidImage1(true);
    }
  }

  if (stakingData.image2 !== "") {
    if (!isValidUrl(stakingData.image2)) {
      setValidImage2(false);
      return;
    } else {
      setValidImage2(true);
    }
  }

    if (!isValidUrl(stakingData.website)) {
      setValidWebsite(false);
      return;
    } else {
      setValidWebsite(true);
    }

    if (stakingData.banner !== "") {
    if (!isValidUrl(stakingData.banner)) {
      setValidBanner(false);
      return;
    } else {
      setValidBanner(true);
    }
  }

    if (stakingData.twitter !== "") {
      if (!isValidUrl(stakingData.twitter)) {
        setValidTwitter(false);
        return;
      } else {
        setValidTwitter(true);
      }
    }

    if (stakingData.whitepaper !== "") {
      if (!isValidUrl(stakingData.whitepaper)) {
        setValidWhitePaper(false);
        return;
      } else {
        setValidWhitePaper(true);
      }
    }


    if (stakingData.github !== "") {
      if (!isValidUrl(stakingData.github)) {
        setValidGithub(false);
        return;
      } else {
        setValidGithub(true);
      }
    }

    if (stakingData.telegram !== "") {
      if (!isValidUrl(stakingData.telegram)) {
        setValidTelegram(false);
        return;
      } else {
        setValidTelegram(true);
      }
    }


    if (stakingData.discord !== "") {
      if (!isValidUrl(stakingData.discord)) {
        setValidDiscord(false);
        return;
      } else {
        setValidDiscord(true);
      }
    }

    setActive("Preview");
  };

  function handleBack() {
    setActive("Staking")
  }
  console.log(stakingData, "stakingData")
  return (
    <div className="w-full p-5 md:p-9 bg-white dark:bg-dark-1 rounded-[10px] ">
      <>
        <div className="flex items-center mt-9">
          <HeadingTags name={"Staking Token Logo Url"} required />
          <div className="relative">
            <img
              src="/images/lists/question.svg"
              alt="info"
              id="info"
              className="ml-2"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
            {/* tooltip to appear above img info tag */}
            {show.includes("info") && (
              <div className="absolute z-10 bg-dim-text bg-opacity-50 dark:bg-white rounded-[10px] shadow-lg p-1 bottom-3 left-5 w-36">
                <p className="text-light-text dark:text-dark-text font-extralight text-sm">
                  This is the logo of your project (atleast 1000px for quality)
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="mt-2 flex items-center justify-between gap-5 cursor-pointer">
          <div
            className={`flex items-center justify-between bg-[#FAF8F5] border dark:bg-dark-2 px-3 py-3 rounded-md w-[100%] ${
              validImage1 ? "border-dim-text" : "border-red-500"
            }`}
          >
            <input
              type="text"
              placeholder="Ex: https://..."
              className="w-[100%] font-bold text-dark-text dark:text-light-text"
              value={stakingData.image1}
              onChange={(e) =>
                setStakingData((prevState) => ({
                  ...prevState,
                  image1: e.target.value,
                }))
              }
            />
          </div>
        </div>
      </>

      <div className="flex items-center mt-9">
        <HeadingTags name={"Reward Token Logo Url"}/>
      </div>
      <div className="mt-2 flex items-center justify-between gap-5 cursor-pointer">
        <div
          className={`flex items-center justify-between bg-[#FAF8F5] border dark:bg-dark-2 px-3 py-3 rounded-md w-[100%] ${
            validImage2 ? "border-dim-text" : "border-red-500"
          }`}
        >
          <input
            type="text"
            placeholder="Ex: https://..."
            className="w-[100%] font-bold text-dark-text dark:text-light-text"
            value={stakingData.image2}
            onChange={(e) =>
              setStakingData((prevState) => ({
                ...prevState,
                image2: e.target.value,
              }))
            }
          />
        </div>
      </div>

      <div className="flex items-center mt-9">
        <HeadingTags name={"Project Banner 500 x 2000px"}/>
      </div>
      <div className="mt-2 flex items-center justify-between gap-5 cursor-pointer">
        <div
          className={`flex items-center justify-between bg-[#FAF8F5] border dark:bg-dark-2 px-3 py-3 rounded-md w-[100%] ${
            validBanner ? "border-dim-text" : "border-red-500"
          }`}
        >
          <input
            type="text"
            placeholder="Ex: https://..."
            className="w-[100%] font-bold text-dark-text dark:text-light-text"
            value={stakingData.banner}
            onChange={(e) =>
              setStakingData((prevState) => ({
                ...prevState,
                banner: e.target.value,
              }))
            }
          />
        </div>
      </div>

      <div className="mt-10">
        <div className="flex items-center">
          <HeadingTags name={"Description"} required />
          <div className="relative">
            <img
              src="/images/lists/question.svg"
              alt="info"
              id="info2"
              className="ml-2"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
            {/* tooltip to appear above img info tag */}
            {show.includes("info2") && (
              <div className="absolute z-10 bg-dim-text bg-opacity-50 dark:bg-white rounded-[10px] shadow-lg p-1 bottom-3 left-5 w-24">
                <p className="text-light-text dark:text-dark-text font-extralight text-sm">
                  This is the description of your project.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="mt-2">
          <textarea
            className="bg-transparent w-full px-3 py-3 font-gilroy placeholder:font-medium placeholder:text-dim-text font-semibold text-dark-text dark:text-light-text focus:outline-none border-[1.5px] rounded-lg border-dim-text border-opacity-50"
            type={"text"}
            value={stakingData.description}
            onChange={(e) =>
              setStakingData((prevState) => ({
                ...prevState,
                description: e.target.value,
              }))
            }
            placeholder="Describe about your project"
          />
        </div>
      </div>

      <PreviewHeader heading={"Social Details"} />
      <div className="mt-7">
        <HeadingTags name={"Website"} required />
        <div
          className={`flex items-center rounded-lg border-[1.5px] pr-5  border-opacity-50 justify-between mt-2 ${
            validWebsite ? "border-dim-text" : "border-red-500"
          }`}
        >
          <input
            className={`bg-transparent w-full px-3 py-3 font-gilroy placeholder:font-medium placeholder:text-dim-text font-semibold text-dark-text dark:text-light-text focus:outline-none `}
            type={"text"}
            value={stakingData.website}
            onChange={(e) =>
              setStakingData((prevState) => ({
                ...prevState,
                website: e.target.value,
              }))
            }
            placeholder="Ex: https://..."
          />
          <DribbleSVG className="w-5 h-5 fill-dark-text dark:fill-light-text" />
        </div>
      </div>
      <div className="mt-7">
        <HeadingTags name={"WhitePaper"} />
        <div
          className={`flex items-center rounded-lg border-[1.5px] pr-5  border-opacity-50 justify-between mt-2 ${
            validWhitePaper? "border-dim-text" : "border-red-500"
          }`}
        >
          <input
            className={`bg-transparent w-full px-3 py-3 font-gilroy placeholder:font-medium placeholder:text-dim-text font-semibold text-dark-text dark:text-light-text focus:outline-none `}
            type={"text"}
            value={stakingData.whitepaper}
            onChange={(e) =>
              setStakingData((prevState) => ({
                ...prevState,
                whitepaper: e.target.value,
              }))
            }
            placeholder="Ex: https://..."
          />
          <WhitePaperSVG className="w-5 h-5 fill-dark-text dark:fill-light-text" />
        </div>
      </div>
      <div className="mt-7">
        <HeadingTags name={"Twitter"} />
        <div
          className={`flex items-center rounded-lg border-[1.5px] pr-5 border-dim-text border-opacity-50 justify-between mt-2 ${
            validTwitter ? "border-dim-text" : "border-red-500"
          }`}
        >
          <input
            className="bg-transparent w-full px-3 py-3 font-gilroy placeholder:font-medium placeholder:text-dim-text font-semibold text-dark-text dark:text-light-text focus:outline-none"
            type={"text"}
            value={stakingData.twitter}
            onChange={(e) =>
              setStakingData((prevState) => ({
                ...prevState,
                twitter: e.target.value,
              }))
            }
            placeholder="Ex: https://twitter.com/.."
          />
          <TwitterSVG className="w-5 h-5 fill-dark-text dark:fill-light-text" />
        </div>
      </div>

      <div className="mt-7">
        <HeadingTags name={"Telegram"} />
        <div
          className={`flex items-center rounded-lg border-[1.5px] pr-5 border-opacity-50 justify-between mt-2 ${
            validTelegram ? "border-dim-text" : "border-red-500"
          }`}
        >
          <input
            className="bg-transparent w-full px-3 py-3 font-gilroy placeholder:font-medium placeholder:text-dim-text font-semibold text-dark-text dark:text-light-text focus:outline-none"
            type={"text"}
            value={stakingData.telegram}
            onChange={(e) =>
              setStakingData((prevState) => ({
                ...prevState,
                telegram: e.target.value,
              }))
            }
            placeholder="Ex: https://t.me/.."
          />
          <TelegramSVG
            className="w-5 h-5"
            outer={`${theme === "dark" ? "#fff" : "#464754"}`}
            inner={`${theme === "dark" ? "#464754" : "#fff"}`}
          />
        </div>
      </div>
      <div className="mt-7">
        <HeadingTags name={"Github"} />
        <div
          className={`flex items-center rounded-lg border-[1.5px] pr-5 border-opacity-50 justify-between mt-2 ${
            validGithub ? "border-dim-text" : "border-red-500"
          }`}
        >
          <input
            className="bg-transparent w-full px-3 py-3 font-gilroy placeholder:font-medium placeholder:text-dim-text font-semibold text-dark-text dark:text-light-text focus:outline-none"
            type={"text"}
            value={stakingData.github}
            onChange={(e) =>
              setStakingData((prevState) => ({
                ...prevState,
                github: e.target.value,
              }))
            }
            placeholder="Ex: https://github.com/.."
          />
          <GithubSVG
            className="w-5 h-5"
            outer={`${theme === "dark" ? "#fff" : "#464754"}`}
            inner={`${theme === "dark" ? "#464754" : "#fff"}`}
          />
        </div>
      </div>
      <div className="mt-7">
        <HeadingTags name={"Discord"} />
        <div
          className={`flex items-center rounded-lg border-[1.5px] pr-5 border-opacity-50 justify-between mt-2 ${
            validDiscord ? "border-dim-text" : "border-red-500"
          }`}
        >
          <input
            className="bg-transparent w-full px-3 py-3 font-gilroy placeholder:font-medium placeholder:text-dim-text font-semibold text-dark-text dark:text-light-text focus:outline-none"
            type={"text"}
            value={stakingData.discord}
            onChange={(e) =>
              setStakingData((prevState) => ({
                ...prevState,
                discord: e.target.value,
              }))
            }
            placeholder="Ex: https://discord.com/.."
          />
          <DiscordSVG
            className="w-5 h-5"
            outer={`${theme === "dark" ? "#fff" : "#464754"}`}
            inner={`${theme === "dark" ? "#464754" : "#fff"}`}
          />
        </div>
      </div>

      <div className="mt-10">
        <div className="flex justify-end items-center mb-10">
          <button
            className="bg-white dark:bg-transparent mr-5 flex items-center gap-2 py-[10px] px-5"
            onClick={handleBack}
          >
            <BackArrowSVG className="fill-dark-text dark:fill-light-text" />
            <span className="font-gilroy font-medium text-sm text-dark-text dark:text-light-text">
              Go Back
            </span>
          </button>

          <button
            className="bg-primary-green hover:opacity-40 disabled:bg-light-text text-white font-gilroy font-bold px-8 py-3 rounded-md"
            // disabled={address.length < 5}
            onClick={handleSubmit}
          >
            {/* if edit then submit, otherwise next */}
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
