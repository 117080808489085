import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import PoolPageBase from "../../components/Staking/PoolPage";
import { useEthers } from "@usedapp/core";
import Modal from "components/Staking/Modal";
import axios from "axios";
import { useModal } from "react-simple-modal-provider";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";
import Web3 from "web3";
import { Contract } from "ethers";
import PublicSaleAbi from "../../config/abi/PublicSale.json";
import PublicSaleErcAbi from "../../config/abi/PublicSaleErcAbi.json";
import getSaleInfo from "utils/getSaleInfo";
import { BigNumber } from "ethers";
import { formatBigToNum } from "utils/numberFormat";

export default function PoolPage() {
  const { id } = useParams();
  const { account, library } = useEthers();
  const [stake, setStake] = useState(null);
  const [modal, showModal] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [adminMode, setAdminMode] = useState(false);
  const [userAccount, setUserAccount] = useState(null);
  const [stakingOwner, setStakingOwner] = useState(null);
  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");

  useEffect(() => {
    async function getAccount() {
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.enable();
        const res = await web3.eth.getAccounts();
        setUserAccount(res[0]);
        if (res[0] === stakingOwner) {
          setAdmin(true);
          setAdminMode(true);
        } else {
          setAdmin(false);
          setAdminMode(false);
        }
      } catch (e) {
        console.log(e);
      }
    }
    getAccount();
  }, [stakingOwner, account]);

  useEffect(() => {
    //get stake data from api
    openLoadingModal();
    axios
      .get(`${BACKEND_URL}/api/staking/${id}`)
      .then((res) => {
        setStake(res.data);
        document.title = res.data.staking.name;
        // Check if the user is admin
        setStakingOwner(res.data.staking.owner);
        closeLoadingModal();
      })
      .catch((err) => {
        console.log(err);
        //alert ("Something went wrong")
        closeLoadingModal();
      });
  }, []);

  return (
    stake && (
      <div className="w-full">
        {modal && (
          <div className="fixed z-50  top-0 left-0">
            <Modal
              showModal={showModal}
              from_symbol={stake.staking.token1.tokenSymbol}
              from_icon={stake.staking.image1}
              to_icon={stake.staking.image2}
              to_symbol={stake.staking.token2.tokenSymbol}
              token={stake.staking.token}
              stake={stake.staking}
              objId={stake._id}
              account={userAccount}
            />
          </div>
        )}
        <BaseLayout
          page_name={"Stakes"}
          title={stake.staking.name}
          subpage
          admin={admin}
          setAdminMode={setAdminMode}
        >
          <PoolPageBase
            objId={stake._id}
            stake={stake.staking}
            visible={stake.visible}
            showModal={showModal}
            admin={adminMode}
            isFinished={stake.isFinished}
            isCancelled={stake.isCancelled}
          />
        </BaseLayout>
      </div>
    )
  );
}
