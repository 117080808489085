import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 502.001 502.001"
    xmlSpace="preserve"
    {...props}
  >
    <path
      style={{
        fill: "#50a5dc",
      }}
      d="M445.317 133.126H56.683C30.901 133.126 10 112.225 10 86.443S30.901 39.76 56.683 39.76h388.634C471.099 39.76 492 60.661 492 86.443s-20.901 46.683-46.683 46.683"
    />
    <path d="M445.317 143.126H56.683C25.428 143.126 0 117.698 0 86.443S25.428 29.76 56.683 29.76h388.635C476.572 29.76 502 55.188 502 86.443s-25.428 56.683-56.683 56.683M56.683 49.76C36.456 49.76 20 66.216 20 86.443s16.456 36.683 36.683 36.683h388.635c20.227 0 36.683-16.456 36.683-36.683S465.544 49.76 445.317 49.76z" />
    <path
      style={{
        fill: "#50a5dc",
      }}
      d="M445.317 297.684H56.683C30.901 297.684 10 276.783 10 251s20.901-46.683 46.683-46.683h388.634C471.099 204.317 492 225.218 492 251s-20.901 46.684-46.683 46.684"
    />
    <path d="M445.317 307.684H56.683C25.428 307.684 0 282.255 0 251s25.428-56.683 56.683-56.683h388.635C476.572 194.317 502 219.745 502 251s-25.428 56.684-56.683 56.684M56.683 214.317C36.456 214.317 20 230.773 20 251s16.456 36.683 36.683 36.683h388.635C465.544 287.684 482 271.227 482 251s-16.456-36.683-36.683-36.683z" />
    <path
      style={{
        fill: "#50a5dc",
      }}
      d="M445.317 462.24H56.683C30.901 462.24 10 441.339 10 415.557s20.901-46.683 46.683-46.683h388.634c25.782 0 46.683 20.901 46.683 46.683s-20.901 46.683-46.683 46.683"
    />
    <path d="M445.317 472.24H56.683C25.428 472.24 0 446.813 0 415.557s25.428-56.683 56.683-56.683h388.635c31.255 0 56.683 25.428 56.683 56.683-.001 31.256-25.429 56.683-56.684 56.683M56.683 378.874C36.456 378.874 20 395.33 20 415.557s16.456 36.683 36.683 36.683h388.635c20.227 0 36.683-16.456 36.683-36.683s-16.456-36.683-36.683-36.683zM230.576 87.106H57.85c-5.523 0-10-4.477-10-10s4.477-10 10-10h172.726c5.523 0 10 4.477 10 10s-4.477 10-10 10m66.523 0h-19.84c-5.523 0-10-4.477-10-10s4.477-10 10-10h19.84c5.523 0 10 4.477 10 10s-4.477 10-10 10" />
  </svg>
);
export default SVGComponent;
