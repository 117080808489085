import React from "react";
import chainImage from "./images/homeV2/Multi_Chain_Support1.png"
import statisticImage from "./images/icons/steps.png"
import checkImage from "./images/icons/icon-Check.svg"
import bnbImage from "./images/icons/base.png"
import ethImage from "./images/icons/core.png"
import maticImage from "./images/icons/Vanar.png"
import avaxImage from "./images/icons/BlockChain5.png"
import fuseImage from "./images/icons/BlockChain3.png"
import okxImage from "./images/icons/BlockChain6.png"
import stone1 from './images/icons/tier_icon2.png';
import stone2 from './images/icons/bnbcoin.svg';
import stone3 from './images/icons/ethcoin.svg';
import "./animate.css";
import "./responsive.css";

export default function SupportChain() {
  return (
    <div className="Multi_Chain_Support_Sect Key_Points_section2">
    <div className="keyPointBg">
      <div className="bgStone">
        <span className="stone1">
          <img src={stone1} alt="" className="img-fluid" />
        </span>
        <span className="stone2">
          <img src={stone2} alt="" className="img-fluid" />
        </span>
        <span className="stone3">
          <img src={stone3} alt="" className="img-fluid" />
        </span>
      </div>
    </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="Multi_Chain_Support_Left_Sect wow fadeInLeft" data-wow-delay="0.2s" data-wow-duration="0.4s">
                            <div className="Multi_Chain_Support_img">
                                <img src={chainImage} alt="img" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="Multi_Chain_Support_right_Sect wow fadeInRight" data-wow-delay="0.2s" data-wow-duration="0.4s">
                            <div className="sec-inner align-items-center mb-30">
                                <div className="sec-heading">
                                    <div className="sub-inner mb-15">
                                        <span className="sub-title">Integration</span>
                                        <img className="heading-left-image" src={statisticImage} alt="Steps-Image" />
                                    </div>
                                    <h2 className="title mb-0 text-black">Multi-Chain Support</h2>
                                </div>
                                <div className="Multi_Chain_Support_right_Content">
                                    <p className="text-black">Our IDO launchpad supports multiple blockchain networks, allowing projects to launch on various ecosystems like Base Chain, Core Chain, and more. This flexibility attracts diverse projects and investors, enhancing interoperability and broadening the platform’s reach. Seamless integration with different blockchains ensures efficient transactions and maximizes project visibility.</p>
                                    <div className="Multi_Chain_Support_right_list">
                                          {/*<ul>
                                            <li><span className="text-white"><img src={bnbImage} className="img-support" /></span>Base Chain</li>
                                            <li><span className="text-white"><img src={ethImage} className="img-support" /></span>Core Chain</li>
                                            <li><span className="text-white"><img src={maticImage} className="img-support" /></span>Vanar Chain</li>
                                        </ul>
                                      <ul className="m-0">
                                            <li><span><img src={avaxImage} className="img-support" /></span>Avalanche</li>
                                            <li><span><img src={fuseImage} className="img-support" /></span>Fuse</li>
                                            <li><span><img src={okxImage} className="img-support" /></span>OKEX Chain</li>
                                        </ul>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
    }
