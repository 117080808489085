import React, { useEffect, useMemo, useState } from 'react';
import Web3 from 'web3';
import { useModal } from 'react-simple-modal-provider';
import { useNavigate } from 'react-router-dom';
import { parseEther } from 'ethers/lib/utils';
import BackArrowSVG from '../../svgs/back_arrow';
import PreviewDetails from '../Common/PreviewDetails';
import PreviewHeader from '../Common/PreviewHeader';
import { formatBigToNum } from '../../utils/numberFormat';
import { FACTORY_ADDRESS } from '../../config/constants/address';
import { ethers } from 'ethers';
import { useEthers, useTokenAllowance, useTokenBalance } from '@usedapp/core';
import { parseUnits } from 'ethers/lib/utils';
import { Contract } from '@ethersproject/contracts';
import ERCAbi from 'config/abi/ERC20.json';
import LockFactoryAbi from 'config/abi/LockFactory.json';
import getFeeInfo from 'hooks/useFeeInfo';
import axios from 'axios';
import { BACKEND_URL } from 'config/constants/LaunchpadAddress';
import { handleLockToken, handleLockLP } from "utils/deployLock";

export default function Preview({ locker, setActive, lockData }) {
  console.log(lockData, "lockData");
  const navigate = useNavigate();
  const [date, setDate] = useState();
  const [days, setDays] = useState(0);
  const [feeInfo, setFeeInfo] = useState(null);
  const [tokenObject, setTokenObject] = useState();
  const [lpObject, setLpObject] = useState();
  const { open: openLoadingModal, close: closeLoadingModal } = useModal("LoadingModal");

  const { account, library, chainId } = useEthers();
  console.log("Use token allowance", lockData.tokenAddress, account, FACTORY_ADDRESS[chainId], chainId, "chainId");
  const allowance = useTokenAllowance(lockData.tokenAddress, account, FACTORY_ADDRESS[chainId], {
    refresh: 5,
  });
  const balance = useTokenBalance(lockData.tokenAddress, account, {
    refresh: 5,
  });
  const amountLock = useMemo(() => {
    if (typeof lockData.lockAmount === 'string') {
      return parseUnits(lockData.lockAmount, lockData.tokenDecimals);
    }
    return parseUnits(lockData.lockAmount.toString(), lockData.tokenDecimals);
  }, [lockData.lockAmount, lockData.tokenDecimals]);

  useEffect(() => {
    async function fetchData() {
      const info = await getFeeInfo(chainId);
      setFeeInfo(info);
    }
    fetchData();
  }, [chainId]);

  useEffect(() => {
    if (lockData.unlockDate) {
      const date = new Date(lockData.unlockDate * 1000);
      setDate(date.toLocaleDateString());
      setDays(Math.ceil((date - new Date()) / (1000 * 60 * 60 * 24)));
    }
  }, [lockData.unlockDate]);

  const needApprove = useMemo(() => {
    if (typeof allowance === 'undefined') {
      return true;
    }
    return amountLock.gt(allowance);
  }, [amountLock, allowance]);

  const isValid = useMemo(() => {
    if (typeof balance === 'undefined') {
      return true;
    }
    return !needApprove && balance.gt(amountLock);
  }, [amountLock, needApprove, balance]);

  const handleApprove = async () => {
    openLoadingModal();
    const contract = new Contract(lockData.tokenAddress, ERCAbi, library.getSigner(account));
    try {
      console.log("Approve", FACTORY_ADDRESS[chainId], chainId, "chainId");
      const approval = await contract.approve(FACTORY_ADDRESS[chainId], ethers.constants.MaxUint256);
      await approval.wait();
    } catch (error) {
      console.log(error, "error");
    }
    closeLoadingModal();
  };

  const handleTokenSubmit = async () => {
    openLoadingModal();

    const web3 = new Web3(window.ethereum);
    const chainId = await web3.eth.getChainId();

    if (lockData.type === "public") {
      let finalLockObject;
      const coinName = lockData.tokenName;
      if (lockData.tokenName === coinName) {
        if(chainId === chainId) {
          finalLockObject = await handleLockToken(
            lockData,
            library,
            account,
            chainId,
            feeInfo,
            closeLoadingModal
          );
        }
      }
      console.log("lockObject", finalLockObject);
      await axios.post(`${BACKEND_URL}/api/lock`, {
        lock: finalLockObject,
        liquidity: false,
        chainId: chainId,
      });
    }
    closeLoadingModal();
    window.location.href = "/locked-assets";
  };

  const handleLpSubmit = async () => {
    openLoadingModal();
    const web3 = new Web3(window.ethereum);
    const chainId = await web3.eth.getChainId();
    if (lockData.type === "lptoken") {
      let finalLockObject;
      const coinName = lockData.tokenName;
      if (lockData.tokenName === coinName) {
        if(chainId === chainId) {
          finalLockObject = await handleLockLP(
            lockData,
            library,
            account,
            chainId,
            closeLoadingModal
          );
        }
      }
        await axios.post(`${BACKEND_URL}/api/lock`, {
          lock: finalLockObject,
          liquidity: true,
          chainId: chainId,
        });
    }
    closeLoadingModal();
    window.location.href = "/locked-assets";
  };

  const tokenSymbol = useMemo(() => {
    return lockData.type === 'lptoken' ? `${lockData.token0.symbol}/${lockData.token1.symbol}` : lockData.tokenSymbol;
  }, [lockData]);

  return (
    <div className="-mt-10">
      {locker ? <PreviewHeader heading={'Token address Details'} /> : <PreviewHeader heading={'LP Details'} />}

      <div className="flex flex-col">
        {locker ? (
          <>
            <PreviewDetails name="Name" value={lockData.tokenName} />
            <PreviewDetails name="Symbol" value={lockData.tokenSymbol} />
            <PreviewDetails name="Decimals" value={lockData.tokenDecimals} />
            <PreviewDetails
              name="Total Supply"
              value={`${formatBigToNum(lockData.tokenSupply, lockData.tokenDecimals)} ${lockData.tokenSymbol}`}
            />
          </>
        ) : (
          <>
            <PreviewDetails name="Quote Pair" value={lockData.token0.symbol} />
            <PreviewDetails name="Base Pair" value={lockData.token1.symbol} />
            <PreviewDetails name="Symbol" value={tokenSymbol} />
            <PreviewDetails
              name="LP Supply"
              value={`${formatBigToNum(lockData.tokenSupply, lockData.tokenDecimals)} ${tokenSymbol}`}
            />
            <PreviewDetails name="Dex Listed" value={lockData.factory} />
          </>
        )}
      </div>

      <PreviewHeader heading={'Lock Details'} />

      <div className="flex flex-col">
        <PreviewDetails name={'Amount to be Locked'} value={`${lockData.lockAmount.toLocaleString()} ${tokenSymbol}`} />
        <PreviewDetails name={'Lock Period (Days)'} value={days} />
        <PreviewDetails name={'Unlock Date'} value={date} />
      </div>

      <div className="mt-10">
        <div className="flex justify-end items-center mb-10">
          <button
            className="bg-white dark:bg-transparent mr-5 flex items-center gap-2 py-[10px] px-5"
            onClick={() => setActive('Lock details')}
          >
            <BackArrowSVG className="fill-dark-text dark:fill-light-text" />
            <span className="font-gilroy font-medium text-sm text-dark-text dark:text-light-text">Go Back</span>
          </button>
          {needApprove ? (
            <>
              <button
                className="bg-primary-green hover:opacity-40 disabled:bg-light-text text-white font-gilroy font-bold px-8 py-3 rounded-md"
                onClick={handleApprove}
              >
                Approve Token
              </button>
            </>
          ) : locker ? (
            <>
              <button
                disabled={!isValid}
                className="bg-primary-green hover:opacity-40 disabled:bg-light-text text-white font-gilroy font-bold px-8 py-3 rounded-md"
                onClick={handleTokenSubmit}
              >
                Lock Token
              </button>
            </>
          ) : (
            <>
              <button
                disabled={!isValid}
                className="bg-primary-green hover:opacity-40 disabled:bg-light-text text-white font-gilroy font-bold px-8 py-3 rounded-md"
                onClick={handleLpSubmit}
              >
                Lock LP Token
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
