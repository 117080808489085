import React from "react";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import Exchange from "../../components/DEX/swap";

export default function Home({ totalSales }) {
  return (
    <BaseLayout>
      <Exchange />
    </BaseLayout>
  );
}
