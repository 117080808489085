import { Contract } from "ethers";
import StakingAbi from "config/abi/Staking.json";

import { useCall, useEthers } from "@usedapp/core";

//public
function useParticipated(stakingAddress,account) {
  const { value, error } =
    useCall(
      {
        contract: new Contract(stakingAddress, StakingAbi),
        method: "isParticipated",
        args: [account],
      },
      {
        refresh: "never",
      }
    ) ?? {};
  if (error) {
    // console.log(error)
    return error;
  }
  return value;
}

export default useParticipated;
