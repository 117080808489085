import React from "react";
import ModalField from "./ModalField";
import { useEffect, useState } from "react";
import { BigNumber, Contract, ethers } from "ethers";
import { useEtherBalance, useEthers } from "@usedapp/core";
import StakingAbi from "../../../config/abi/Staking.json";
import { formatEther, parseEther } from "ethers/lib/utils";
import { API_URL, API_KEY } from "config/constants/api";
import axios from "axios";
import getStakingInfo from "utils/getStakingInfo";
import { formatBigToNum } from "utils/numberFormat";
import ERC20 from "config/abi/ERC20.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getAmountParticipated from "utils/getStakingAmountParticipated";
import Web3 from "web3";
import { useModal } from "react-simple-modal-provider";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";
import { useNativePrice } from 'hooks/useNativePrice'
import { useDefaultChainId } from "config/useDefaultChainId";

export default function Modal({
  showModal,
  from_symbol,
  from_icon,
  to_icon,
  to_symbol,
  stake,
  objId,
  // account,
}) {
  const { library } = useEthers();
  const [amount, setAmount] = useState(0);
  console.log("amount:", amount)
  const chainId = useDefaultChainId();
  const [nativePrice, setNativePrice] = useState();
  const nativePriceData = useNativePrice(chainId);
  console.log("nativePriceData:", nativePriceData)
  const [usdAmount, setUsdAmount] = useState(stake.minAllocation * nativePrice);
  console.log("usdAmount:", usdAmount);
  const [tokenPrice, setTokenPrice] = useState(stake.presalePrice);
  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");
  // const sale_info_public_erc = usePublicErcSaleInfo(stake.saleAddress);
  console.log(stake, "saleis");
  let account = "";
  const [balanceBNB, setBalanceBNB] = useState(null);
  const [depositBalance, setDepositToken] = useState(null);
  console.log("depositBalance:", depositBalance);
  console.log("balanceBNB:", balanceBNB)
  const [balance, setBalance] = useState(0);
  const [acct, setAcct] = useState("");
  let bought = "";

  function formatBigToNum(bigNumber, decimals) {
    const num = Web3.utils.fromWei(bigNumber, "ether");
    return parseFloat(num).toFixed(decimals);
  }

  useEffect(() => {
    async function connectWallet() {
      if (typeof window.ethereum !== "undefined") {
        const web3 = new Web3(window.ethereum);
        try {
          await window.ethereum.enable();
          const accounts = await web3.eth.getAccounts();
          setAcct(accounts[0]);

          const tokenAddress = stake.token1.tokenAddress;
          const tokenContract = new web3.eth.Contract(ERC20, tokenAddress);
          const balanceToken = await tokenContract.methods.balanceOf(accounts[0]).call();
          const balanceString = formatBigToNum(balanceToken, 18);
          setBalanceBNB(parseFloat(balanceString.replace(/,/g, "")));
        } catch (e) {
          console.error("Error connecting wallet:", e);
        }
      }
    }

    connectWallet();
  }, [stake.token1.tokenAddress]);

  const [tokenAmount, setTokenAmount] = useState(0);


  useEffect(() => {
    if (chainId !== chainId) {
      console.log(stake.token1.tokenAddress, "stake.currency.address");
      const contract = new Contract(
        stake.token1.tokenAddress,
        ERC20,
        library.getSigner()
      );
      const getBalance = async () => {
        try {
          if (!acct) return;
          const balance = await contract.balanceOf(acct);
          const balanceString = formatBigToNum(balance, 18);
          setBalance(parseFloat(balanceString.replace(/,/g, "")));
        } catch (e) {
          console.log(e);
        }
      };
      getBalance();
      console.log("balance:", balance)
    }
  }, [balanceBNB, acct]);

  const handleSubmit = async () => {
      // Ambil saldo token ERC20 pengguna
      const userBalance = await getERC20Balance(stake.token1.tokenAddress, acct);

      // Cek apakah staking telah dimulai
      bought = await getAmountParticipated(stake.stakingAddress, acct, stake.stakingType);
      console.log("bought", bought);
      const userAllocation = formatBigToNum(bought[0].toString(), 19, 4);

      const start = new Date(stake.startDate);
      const now = new Date();
      if (now < start) {
        toast.error("Sale not started yet");
        return;
      }

      // Cek apakah saldo cukup
      if (parseFloat(amount) > parseFloat(userBalance)) {
        toast.error("Insufficient balance");
        return;
      }

      const stakingContractAddress = stake.stakingAddress;
      let abi;
      if (stake.stakingType === "public") {
          abi = StakingAbi;
      }

      const contract = new Contract(
        stakingContractAddress,
        abi,
        library.getSigner()
      );

      const amountDeposit = parseEther(amount.toString()).toString();
      openLoadingModal();
      try {
        // Approve token ERC20 jika diperlukan
        const approvalContract = new Contract(
          stake.token1.tokenAddress,
          ERC20,
          library.getSigner()
        );
        try {
          const approval = await approvalContract.approve(
            stake.stakingAddress,
            ethers.constants.MaxUint256
          );
          await approval.wait();
        } catch (err) {
          console.log(err);
        }

        // Lakukan staking token ERC20
        const tx = await contract.stake(acct, amountDeposit, {gasLimit: 5000000});
        await tx.wait();

        try {
          const stakingInfo = await getStakingInfo(
            stake.stakingAddress,
            stake.stakingType,
            stake.token1.tokenSymbol
          );
          const initialTotalStaked = stake.rewardAmount;
          let res;
          let percents;
          if (stake.stakingType === "public") {
            res = await stakingInfo.rewardBalance;

            if (initialTotalStaked === 0) {
              percents = 0;
            } else {
              const balanceDecrease = initialTotalStaked - res;
              percents = (balanceDecrease / initialTotalStaked) * 100;
            }

            console.log("percents:", percents);
          }
          const filled = formatBigToNum(percents.toString(), 0, 1);

          const finalStakingObject = {
            saleId: stake.saleId,
            saleAddress: stake.saleAddress,
            stakingType: stake.stakingType,
            github: stake.github,
            website: stake.website,
            twitter: stake.twitter,
            whitepaper: stake.whitepaper,
            linkedin: stake.linkedin,
            discord: stake.discord,
            telegram: stake.telegram,
            image1: stake.image1,
            image2: stake.image2,
            token1: stake.stakingToken,
            token2: stake.rewardToken,
            rewardAmount: stake.rewardAmount,
            endDate: stake.endDate,
            startDate: stake.startDate,
            owner: stake.owner,
            isFinished: stake.isFinished,
            chainID: stake.chainID,
            filledPercent: filled,
          };
          console.log(finalStakingObject, "finalStakingObject");
          const res2 = await axios.put(`${BACKEND_URL}/api/staking/${objId}`, {
            staking: finalStakingObject,
          });
          console.log(res2, "res2");
        } catch (err) {
          console.log(err);
        }
        toast.success("Transaction successful");
        showModal(false);
      } catch (err) {
        toast.error("Transaction failed");
        console.log(err);
      }
      closeLoadingModal();
  };

  // Fungsi untuk mengambil saldo token ERC20 pengguna
  const getERC20Balance = async (tokenAddress, account) => {
    const tokenContract = new Contract(tokenAddress, ERC20, library.getSigner());
    const balance = await tokenContract.balanceOf(account);
    return formatBigToNum(balance.toString(), 18, 4); // Ubah sesuai dengan desimal token
  };


  const handleInput = async (e) => {
    setAmount(Number(e.target.value));
    setUsdAmount((Number(e.target.value) * nativePrice).toFixed(3));
    setTokenAmount((Number(e.target.value) * tokenPrice).toFixed(5));
  };

  const handleMax = () => {
    //balanceBNB to number everything after , is not removed
    if (balanceBNB === null) {
      toast.error("Connect wallet first");
      return;
    }

    let bal = balanceBNB;
    const amt = parseFloat(stake.maxAllocation);
    setAmount(amt);
    console.log(amt);
  };
  const handleHalf = () => {
    if (balanceBNB === null) {
      toast.error("Connect wallet first");
      return;
    }

    let bal = balanceBNB;
    const amt = parseFloat(stake.maxAllocation);
    setAmount(amt / 2);
  };

  useEffect(() => {
  let nativePrice; // Declare nativePrice variable outside of if-else blocks

  if (chainId === 1116) {
    nativePrice = nativePriceData?.core?.usd;
  } else if (chainId === 84532) {
    nativePrice = nativePriceData?.ethereum?.usd;
  } else if (chainId === 2040) {
    nativePrice = nativePriceData?.vanry?.usd;
  }

  if (typeof nativePrice === 'number') {
    const formattedNativePrice = nativePrice.toFixed(0);
    setNativePrice(formattedNativePrice);
    console.log('nativePrice: ', formattedNativePrice);
  } else {
    console.log('nativePrice is not a valid number:', nativePrice);
  }
}, [chainId, nativePriceData]);


  return (
    <>
      <div
        className={`w-screen h-screen flex backdrop-blur-[7px] flex-col justify-center items-center bg-[#F2F3F5] dark:bg-dark dark:bg-opacity-40 bg-opacity-40`}
      >
        <div className="w-[90%] max-w-[420px] rounded-[10px] px-9 py-5 bg-white dark:bg-dark-1">
          <div className="flex justify-between items-center  ">
            <span className="text-dark-text dark:text-light-text font-gilroy font-semibold text-lg">
              Join Staking
            </span>

            <div
              className="flex items-center cursor-pointer"
              onClick={() => showModal(false)}
            >
              <span className="text-sm font-gilroy font-semibold text-dark-text dark:text-light-text mr-2">
                Close
              </span>
              <div className="flex justify-center items-center bg-[#E56060] text-[#E56060] bg-opacity-10 rounded-full w-[15px] h-[15px]">
                &#10005;
              </div>
            </div>
          </div>

          <div className="mt-7">
            <div className="flex justify-between items-center">
              <span className="font-semibold text-sm text-gray dark:text-gray-dark">
                Amount {stake.token1.tokenSymbol}
              </span>

              <span className="font-medium text-sm text-dim-text dark:text-dim-text-dark">
                Balance:
                <span className="text-dark-text dark:text-light-text">
                  {balanceBNB && balanceBNB.toLocaleString()}
                </span>
              </span>
            </div>
          </div>
          <div className="mt-2 flex justify-between items-center rounded-md bg-[#F5F1EB] dark:bg-dark-3 px-2 py-2">
            <div className="flex flex-col">
              <input
                className="bg-transparent outline-none text-sm font-medium text-dark-text dark:text-light-text"
                type="number"
                placeholder="0.0"
                onChange={handleInput}
                value={amount}
                step={0.001}
                // max={stake.maxAllocation}
                // min={stake.minAllocation}
              />

              <span className="mt-1 text-sm font-medium text-gray dark:text-gray-dark">
                ~ ${usdAmount}
              </span>
            </div>

            <div className="border-l border-dashed pl-5 border-dim-text dark:border-dim-text-dark ">
              <button
                onClick={handleHalf}
                className="rounded-sm bg-[#C29D46] bg-opacity-[0.08] py-[2px] px-4 text-[#C89211] text-sm onhover:cursor-pointer"
              >
                Half
              </button>

              <div
                onClick={handleMax}
                className="mt-3 rounded-sm bg-primary-green bg-opacity-[0.08] py-[2px] px-4 text-primary-green text-sm"
              >
                Max
              </div>
            </div>
          </div>
        </div>

        <div className="w-[90%] max-w-[420px]">
          <button
            className="w-full bg-primary-green text-white py-2 rounded-md font-gilroy font-bold text-xl"
            onClick={handleSubmit}
          >
            DEPOSIT
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
