export const baseTokenList = [
  {
    id: 1,
    chainId: 84532,
    name: 'Wrapped ETH',
    symbol: 'WETH',
    address: '0x4200000000000000000000000000000000000006',
    decimals: 18,
    logoUrl: 'https://example.com/bnb-logo.png',
  },
  {
    id: 2,
    chainId: 84532,
    name: 'VESTINGSALE TOKEN',
    symbol: 'VELE',
    address: '0x5dcB60BbE1861b17cA7B4476E614F0608135d4dA',
    decimals: 18,
    logoUrl: 'https://example.com/cake-logo.png',
  },
  {
    id: 3,
    chainId: 84532,
    name: 'SALEVESTING TOKEN',
    symbol: 'PELE',
    address: '0x0a66604F4a14D38a0AB3B0e99a38308B4959144B',
    decimals: 18,
    logoUrl: 'https://example.com/busd-logo.png',
  },
  {
    id: 4,
    chainId: 84532,
    name: 'ETH',
    symbol: 'ETH',
    address: 'native', // Special identifier for the native token
    decimals: 18,
    logoUrl: 'https://example.com/eth-logo.png',
  },
];


export const vanarTokenList = [
  {
    id: 1,
    chainId: 84532,
    name: 'Wrapped ETH',
    symbol: 'WETH',
    address: '0x4200000000000000000000000000000000000006',
    decimals: 18,
    logoUrl: 'https://example.com/bnb-logo.png',
  },
  {
    id: 2,
    chainId: 84532,
    name: 'VESTINGSALE TOKEN',
    symbol: 'VELE',
    address: '0x5dcB60BbE1861b17cA7B4476E614F0608135d4dA',
    decimals: 18,
    logoUrl: 'https://example.com/cake-logo.png',
  },
  {
    id: 3,
    chainId: 84532,
    name: 'SALEVESTING TOKEN',
    symbol: 'PELE',
    address: '0x0a66604F4a14D38a0AB3B0e99a38308B4959144B',
    decimals: 18,
    logoUrl: 'https://example.com/busd-logo.png',
  },
  // tambahkan token lainnya sesuai kebutuhan
];

export const coreTokenList = [
  {
    id: 1,
    chainId: 84532,
    name: 'Wrapped ETH',
    symbol: 'WETH',
    address: '0x4200000000000000000000000000000000000006',
    decimals: 18,
    logoUrl: 'https://example.com/bnb-logo.png',
  },
  {
    id: 2,
    chainId: 84532,
    name: 'VESTINGSALE TOKEN',
    symbol: 'VELE',
    address: '0x5dcB60BbE1861b17cA7B4476E614F0608135d4dA',
    decimals: 18,
    logoUrl: 'https://example.com/cake-logo.png',
  },
  {
    id: 3,
    chainId: 84532,
    name: 'SALEVESTING TOKEN',
    symbol: 'PELE',
    address: '0x0a66604F4a14D38a0AB3B0e99a38308B4959144B',
    decimals: 18,
    logoUrl: 'https://example.com/busd-logo.png',
  },
  // tambahkan token lainnya sesuai kebutuhan
];
