import React, { useState, useEffect } from "react";
import Web3 from 'web3';
import FactoryABI from "config/abi/SwapFactory.json";
import RouterABI from "config/abi/SwapRouter.json";
import {
  baseTokenList,
  coreTokenList,
  vanarTokenList,
} from "./tokenlist";
import { useEthers } from "@usedapp/core";
import { useDefaultChainId } from "config/useDefaultChainId";
import { SWAP_ROUTER, SWAP_FACTORY } from "config/constants/address";
import "./components/styles.css";

const SwapComponent = () => {
  const chainId = useDefaultChainId();
  console.log("Current chainId:", chainId);
  const { account } = useEthers();
  const [tokenList, setTokenList] = useState([]);
  console.log("baseTokenList:", baseTokenList);
  console.log("tokenList:", tokenList);
  const [inputToken, setInputToken] = useState('');
  const [outputToken, setOutputToken] = useState('');
  const [amountIn, setAmountIn] = useState('');
  const [amountOut, setAmountOut] = useState('');
  const [slippage, setSlippage] = useState(1); // Default slippage tolerance of 1%
  const [tokenPrice, setTokenPrice] = useState(null);

  // Instance of Web3 and contracts
  const web3 = new Web3(window.ethereum);
  const factoryAddress = SWAP_FACTORY[chainId]; // Address of PancakeSwap Factory
  const routerAddress = SWAP_ROUTER[chainId]; // Address of PancakeSwap Router V2
  const factoryContract = new web3.eth.Contract(FactoryABI, factoryAddress);
  const routerContract = new web3.eth.Contract(RouterABI, routerAddress);

  const nativeTokens = {
    1116: { symbol: 'CORE', address: 'CORE' }, // Example for Ethereum chain
    84532: { symbol: 'ETH', address: 'ETH' }, // Example for Binance Smart Chain
    2040: { symbol: 'VANRY', address: 'VANRY' } // Example for VeChain
  };

  useEffect(() => {
    let tokens = [];
    if (chainId === 1116) {
      tokens = [nativeTokens[1116], ...coreTokenList];
    } else if (chainId === 84532) {
      tokens = [nativeTokens[84532], ...baseTokenList];
    } else if (chainId === 2040) {
      tokens = [nativeTokens[2040], ...vanarTokenList];
    }
    setTokenList(tokens);
  }, [chainId]);

  const handleSwap = async () => {
    try {
      const nativeTokenSymbol = nativeTokens[chainId]?.symbol;
      const isNativeInput = inputToken === nativeTokenSymbol;
      const isNativeOutput = outputToken === nativeTokenSymbol;

      // Convert input amount to wei if necessary
      const amountInWei = web3.utils.toWei(amountIn.toString(), 'ether');

      // Calculate minimum amount out based on slippage tolerance
      const amountOutMin = web3.utils.toWei((amountOut * (1 - slippage / 100)).toString(), 'ether');

      if (isNativeInput) {
        // Swap from native token to ERC-20 token
        await routerContract.methods.swapExactETHForTokens(
          amountOutMin,
          [outputToken],
          account,
          Date.now() + 1000 * 60 * 10 // deadline 10 minutes from now
        ).send({ from: account, value: amountInWei });
      } else if (isNativeOutput) {
        // Swap from ERC-20 token to native token
        await routerContract.methods.swapExactTokensForETH(
          amountInWei,
          amountOutMin,
          [inputToken],
          account,
          Date.now() + 1000 * 60 * 10 // deadline 10 minutes from now
        ).send({ from: account });
      } else {
        // Approve Router to spend input token on behalf of user
        await routerContract.methods.approve(routerAddress, amountInWei).send({ from: account });

        // Execute swap
        await routerContract.methods.swapExactTokensForTokens(
          amountInWei,
          amountOutMin,
          [inputToken, outputToken],
          account,
          Date.now() + 1000 * 60 * 10, // deadline 10 minutes from now
        ).send({ from: account });
      }

      alert('Swap successful!');
    } catch (error) {
      console.error('Swap error:', error);
      alert('Swap failed! Please check console for error.');
    }
  };

  // Function to calculate output amount based on input amount
  const calculateOutputAmount = async () => {
    try {
      if (!amountIn || isNaN(amountIn)) {
        setAmountOut('');
        return;
      }
      const inputAmountWei = web3.utils.toWei(amountIn.toString(), 'ether');

      const amountsOut = await routerContract.methods.getAmountsOut(
        inputAmountWei,
        [inputToken, outputToken]
      ).call();

      const outputAmountWei = amountsOut[1]; // The output amount for the final token in the path
      setAmountOut(web3.utils.fromWei(outputAmountWei.toString(), 'ether'));
    } catch (error) {
      console.error('Error calculating output amount:', error);
      setAmountOut('');
    }
  };

  // Function to fetch LP reserves and calculate token price
  const fetchTokenPrice = async () => {
    try {
      const pairAddress = await factoryContract.methods.getPair(inputToken, outputToken).call();
      const pairContract = new web3.eth.Contract(FactoryABI, pairAddress);
      const reserves = await pairContract.methods.getReserves().call();

      const [reserve0, reserve1] = reserves;
      const token0 = await pairContract.methods.token0().call();
      const token1 = await pairContract.methods.token1().call();

      let price;
      if (token0.toLowerCase() === inputToken.toLowerCase()) {
        price = reserve1 / reserve0;
      } else {
        price = reserve0 / reserve1;
      }

      setTokenPrice(price);
    } catch (error) {
      console.error('Error fetching token price:', error);
      setTokenPrice(null);
    }
  };

  useEffect(() => {
    if (inputToken && outputToken) {
      fetchTokenPrice();
    }
  }, [inputToken, outputToken]);

  // Handle change in input amount
  const handleInputChange = (e) => {
    setAmountIn(e.target.value);
    calculateOutputAmount();
  };

  return (
    <div className="card">
      <h2>Token Swap</h2>
      <div className="input-group">
        <label>Input Token:</label>
        <select value={inputToken} onChange={(e) => setInputToken(e.target.value)}>
          {tokenList.map(token => (
            <option key={token.address} value={token.address}>
              {token.symbol}
            </option>
          ))}
        </select>
      </div>
      <div className="input-group">
        <label>Output Token:</label>
        <select value={outputToken} onChange={(e) => setOutputToken(e.target.value)}>
          {tokenList.map(token => (
            <option key={token.address} value={token.address}>
              {token.symbol}
            </option>
          ))}
        </select>
      </div>
      <div className="input-group">
        <label>Amount In:</label>
        <input type="text" value={amountIn} onChange={handleInputChange} />
      </div>
      <div className="input-group">
        <label>Amount Out:</label>
        <input type="text" value={amountOut} readOnly />
      </div>
      <div className="input-group">
        <label>Slippage Tolerance (%):</label>
        <input type="number" value={slippage} onChange={(e) => setSlippage(parseFloat(e.target.value))} />
      </div>
      <div className="input-group">
        <label>Token Price:</label>
        <input type="text" value={tokenPrice !== null ? tokenPrice : 'Fetching...'} readOnly />
      </div>
      <button className="button" onClick={handleSwap}>Swap</button>
    </div>
  );
};

export default SwapComponent;
