import React from 'react';

const Slider = () => {
  return (
    <div className="flex space-x-4">
      <div className="w-1/3">
        <img src="https://static.vecteezy.com/system/resources/previews/027/010/377/original/banner-for-website-with-offer-and-3d-coin-of-bitcoin-in-blue-and-pink-hologram-of-digital-rings-in-dark-room-vector.jpg" alt="Ad 1" className="w-[60%] h-[40%] " />
      </div>
      <div className="w-1/3">
        <img src="https://static.vecteezy.com/system/resources/previews/027/010/377/original/banner-for-website-with-offer-and-3d-coin-of-bitcoin-in-blue-and-pink-hologram-of-digital-rings-in-dark-room-vector.jpg" alt="Ad 2" className="w-[60%] h-[40%]" />
      </div>
      <div className="w-1/3">
        <img src="https://static.vecteezy.com/system/resources/previews/027/010/377/original/banner-for-website-with-offer-and-3d-coin-of-bitcoin-in-blue-and-pink-hologram-of-digital-rings-in-dark-room-vector.jpg" alt="Ad 3" className="w-[60%] h-[40%]" />
      </div>
    </div>
  );
}

export default Slider;
