import React from 'react';
import headingImage from './images/icons/steps.png';
import stone1 from './images/icons/stone1.svg';
import stone2 from './images/icons/stone2.svg';
import stone3 from './images/icons/stone3.svg';
import key1 from './images/icons/Key_icon1.png';
import key2 from './images/icons/Key_icon2.png';
import key3 from './images/icons/Key_icon3.png';
import key4 from './images/icons/Key_icon4.png';
import key5 from './images/icons/Tier4.png';
import key6 from './images/icons/tier_icon2.png';
import './style.css';
import './animate.css';
import './responsive.css';
import './bootstrap.min.css';

export default function How() {
  return (
    <div className="Key_Points_section">
      <div className="keyPointBg">
        <div className="bgStone">
          <span className="stone1">
            <img src={stone1} alt="" className="img-fluid" />
          </span>
          <span className="stone2">
            <img src={stone2} alt="" className="img-fluid" />
          </span>
          <span className="stone3">
            <img src={stone3} alt="" className="img-fluid" />
          </span>
        </div>
      </div>
      <div className="container">
        <div className="sec-inner align-items-center mb-30">
          <div className="sec-heading">
            <div className="flex sub-inner mb-15">
              <span className="sub-title">FEATURES</span>
              <img className="heading-left-image" src={headingImage} alt="Steps-Image" />
            </div>
            <h2 className="title">Key Points</h2>
          </div>
          <div className="Key_Points_Conttent">
            <div className="row">
              <div className="col-md-3">
                <div className="Key_Points_Items Key_Points_card wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.4s">
                  <div className="Key_Points_Item_img">
                    <img src={key5} alt="icon" className="img-fluid3" />
                  </div>
                  <div className="Key_Points_Item_Text">
                    <h3>Decentralized Fundraising Platform</h3>
                    <p>Direct, secure, and transparent fundraising via DEXs, eliminating intermediaries and ensuring trust through blockchain technology.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="Key_Points_Items Key_Points_card wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.5s">
                  <div className="Key_Points_Item_img">
                    <img src={key2} alt="icon" className="img-fluid3" />
                  </div>
                  <div className="Key_Points_Item_Text">
                    <h3>Pre-IDO Project Incubation</h3>
                    <p>Comprehensive support including mentorship, technical assistance, marketing, and partnerships to prepare projects for successful IDOs.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="Key_Points_Items Key_Points_card wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="0.6s">
                  <div className="Key_Points_Item_img">
                    <img src={key3} alt="icon" className="img-fluid3" />
                  </div>
                  <div className="Key_Points_Item_Text">
                    <h3>Token Sale Management</h3>
                    <p>Automated whitelisting, tiered participation, fair allocation, and audited smart contracts for secure and efficient IDO processes.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="Key_Points_Items Key_Points_card wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="0.7s">
                  <div className="Key_Points_Item_img">
                    <img src={key4} alt="icon" className="img-fluid3" />
                  </div>
                  <div className="Key_Points_Item_Text">
                    <h3>Post-IDO Support and Liquidity Provision</h3>
                    <p>Ongoing support, DEX listings, marketing, community management, and analytics to ensure project success post-IDO.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
