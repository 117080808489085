import React, { useState, useEffect } from "react";
import BackArrowSVG from "../../../svgs/back_arrow";
import Web3 from 'web3';
import PreviewHeader from "../../Common/PreviewHeader";
import HeadingTags from "../../TokenLocker/Subcomponents/HeadingTags";
import CalendarField from "./Subcomponents/CalendarField";
import CurrencyOptions from "./Subcomponents/CurrencyOption";
import DexOptions from "./Subcomponents/DexOption";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Input from "./Subcomponents/Input";
import PresaleStandard from "./Subcomponents/PresaleStandard";
import PresalePrivate from "./Subcomponents/PresalePrivate";
import { useModal } from "react-simple-modal-provider";
import { ethers } from "ethers";
import { useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import ERCAbi from "../../../config/abi/ERC20.json";
import { useDefaultChainId } from "config/useDefaultChainId";
import { approveTokens } from "utils/deployStake";
import { Staking_Factory } from "config/constants/LaunchpadAddress";

const currencies1 = [
  {
    id: 1,
    name: "CORE",
    symbol: "CORE",
    icon: "/images/cards/core.png",
    address: "",
  },
];

const currencies2 = [
  {
    id: 1,
    name: "VANAR",
    symbol: "VANRY",
    icon: "/images/cards/Vanar.png",
    address: "",
  },
];

export default function Presale({ setActive, saleType, setStakingObject, token }) {
  console.log("stakingData:", token.rewardToken.tokenAddress);
  const chainId = useDefaultChainId();
  const [currencySelected, setCurrencySelected] = useState(1);
  const [currencies, setCurrencies] = useState(currencies1);
  const [startDate, setStartDate] = useState();
  const [rewardAmount, setRewardAmount] = useState();
  const [minAllocation, setMinAllocation] = useState();
  const [maxAllocation, setMaxAllocation] = useState();
  const [duration, setDuration] = useState();
  const [connected, setConnected] = useState(false);
  const { account, library } = useEthers();
  const [enoughBalance, setEnoughBalance] = useState(false);

  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");
  const { open: openModal } = useModal("ConnectionModal");

  useEffect(() => {
    if (chainId === 1116) {
      setCurrencies(currencies1);
    } else if (chainId === 2040) {
      setCurrencies(currencies2);
    } 

  }, [chainId]);

  const approveTokens = async () => {
    const tokenAddress = token.rewardToken.tokenAddress;
    const contract = new Contract(
      tokenAddress,
      ERCAbi,
      library.getSigner()
    );

    const amount = ethers.constants.MaxUint256;

    try {
      console.log("Approving tokens...");
      const publicFactory = Staking_Factory[chainId];
      const approval = await contract.approve(publicFactory, amount);

      await approval.wait();
      if (approval) {
        console.log("Token approval successful!");
      } else {
        console.error("Token approval failed.");
      }
    } catch (error) {
      return false;
    }
    return true;
  };


  const handleSubmit = async () => {
    try {
    //if start date is less than current date and time, and also if end date is less than start date
    const now = moment();
    if (account === undefined) {
      toast.error("Please connect your wallet");
    }

    let res = false;
    openLoadingModal();
      if (currencySelected === 1) {
        if (chainId === chainId) {
          console.log('currencySelected:', currencySelected);
          res = await approveTokens();
        }
      }

    if (!res) {
      closeLoadingModal();
      return;
    }

    const endDuration = duration * 86400;
    const endDate = startDate + endDuration;

    const stakingObject = {
      currency: currencies[currencySelected - 1],
      rewardAmount: rewardAmount,
      minAllocation: minAllocation,
      maxAllocation: maxAllocation,
      startDate: startDate,
      endDate: endDate,
      duration: duration,
      isFinished: false,
    };

    setStakingObject(stakingObject);
    console.log(stakingObject);
    closeLoadingModal();
    setActive("Project Details");
  } catch (error) {
    console.error("Error in handleSubmit:", error);
    console.error(error.message);
    console.error(error.stack);
    closeLoadingModal();
  }
  };

  useEffect(() => {
    if (!account) {
      setConnected(false);
      openModal();
    } else {
      setConnected(true);
    }
  }, [account]);

  return (
    <>
      <div className="w-full">
        {/* sticky navbar */}
        <HeadingTags name={"Chain"} required />

        {/* Currency Options */}
        <div className="grid grid-cols-2 md:grid-cols-2 gap-4 mt-4">
          {currencies.map((currency) => (
            <CurrencyOptions
              key={currency.id}
              selected={currency.id === currencySelected}
              setCurrency={setCurrencySelected}
              {...currency}
            />
          ))}
        </div>

        <PreviewHeader heading={"Presale Details"} />

          <>
            <Input
              heading={"Reward Amount (Total)"}
              currencies={currencies}
              currencySelected={currencySelected}
              changeState={setRewardAmount}
            />
            <Input
              heading={"Min Stake"}
              currencies={currencies}
              currencySelected={currencySelected}
              changeState={setMinAllocation}
            />
            <Input
              heading={"Max Stake"}
              currencies={currencies}
              currencySelected={currencySelected}
              changeState={setMaxAllocation}
            />
            <Input
              heading={"Duration (days)"}
              currencies={currencies}
              currencySelected={currencySelected}
              changeState={setDuration}
            />
            <div className="flex flex-col md:flex-row items-center gap-4 mt-4">
              <div className="w-full md:w-1/2">
                <CalendarField heading={"Starts On (UTC)"} setFunction={setStartDate} />
              </div>
            </div>
          </>

        <div className="mt-10">
          <div className="flex justify-end items-center mb-10">
            <button
              className="bg-white dark:bg-transparent mr-5 flex items-center gap-2 py-[10px] px-5"
              onClick={() => setActive("Token Info")}
            >
              <BackArrowSVG className="fill-dark-text dark:fill-light-text" />
              <span className="font-gilroy font-medium text-sm text-dark-text dark:text-light-text">
                Go Back
              </span>
            </button>

            <button
              className="bg-primary-green hover:opacity-40 disabled:bg-light-text text-white font-gilroy font-bold px-8 py-3 rounded-md"
              // disabled={address.length < 5}
              onClick={handleSubmit}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
