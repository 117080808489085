import { Contract } from "@ethersproject/contracts";
import Web3 from 'web3';
import PublicAbi from "../config/abi/PublicLaunchpadAbi.json";
import PublicVestingAbi from "../config/abi/PublicVestingAbi.json";
import PrivateAbi from "../config/abi/PrivateLaunchpadAbi.json";
import FairAbi from "../config/abi/FairLaunchpadAbi.json";
import PublicErcAbi from "../config/abi/PublicLaunchErcAbi.json";
import PrivateErcAbi from "../config/abi/PrivateLaunchErcAbi.json";
import FairErcAbi from "../config/abi/FairErcAbi.json";
import PublicSaleAbi from "../config/abi/PublicSale.json";
import PublicVestAbi from "../config/abi/PublicVesting.json";
import {
  Public_FACTORY,
  ROUTER_ADDRESS,
  ADMIN_ADDRESS,
  Private_FACTORY,
  FairLaunch_FACTORY,
  PublicVesting,
  LOCK_ADDRESS,
  USDT_ADDRESS,
  USDC_ADDRESS,
  TOWER_TOKEN,
  PublicErc_FACTORYADRESS,
  PrivateErc_FACTORYADRESS,
  FairLaunchErc_FACTORY,
  BSC_PUBLIC_ERC_FACTORYADDRESS,
} from "config/constants/LaunchpadAddress";
import { FACTORY_ADDRESS } from "config/constants/address";
import { parseEther, parseUnits } from "ethers/lib/utils";
import { ethers, utils } from "ethers";
import ERCAbi from "../config/abi/ERC20.json";
import { useDefaultChainId } from "config/useDefaultChainId";

export const approveTokens = async (library, token, factoryContractAddress) => {
  const contract = new Contract(
    token.tokenAddress,
    ERCAbi,
    library.getSigner()
  );

  const amount = ethers.constants.MaxUint256;

  try {
    console.log("Approving tokens...");
    const approval = await contract.approve(factoryContractAddress, amount);

    await approval.wait();
    if (approval) {
      console.log("Token approval successful!");
    } else {
      console.error("Token approval failed.");
    }
  } catch (error) {
    return false;
  }
  return true;
};

// Public sale
/* formating break to find functions easily

*/

export const deployPublicSaleMainnet = async (
  token,
  saleObject,
  library,
  account,
  deploymentFee,
  saleData,
  chainId,
  closeLoadingModal
) => {
  const publicFactory = Public_FACTORY[chainId];
  const contract = new Contract(
    publicFactory,
    PublicAbi,
    library.getSigner()
  );

  console.log('publicFactory:', publicFactory);

  const saleId = await contract.getNumberOfSalesDeployed();
  const routerAddress = ROUTER_ADDRESS[chainId];
  const adminAddress = ADMIN_ADDRESS[chainId];
  // 2nd - with uints [minParticipation, maxParticipation, lp%, dex listing rate,lpLockPeriod, saleEnd, saleStart, hardCap(tokens), softCap(bnb)]
  let deployedAddress;
  let finalSaleObject;
  //console
  console.log((
    [routerAddress, adminAddress, token.tokenAddress, account],
    [
      parseEther(saleObject.minAllocation.toString()).toString(),
      parseEther(saleObject.maxAllocation.toString()).toString(),
      (saleObject.amountLiquidity * 100).toString(),
      parseUnits(saleObject.listing.toString(),token.tokenDecimals).toString(),
      (saleObject.lockup * 86400).toString(),
      parseUnits(
        saleObject.presalePrice.toString(),
        token.tokenDecimals
      ).toString(),
      saleObject.endDate,
      saleObject.startDate,
      parseEther(saleObject.hardCap.toString()).toString(),
      parseEther(saleObject.softCap.toString()).toString(),
    ],
    saleObject.unsoldToken === "Burn" ? true : false,
    {
      value: utils.parseEther(deploymentFee.toString()),
    }
  ));
  try {
    const tx = await contract.deployNormalSale(
      [routerAddress, adminAddress, token.tokenAddress, account],
      [
        parseEther(saleObject.minAllocation.toString()).toString(),
        parseEther(saleObject.maxAllocation.toString()).toString(),
        (saleObject.amountLiquidity * 100).toString(),
        parseUnits(
          saleObject.listing.toString(),
          token.tokenDecimals
        ).toString(),
        (saleObject.lockup * 86400).toString(),
        parseUnits(
          saleObject.presalePrice.toString(),
          token.tokenDecimals
        ).toString(),
        saleObject.endDate,
        saleObject.startDate,
        parseEther(saleObject.hardCap.toString()).toString(),
        parseEther(saleObject.softCap.toString()).toString(),
      ],
      saleObject.unsoldToken === "Burn" ? true : false,
      {
        value: utils.parseEther(deploymentFee.toString()),
        gasLimit: 5000000,
      }
    );
    await tx.wait();

    deployedAddress = await contract.saleIdToAddress(saleId.toNumber());

    finalSaleObject = {
      saleId: saleId.toNumber(),
      saleAddress: deployedAddress,
      saleType: saleData.type,
      github: saleData.github,
      website: saleData.website,
      twitter: saleData.twitter,
      whitepaper: saleData.whitepaper,
      whitelistEndDate: saleObject.whitelistEndDate,
      chainID: chainId,
      linkedin: saleData.linkedin,
      discord: saleData.discord,
      telegram: saleData.telegram,
      kyc: saleData.kyc,
      audit: saleData.audit,
      safu: saleData.safu,
      youtube: saleData.youtube,
      image: saleData.image,
      banner: saleData.banner,
      name: saleData.name,
      description: saleData.description,
      tags: saleData.tags,
      tags2: saleData.tags2,
      token: token,
      firstRelease: saleObject.firstRelease,
      percent1: saleObject.percent1,
      percent2: saleObject.percent2,
      percent3: saleObject.percent3,
      percent4: saleObject.percent4,
      tgeRelaseDate: saleObject.tgeRelaseDate,
      releaseDate1: saleObject.releaseDate1,
      releaseDate2: saleObject.releaseDate2,
      releaseDate3: saleObject.releaseDate3,
      releaseDate4: saleObject.releaseDate4,
      minAllocation: saleObject.minAllocation,
      maxAllocation: saleObject.maxAllocation,
      amountLiquidity: saleObject.amountLiquidity,
      listing: saleObject.listing,
      lockup: saleObject.lockup,
      presalePrice: saleObject.presalePrice,
      fairAmount: saleObject.fairAmount,
      endDate: saleObject.endDate,
      startDate: saleObject.startDate,
      hardCap: saleObject.hardCap,
      softCap: saleObject.softCap,
      unsoldToken: saleObject.unsoldToken,
      currency: saleObject.currency,
      dex: saleObject.dex,
      whiteisting: saleObject.whiteisting,
      whiteListedAddresses: saleObject.whiteListedAddresses,
      // whiteListedEndDates: saleObject.whiteListedEndDates,
      owner: account,
      isFinished: false,
    };
    if (saleObject.whiteisting) {
      try {
        const contract = new Contract(
          deployedAddress,
          PublicSaleAbi,
          library.getSigner()
        );

        const ts = await contract.setWLEnabled(true);
        await ts.wait();

        const tx = await contract.setMultiplyAddressesWL(
          saleObject.whiteListedAddresses?.map((address) => address),
          true
        );
        await tx.wait();
        alert("Whitelisting Done");
        return finalSaleObject;
      } catch (error) {
        console.log(error);
        alert("Whitelisting Failed");
        closeLoadingModal();
      }
    } else return finalSaleObject;
  } catch (error) {
    console.error(error);
    console.log("Revert Reason:", error.reason || "N/A");
    closeLoadingModal();
    alert("Transaction Failed");
  }
};

export const deployPublicSaleVesting = async (
  token,
  saleObject,
  library,
  account,
  deploymentFee,
  saleData,
  chainId,
  closeLoadingModal
) => {
  console.log(chainId, "chainId");
  const publicVestFactory = PublicVesting[chainId];
  const contract = new Contract(
    publicVestFactory,
    PublicVestingAbi,
    library.getSigner()
  );

  let saleId;

  try {
      const saleId = await contract.getNumberOfSalesDeployed();
      console.log(`Number of Sales Deployed: ${saleId}`);
  } catch (error) {
      console.error("Error fetching number of sales deployed:", error);
  }
  const routerAddress = ROUTER_ADDRESS[chainId];

  console.log(routerAddress, "routerAddress");
  const adminAddress = ADMIN_ADDRESS[chainId];
  // 2nd - with uints [minParticipation, maxParticipation, lp%, dex listing rate,lpLockPeriod, saleEnd, saleStart, hardCap(tokens), softCap(bnb)]
  let deployedAddress;
  let finalSaleObject;
  //console
  console.log((
    [routerAddress, adminAddress, token.tokenAddress, account]
  ),"first array");
  console.log((
    [
      parseEther(saleObject.minAllocation.toString()).toString(),
      parseEther(saleObject.maxAllocation.toString()).toString(),
      (saleObject.amountLiquidity * 100).toString(),
      parseUnits(
        saleObject.listing.toString(),
        token.tokenDecimals
      ).toString(),
      (saleObject.lockup * 86400).toString(),
      parseUnits(
        saleObject.presalePrice.toString(),
        token.tokenDecimals
      ).toString(),
      saleObject.endDate,
      saleObject.startDate,
      parseEther(saleObject.hardCap.toString()).toString(),
      parseEther(saleObject.softCap.toString()).toString(),
      saleObject.firstRelease,
      saleObject.percent1,
      saleObject.percent2,
      saleObject.percent3,
      saleObject.percent4,
      saleObject.tgeRelaseDate,
      saleObject.releaseDate1,
      saleObject.releaseDate2,
      saleObject.releaseDate3,
      saleObject.releaseDate4,
    ]
  ),"second array");
  console.log((
    saleObject.unsoldToken === "Burn" ? true : false
  ),"third array");
  console.log((
    {
      value: utils.parseEther(deploymentFee.toString()),
      gasLimit: 5000000,
    }
  ),"fourth array");
  try {
    const tx = await contract.deployNormalSale(
      [routerAddress, adminAddress, token.tokenAddress, account],
      [
        parseEther(saleObject.minAllocation.toString()).toString(),
        parseEther(saleObject.maxAllocation.toString()).toString(),
        (saleObject.amountLiquidity * 100).toString(),
        parseUnits(
          saleObject.listing.toString(),
          token.tokenDecimals
        ).toString(),
        (saleObject.lockup * 86400).toString(),
        parseUnits(
          saleObject.presalePrice.toString(),
          token.tokenDecimals
        ).toString(),
        saleObject.endDate,
        saleObject.startDate,
        parseEther(saleObject.hardCap.toString()).toString(),
        parseEther(saleObject.softCap.toString()).toString(),
      ],
      [saleObject.firstRelease, saleObject.percent1, saleObject.percent2, saleObject.percent3, saleObject.percent4],
      [saleObject.tgeRelaseDate, saleObject.releaseDate1, saleObject.releaseDate2, saleObject.releaseDate3, saleObject.releaseDate4 ],
      saleObject.unsoldToken === "Burn" ? true : false,
      {
        value: utils.parseEther(deploymentFee.toString()),
        gasLimit: 5000000,
      }
    );
    await tx.wait();

    deployedAddress = await contract.saleIdToAddress(saleId.toNumber());

    finalSaleObject = {
      saleId: saleId.toNumber(),
      saleAddress: deployedAddress,
      saleType: saleData.type,
      github: saleData.github,
      website: saleData.website,
      twitter: saleData.twitter,
      whitepaper: saleData.whitepaper,
      whitelistEndDate: saleObject.whitelistEndDate,
      chainID: chainId,
      linkedin: saleData.linkedin,
      discord: saleData.discord,
      telegram: saleData.telegram,
      kyc: saleData.kyc,
      audit: saleData.audit,
      safu: saleData.safu,
      youtube: saleData.youtube,
      image: saleData.image,
      banner: saleData.banner,
      name: saleData.name,
      description: saleData.description,
      tags: saleData.tags,
      tags2: saleData.tags2,
      token: token,
      firstRelease: saleObject.firstRelease,
      percent1: saleObject.percent1,
      percent2: saleObject.percent2,
      percent3: saleObject.percent3,
      percent4: saleObject.percent4,
      tgeRelaseDate: saleObject.tgeRelaseDate,
      releaseDate1: saleObject.releaseDate1,
      releaseDate2: saleObject.releaseDate2,
      releaseDate3: saleObject.releaseDate3,
      releaseDate4: saleObject.releaseDate4,
      minAllocation: saleObject.minAllocation,
      maxAllocation: saleObject.maxAllocation,
      amountLiquidity: saleObject.amountLiquidity,
      listing: saleObject.listing,
      lockup: saleObject.lockup,
      presalePrice: saleObject.presalePrice,
      fairAmount: saleObject.fairAmount,
      endDate: saleObject.endDate,
      startDate: saleObject.startDate,
      hardCap: saleObject.hardCap,
      softCap: saleObject.softCap,
      unsoldToken: saleObject.unsoldToken,
      currency: saleObject.currency,
      dex: saleObject.dex,
      whiteisting: saleObject.whiteisting,
      whiteListedAddresses: saleObject.whiteListedAddresses,
      // whiteListedEndDates: saleObject.whiteListedEndDates,
      owner: account,
      isFinished: false,
    };
    if (saleObject.whiteisting) {
      try {
        const contract = new Contract(
          deployedAddress,
          PublicVestingAbi,
          library.getSigner()
        );

        const ts = await contract.setWLEnabled(true);
        await ts.wait();

        const tx = await contract.setMultiplyAddressesWL(
          saleObject.whiteListedAddresses?.map((address) => address),
          true
        );
        await tx.wait();
        alert("Whitelisting Done");
        return finalSaleObject;
      } catch (error) {
        console.log(error);
        alert("Whitelisting Failed");
        closeLoadingModal();
      }
    } else return finalSaleObject;
  } catch (error) {
    console.error(error);
    console.log("Revert Reason:", error.reason || "N/A");
    closeLoadingModal();
    alert("Transaction Failed");
  }
};

export const deployPublicSaleERCMainnet = async (
  token,
  saleObject,
  library,
  account,
  deploymentFee,
  saleData,
  chainId,
  closeLoadingModal
) => {
  const tokenPublicSaleContract = PublicErc_FACTORYADRESS[chainId];
  const contract = new Contract(
    tokenPublicSaleContract,
    PublicErcAbi,
    library.getSigner()
  );

  const saleId = await contract.getNumberOfSalesDeployed();

  const routerAddress = ROUTER_ADDRESS[chainId];
  const adminAddress = ADMIN_ADDRESS[chainId];
  let PaymentToken = "";
  if (saleObject.currency.name === "Tether") {
    PaymentToken = USDT_ADDRESS[chainId];
  } else if (saleObject.currency.name === "USD Coin") {
    PaymentToken = USDC_ADDRESS[chainId];
  } else if (saleObject.currency.name === "Tower Token") {
    PaymentToken = TOWER_TOKEN[chainId];
  }

  // 2nd - with uints [minParticipation, maxParticipation, lp%, dex listing rate,lpLockPeriod, saleEnd, saleStart, hardCap(tokens), softCap(bnb)]
  console.log(PaymentToken, "PaymentToken");
  console.log(saleObject, "saleObject");
  try {
    const tx = await contract.deployERC20Sale(
      [routerAddress, adminAddress, token.tokenAddress, account, PaymentToken],
      [
        parseEther(saleObject.minAllocation.toString()).toString(),
        parseEther(saleObject.maxAllocation.toString()).toString(),
        (saleObject.amountLiquidity * 100).toString(),
        parseUnits(
          saleObject.listing.toString(),
          token.tokenDecimals
        ).toString(),
        (saleObject.lockup * 86400).toString(),
        parseUnits(
          saleObject.presalePrice.toString(),
          token.tokenDecimals
        ).toString(),
        saleObject.endDate,
        saleObject.startDate,
        parseEther(saleObject.hardCap.toString()).toString(),
        parseEther(saleObject.softCap.toString()).toString(),
      ],
      saleObject.unsoldToken === "Burn" ? true : false,
      {
        value: utils.parseEther(deploymentFee.toString()),
        gasLimit: 8000000,
      }
    );
    await tx.wait();

    const deployedAddress = await contract.saleIdToAddress(saleId.toNumber());

    const finalSaleObject = {
      saleId: saleId.toNumber(),
      saleAddress: deployedAddress,
      saleType: saleData.type,
      github: saleData.github,
      website: saleData.website,
      twitter: saleData.twitter,
      whitepaper: saleData.whitepaper,
      whitelistEndDate: saleObject.whitelistEndDate,
      chainID: chainId,
      linkedin: saleData.linkedin,
      discord: saleData.discord,
      telegram: saleData.telegram,
      kyc: saleData.kyc,
      audit: saleData.audit,
      safu: saleData.safu,
      youtube: saleData.youtube,
      image: saleData.image,
      banner: saleData.banner,
      name: saleData.name,
      description: saleData.description,
      tags: saleData.tags,
      tags2: saleData.tags2,
      token: token,
      firstRelease: saleObject.firstRelease,
      percent1: saleObject.percent1,
      percent2: saleObject.percent2,
      percent3: saleObject.percent3,
      percent4: saleObject.percent4,
      tgeRelaseDate: saleObject.tgeRelaseDate,
      releaseDate1: saleObject.releaseDate1,
      releaseDate2: saleObject.releaseDate2,
      releaseDate3: saleObject.releaseDate3,
      releaseDate4: saleObject.releaseDate4,
      minAllocation: saleObject.minAllocation,
      maxAllocation: saleObject.maxAllocation,
      amountLiquidity: saleObject.amountLiquidity,
      listing: saleObject.listing,
      lockup: saleObject.lockup,
      presalePrice: saleObject.presalePrice,
      fairAmount: saleObject.fairAmount,
      endDate: saleObject.endDate,
      startDate: saleObject.startDate,
      hardCap: saleObject.hardCap,
      softCap: saleObject.softCap,
      unsoldToken: saleObject.unsoldToken,
      currency: saleObject.currency,
      dex: saleObject.dex,
      whiteisting: saleObject.whiteisting,
      whiteListedAddresses: saleObject.whiteListedAddresses,
      owner: account,
      isFinished: false,
    };

    return finalSaleObject;
  } catch (error) {
    console.log(error);
    alert("Transaction Failed");
    if (closeLoadingModal) {
      closeLoadingModal();
    }
  }
};

// PRIVATE SALE
/* formating break to find functions easily


*/

export const deployPrivateSale = async (
  token,
  saleObject,
  library,
  account,
  deploymentFee,
  saleData,
  chainId,
  closeLoadingModal
) => {

  const privateFactory = Private_FACTORY[chainId];

  const contract = new Contract(
    privateFactory,
    PrivateAbi,
    library.getSigner()
  );
  const adminAddress = ADMIN_ADDRESS[chainId];

  const saleId = await contract.getNumberOfSalesDeployed();
  console.log(saleObject)
  try {
    const tx = await contract.deployNormalPrivateSale(
      [adminAddress, token.tokenAddress, account],
      [
        parseEther(Number(saleObject.minAllocation).toString()).toString(),
        parseEther(Number(saleObject.maxAllocation).toString()).toString(),
        parseUnits(
          saleObject.presalePrice.toString(),
          token.tokenDecimals
        ).toString(),
        saleObject.endDate,
        saleObject.startDate,
        parseEther(Number(saleObject.softCap).toString()).toString(),
        parseEther(Number(saleObject.hardCap).toString()).toString(),
      ],
      [saleObject.firstRelease, saleObject.percent1, saleObject.percent2, saleObject.percent3, saleObject.percent4],
      [saleObject.tgeRelaseDate, saleObject.releaseDate1, saleObject.releaseDate2, saleObject.releaseDate3, saleObject.releaseDate4 ],
      {
        value: utils.parseEther(deploymentFee),
        gasLimit: 8000000,
      }
    );
    await tx.wait();

    const deployedAddress = await contract.saleIdToAddress(saleId.toNumber());

    const finalSaleObject = {
      saleId: saleId.toNumber(),
      saleAddress: deployedAddress,
      saleType: saleData.type,
      github: saleData.github,
      website: saleData.website,
      twitter: saleData.twitter,
      whitepaper: saleData.whitepaper,
      whitelistEndDate: saleObject.whitelistEndDate,
      chainID: chainId,
      linkedin: saleData.linkedin,
      discord: saleData.discord,
      telegram: saleData.telegram,
      kyc: saleData.kyc,
      audit: saleData.audit,
      safu: saleData.safu,
      youtube: saleData.youtube,
      image: saleData.image,
      banner: saleData.banner,
      name: saleData.name,
      description: saleData.description,
      tags: saleData.tags,
      tags2: saleData.tags2,
      token: token,
      firstRelease: saleObject.firstRelease,
      percent1: saleObject.percent1,
      percent2: saleObject.percent2,
      percent3: saleObject.percent3,
      percent4: saleObject.percent4,
      tgeRelaseDate: saleObject.tgeRelaseDate,
      releaseDate1: saleObject.releaseDate1,
      releaseDate2: saleObject.releaseDate2,
      releaseDate3: saleObject.releaseDate3,
      releaseDate4: saleObject.releaseDate4,
      minAllocation: saleObject.minAllocation,
      maxAllocation: saleObject.maxAllocation,
      amountLiquidity: saleObject.amountLiquidity,
      listing: saleObject.listing,
      lockup: saleObject.lockup,
      presalePrice: saleObject.presalePrice,
      fairAmount: saleObject.fairAmount,
      endDate: saleObject.endDate,
      startDate: saleObject.startDate,
      hardCap: saleObject.hardCap,
      softCap: saleObject.softCap,
      unsoldToken: saleObject.unsoldToken,
      currency: saleObject.currency,
      dex: saleObject.dex,
      whiteisting: saleObject.whiteisting,
      whiteListedAddresses: saleObject.whiteListedAddresses,
      owner: account,
      isFinished: false,
    };

    if (saleObject.whiteisting) {
      try {
        const contract = new Contract(
          deployedAddress,
          PrivateAbi,
          library.getSigner()
        );

        const ts = await contract.setWLEnabled(true);
        await ts.wait();

        const tx = await contract.setMultiplyAddressesWL(
          saleObject.whiteListedAddresses?.map((address) => address),
          true
        );
        await tx.wait();
        alert("Whitelisting Done");
        return finalSaleObject;
        console.log('finalSaleObject:', finalSaleObject);
      } catch (error) {
        console.log(error);
        alert("Whitelisting Failed");
        closeLoadingModal();
      }
    } else return finalSaleObject;
  } catch (error) {
  console.error(error);
  console.log("Revert Reason:", error.reason || "N/A");
  console.log("Error Message:", error.message || "N/A");
  console.log("Error Data:", error.data || "N/A");
  closeLoadingModal();
  alert("Transaction Failed");
}
};


export const deployPrivateErcSale = async (
  token,
  saleObject,
  library,
  account,
  deploymentFee,
  saleData,
  chainId,
  closeLoadingModal
) => {

  const privateFactory = PrivateErc_FACTORYADRESS[chainId];

  const contract = new Contract(
    privateFactory,
    PrivateErcAbi,
    library.getSigner()
  );
  const adminAddress = ADMIN_ADDRESS[chainId];
  let PaymentToken = "";
  if (saleObject.currency.name === "Tether") {
    PaymentToken = USDT_ADDRESS[chainId];
  } else if (saleObject.currency.name === "USD Coin") {
    PaymentToken = USDC_ADDRESS[chainId];
  } else if (saleObject.currency.name === "Tower Token") {
    PaymentToken = TOWER_TOKEN[chainId];
  }

  // 2nd - with uints [minParticipation, maxParticipation, lp%, dex listing rate,lpLockPeriod, saleEnd, saleStart, hardCap(tokens), softCap(bnb)]
  console.log(PaymentToken, "PaymentToken");

  const saleId = await contract.getNumberOfSalesDeployed();
  console.log(saleObject)
  try {
    const tx = await contract.deployERC20PrivateSale(
      [adminAddress, token.tokenAddress, account, PaymentToken],
      [
        parseEther(Number(saleObject.minAllocation).toString()).toString(),
        parseEther(Number(saleObject.maxAllocation).toString()).toString(),
        parseUnits(
          saleObject.presalePrice.toString(),
          token.tokenDecimals
        ).toString(),
        saleObject.endDate,
        saleObject.startDate,
        parseEther(Number(saleObject.softCap).toString()).toString(),
        parseEther(Number(saleObject.hardCap).toString()).toString(),
      ],
      [saleObject.firstRelease, saleObject.percent1, saleObject.percent2, saleObject.percent3, saleObject.percent4],
      [saleObject.tgeRelaseDate, saleObject.releaseDate1, saleObject.releaseDate2, saleObject.releaseDate3, saleObject.releaseDate4 ],
      {
        value: utils.parseEther(deploymentFee),
        gasLimit: 8000000,
      }
    );
    await tx.wait();

    const deployedAddress = await contract.saleIdToAddress(saleId.toNumber());

    const finalSaleObject = {
      saleId: saleId.toNumber(),
      saleAddress: deployedAddress,
      saleType: saleData.type,
      github: saleData.github,
      website: saleData.website,
      twitter: saleData.twitter,
      whitepaper: saleData.whitepaper,
      whitelistEndDate: saleObject.whitelistEndDate,
      chainID: chainId,
      linkedin: saleData.linkedin,
      discord: saleData.discord,
      telegram: saleData.telegram,
      kyc: saleData.kyc,
      audit: saleData.audit,
      safu: saleData.safu,
      youtube: saleData.youtube,
      image: saleData.image,
      banner: saleData.banner,
      name: saleData.name,
      description: saleData.description,
      tags: saleData.tags,
      tags2: saleData.tags2,
      token: token,
      firstRelease: saleObject.firstRelease,
      percent1: saleObject.percent1,
      percent2: saleObject.percent2,
      percent3: saleObject.percent3,
      percent4: saleObject.percent4,
      tgeRelaseDate: saleObject.tgeRelaseDate,
      releaseDate1: saleObject.releaseDate1,
      releaseDate2: saleObject.releaseDate2,
      releaseDate3: saleObject.releaseDate3,
      releaseDate4: saleObject.releaseDate4,
      minAllocation: saleObject.minAllocation,
      maxAllocation: saleObject.maxAllocation,
      amountLiquidity: saleObject.amountLiquidity,
      listing: saleObject.listing,
      lockup: saleObject.lockup,
      presalePrice: saleObject.presalePrice,
      fairAmount: saleObject.fairAmount,
      endDate: saleObject.endDate,
      startDate: saleObject.startDate,
      hardCap: saleObject.hardCap,
      softCap: saleObject.softCap,
      unsoldToken: saleObject.unsoldToken,
      currency: saleObject.currency,
      dex: saleObject.dex,
      whiteisting: saleObject.whiteisting,
      whiteListedAddresses: saleObject.whiteListedAddresses,
      owner: account,
      isFinished: false,
    };

    if (saleObject.whiteisting) {
      try {
        const contract = new Contract(
          deployedAddress,
          PrivateErcAbi,
          library.getSigner()
        );

        const ts = await contract.setWLEnabled(true);
        await ts.wait();

        const tx = await contract.setMultiplyAddressesWL(
          saleObject.whiteListedAddresses?.map((address) => address),
          true
        );
        await tx.wait();
        alert("Whitelisting Done");
        return finalSaleObject;
        console.log('finalSaleObject:', finalSaleObject);
      } catch (error) {
        console.log(error);
        alert("Whitelisting Failed");
        closeLoadingModal();
      }
    } else return finalSaleObject;
  } catch (error) {
  console.error(error);
  console.log("Revert Reason:", error.reason || "N/A");
  console.log("Error Message:", error.message || "N/A");
  console.log("Error Data:", error.data || "N/A");
  closeLoadingModal();
  alert("Transaction Failed");
}
};
// Fairlaunch onwards
/* formating break to find functions easily*/

export const deployFairLaunchSale = async (
  token,
  saleObject,
  library,
  account,
  deploymentFee,
  saleData,
  chainId,
  closeLoadingModal
) => {

  const fairSaleFactory = FairLaunch_FACTORY[chainId];

  const contract = new Contract(
    fairSaleFactory,
    FairAbi,
    library.getSigner()
  );
  const adminAddress = ADMIN_ADDRESS[chainId];
  const routerAddress = saleObject.dex.address;
  const lockAddress = FACTORY_ADDRESS[chainId];
  console.log('routerAddress:', routerAddress)

  const saleId = await contract.getNumberOfSalesDeployed();
  console.log(saleObject)
  try {
    const tx = await contract.deployFairLaunchSale(
      [routerAddress, adminAddress, token.tokenAddress, account],
      parseUnits(saleObject.presalePrice.toString(),token.tokenDecimals).toString(),
      parseUnits(saleObject.fairAmount.toString(),token.tokenDecimals).toString(),
      parseEther(Number(saleObject.softCap).toString()).toString(),
      [
        parseEther(Number(saleObject.minAllocation).toString()).toString(),
        parseEther(Number(saleObject.maxAllocation).toString()).toString(),
      ],
      [
        saleObject.startDate,
        saleObject.endDate,
      ],
      (saleObject.amountLiquidity * 100).toString(),
      (saleObject.lockup * 86400).toString(),
      saleObject.unsoldToken === "Burn" ? true : false,
      {
        value: utils.parseEther(deploymentFee),
        gasLimit: 3000000,
      }
    );
    await tx.wait();

    const deployedAddress = await contract.saleIdToAddress(saleId.toNumber());

    const finalSaleObject = {
      saleId: saleId.toNumber(),
      saleAddress: deployedAddress,
      saleType: saleData.type,
      github: saleData.github,
      website: saleData.website,
      twitter: saleData.twitter,
      whitepaper: saleData.whitepaper,
      whitelistEndDate: saleObject.whitelistEndDate,
      chainID: chainId,
      linkedin: saleData.linkedin,
      discord: saleData.discord,
      telegram: saleData.telegram,
      kyc: saleData.kyc,
      audit: saleData.audit,
      safu: saleData.safu,
      youtube: saleData.youtube,
      image: saleData.image,
      banner: saleData.banner,
      name: saleData.name,
      description: saleData.description,
      tags: saleData.tags,
      tags2: saleData.tags2,
      token: token,
      firstRelease: saleObject.firstRelease,
      percent1: saleObject.percent1,
      percent2: saleObject.percent2,
      percent3: saleObject.percent3,
      percent4: saleObject.percent4,
      tgeRelaseDate: saleObject.tgeRelaseDate,
      releaseDate1: saleObject.releaseDate1,
      releaseDate2: saleObject.releaseDate2,
      releaseDate3: saleObject.releaseDate3,
      releaseDate4: saleObject.releaseDate4,
      minAllocation: saleObject.minAllocation,
      maxAllocation: saleObject.maxAllocation,
      amountLiquidity: saleObject.amountLiquidity,
      listing: saleObject.listing,
      lockup: saleObject.lockup,
      presalePrice: saleObject.presalePrice,
      fairAmount: saleObject.fairAmount,
      endDate: saleObject.endDate,
      startDate: saleObject.startDate,
      hardCap: saleObject.hardCap,
      softCap: saleObject.softCap,
      unsoldToken: saleObject.unsoldToken,
      currency: saleObject.currency,
      dex: saleObject.dex,
      whiteisting: saleObject.whiteisting,
      whiteListedAddresses: saleObject.whiteListedAddresses,
      owner: account,
      isFinished: false,
    };
    if (saleObject.whiteisting) {
      try {
        const contract = new Contract(
          deployedAddress,
          FairAbi,
          library.getSigner()
        );

        const ts = await contract.setWLEnabled(true);
        await ts.wait();

        const tx = await contract.setMultiplyAddressesWL(
          saleObject.whiteListedAddresses?.map((address) => address),
          true
        );
        await tx.wait();
      alert("Whitelisting Done");
    return finalSaleObject;
   } catch (error) {
     console.log(error);
     alert("Whitelisting Failed");
     closeLoadingModal();
    }
   } else return finalSaleObject;
  } catch (error) {
    alert("Transaction Failed");
    console.log(error)
    closeLoadingModal();
  }
};

export const deployFairLaunchSaleERC20 = async (
  token,
  chainId,
  saleObject,
  library,
  account,
  deploymentFee,
  saleData,
  closeLoadingModal
) => {

  const fairLaunchErc20 = FairLaunchErc_FACTORY[chainId];

  const contract = new Contract(
    fairLaunchErc20,
    FairErcAbi,
    library.getSigner()
  );
  const adminAddress = ADMIN_ADDRESS;
  const routerAddress = ROUTER_ADDRESS;
  let PaymentToken = "";
  if (saleObject.currency.name === "Tether") {
    PaymentToken = USDT_ADDRESS;
  } else if (saleObject.currency.name === "Gnosis") {
    PaymentToken = USDC_ADDRESS;
  } else if (saleObject.currency.name === "Tower Token") {
    PaymentToken = TOWER_TOKEN;
  }

  const saleId = await contract.getNumberOfSalesDeployed();

  try {
    const tx = await contract.deployFairLaunchSaleERC20(
      [routerAddress, adminAddress, token.tokenAddress, account, PaymentToken],
      [
        parseEther(Number(saleObject.minAllocation).toString()).toString(),
        parseEther(Number(saleObject.maxAllocation).toString()).toString(),
        (saleObject.amountLiquidity * 100).toString(),
        (saleObject.lockup * 86400).toString(),
        saleObject.startDate,
        saleObject.endDate,
        parseEther(Number(saleObject.hardCap).toString()).toString(),
        parseEther(Number(saleObject.softCap).toString()).toString(),
      ],
      { value: utils.parseEther(deploymentFee) }
    );
    await tx.wait();

    const deployedAddress = await contract.saleIdToAddress(saleId.toNumber());

    const finalSaleObject = {
      saleId: saleId.toNumber(),
      saleAddress: deployedAddress,
      saleType: saleData.type,
      github: saleData.github,
      website: saleData.website,
      twitter: saleData.twitter,
      whitepaper: saleData.whitepaper,
      whitelistEndDate: saleObject.whitelistEndDate,
      chainID: chainId,
      linkedin: saleData.linkedin,
      discord: saleData.discord,
      telegram: saleData.telegram,
      kyc: saleData.kyc,
      audit: saleData.audit,
      safu: saleData.safu,
      youtube: saleData.youtube,
      image: saleData.image,
      banner: saleData.banner,
      name: saleData.name,
      description: saleData.description,
      tags: saleData.tags,
      tags2: saleData.tags2,
      token: token,
      firstRelease: saleObject.firstRelease,
      percent1: saleObject.percent1,
      percent2: saleObject.percent2,
      percent3: saleObject.percent3,
      percent4: saleObject.percent4,
      tgeRelaseDate: saleObject.tgeRelaseDate,
      releaseDate1: saleObject.releaseDate1,
      releaseDate2: saleObject.releaseDate2,
      releaseDate3: saleObject.releaseDate3,
      releaseDate4: saleObject.releaseDate4,
      minAllocation: saleObject.minAllocation,
      maxAllocation: saleObject.maxAllocation,
      amountLiquidity: saleObject.amountLiquidity,
      listing: saleObject.listing,
      lockup: saleObject.lockup,
      presalePrice: saleObject.presalePrice,
      fairAmount: saleObject.fairAmount,
      endDate: saleObject.endDate,
      startDate: saleObject.startDate,
      hardCap: saleObject.hardCap,
      softCap: saleObject.softCap,
      unsoldToken: saleObject.unsoldToken,
      currency: saleObject.currency,
      dex: saleObject.dex,
      whiteisting: saleObject.whiteisting,
      whiteListedAddresses: saleObject.whiteListedAddresses,
      owner: account,
      isFinished: false,
    };

    return finalSaleObject;
  } catch (error) {
    alert("Transaction Failed");
    closeLoadingModal();
  }
};
