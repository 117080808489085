import React from "react";
import stepImage from "./images/icons/steps.png"
import step2Image from "./images/icons/steps2.png"
import partner1 from "./images/partner/1.png"
import partner2 from "./images/partner/2.png"
import partner3 from "./images/partner/3.png"
import partner4 from "./images/partner/4.png"
import partner5 from "./images/partner/5.png"
import partner6 from "./images/partner/6.png"
import partner7 from "./images/partner/7.png"
import partner8 from "./images/partner/8.png"
import partner9 from "./images/partner/9.png"
import partner10 from "./images/partner/10.png"
import "./style.css";
import "./animate.css";
import "./responsive.css";

export default function Partner() {
  return (
    <div className="gamfi_Our_mentor_section ">
            <div className="container">
                <div className="sec-heading text-center mb-75">
                    <h2 className="title text-black">Our PARTNERS</h2>
                </div>
                <div className="Our_PARTNERSContnetSect">
                    <ul className="OurPartnatList">
                        <li><img src={partner1} alt="logo" className="img-fluid2" /></li>
                        <li><img src={partner2} alt="logo" className="img-fluid2" /></li>
                        <li><img src={partner3} alt="logo" className="img-fluid2" /></li>
                        <li><img src={partner4} alt="logo" className="img-fluid2" /></li>
                        <li><img src={partner5} alt="logo" className="img-fluid2" /></li>
                    </ul>
                </div>
            </div>
        </div>
  );
}
