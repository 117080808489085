import StakingAbi from "config/abi/Staking.json";
import Web3 from "web3";

async function getSaleInfo(stakingAddress, stakingType) {
  console.log(stakingAddress, stakingType);

  try {
    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();

    let contract;
    if (stakingType === 'public') {
      contract = new web3.eth.Contract(StakingAbi, stakingAddress);
    }

    const stakeInfo1 = await contract.methods.getTotalStakingToken().call();
    console.log("rewardBalance:",stakeInfo1);

    const stakeInfo = await contract.methods.getTotalRewardToken().call();
    console.log("rewardBalance:",stakeInfo);

    //Use "totalBNBRaised" as the filled percentage for private sales
    const filledPercent = stakeInfo;
    console.log("Filled Percent:", filledPercent);

    return stakeInfo;
  } catch (error) {
    console.error("An error occurred while fetching the sale information:", error);
  }
}

export default getSaleInfo;
