import React from 'react'
import { useRoutes } from 'react-router-dom'
import Error from '../pages/Error'
import Airdrops from '../pages/AirDropper/Airdrops'
import AirdropsPage from "../pages/AirDropper/AirdropsPage"
import CreateAirdrop from '../pages/AirDropper/CreateAirdrop'
import Home from 'pages/Home/Home'
import WhitePaper from 'pages/WhitePaper/whitepaper'
import Pools from 'pages/Launchpad/Pools'
import PoolPage from 'pages/Launchpad/PoolPage'
import CreateSale from 'pages/Launchpad/CreateSale'
import LockedAsset from '../pages/Locker/LockedAsset'
import Locker from '../pages/Locker/Locker'
import ClaimTWS from '../pages/drops/claim'
import LpLocker from '../pages/Locker/LpLocker'
import TokenLocker from '../pages/Locker/TokenLocker'
import CreateSaleTest from 'pages/Tests/LaunchpadTests/CreateSaleTest'
import CreateStaking from "../pages/Staking/CreateStaking"
import Stakes from 'pages/Staking/Stakes'
import StakePage from 'pages/Staking/StakePage'
import Exchange from 'pages/Exchange/exchange'
import Portfolio from 'pages/Portfolio/Portfolio'



export default function WebRouter() {
  let routes = useRoutes([
    { path: '/', element: <Home /> }, // Redirect to /airdropper/airdrops
    { path: '/locked-assets', element: <Locker /> },
    { path: '/locked-assets/token/:id', element: <LockedAsset type={'token'} /> },
    { path: '/locked-assets/lp-token/:id', element: <LockedAsset type={'lp-token'} /> },
    { path: '/locker/token-locker', element: <TokenLocker /> },
    { path: '/locker/lp-locker', element: <LpLocker /> },
    { path: '/exchange', element: <Exchange /> },
    { path: '/dashboard', element: <Portfolio /> },
    { path: '/pools', element: <Pools /> },
    { path: '/pools/:id', element: <PoolPage /> },
    { path: '/create-sale', element: <CreateSale /> },
    { path: '/create-staking', element: <CreateStaking /> },
    { path: '/stakes', element: <Stakes /> },
    { path: '/stakes/:id', element: <StakePage /> },
    { path: '/airdropper/airdrops', element: <Airdrops />},
    { path: '/airdropper/airdrops/:id', element: <AirdropsPage />},
    { path: '/airdropper/create-airdrop', element: <CreateAirdrop />},
    { path: '/claim-tws', element: <ClaimTWS /> },
    { path: '/Docs', element: <WhitePaper /> },
    { path: '/tests/create-sales', element: <CreateSaleTest />},
    { path: '*', element: <Error /> },
  ])
  return routes
}
