import { useEthers } from "@usedapp/core";
import PreviewDetails from "components/Common/PreviewDetails";
import Timer from "components/LockedAsset/Amount/Timer/Timer";
import { Contract } from "ethers";
import React, { useEffect, useState } from "react";
import PublicStakingAbi from "../../../config/abi/Staking.json";
import useParticipated from "utils/getStakingParticipated";
import { formatBigToNum } from "utils/numberFormat";
import { useModal } from "react-simple-modal-provider";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Web3 from "web3";
import getSaleInfo from "utils/getSaleInfo";

export default function SaleBox({ icon, stake, status, stake_address, isFinished, isCancelled }) {
  const { account, library } = useEthers();
  const [allocated, setAllocated] = useState(0);
  const [bought, setBought] = useState(0);
  const [stakedBalance, setStakedBalance] = useState(0);
  const [depositBalance, setDepositToken] = useState(null);
  const [userReward, setUserReward] = useState(0);
  const [saleInfo, setSaleInfo] = useState(null);
  const participated = useParticipated(stake_address, account);
  const { open: openLoadingModal, close: closeLoadingModal } = useModal("LoadingModal");

  const getUserParticipation = async () => {
      try {
          if (!stake) {
              return; // Return early if 'sale' is not available
          }

          let contract;
          if (stake.stakingType === "public") {
              contract = new Contract(stake.stakingAddress, PublicStakingAbi, library.getSigner());
          } else {
              console.error("Unsupported staking type:", stake.stakingType);
              return;
          }

          // Fetch user reward
          const decimal = stake.token1.tokenDecimals;
          const stakedBalance = await contract.getStakedBalance(account);
          setStakedBalance(formatBigToNum(stakedBalance.toString(), decimal, 4));
          console.log("stakedBalance:",stakedBalance);
          const userReward = await contract.earned(account);
          console.log("userReward:",userReward);
          setUserReward(formatBigToNum(userReward.toString(), 18, 4));

          // Fetch user participation
          const userParticipation = await contract.userToParticipation(account);
          setBought(formatBigToNum(userParticipation.toString(), decimal, 4));
      } catch (error) {
          console.error("Error fetching user participation:", error);
      }
  };

  useEffect(() => {
      if (account && library) {
          getUserParticipation();
      }
  }, [account, library, stake]);

  const withdrawTokens = async () => {
    openLoadingModal();

    if (participated[0] === false) {
      toast.error("You have not participated in this sale");
      closeLoadingModal();
      return;
    }

    let contract;
    const amounToWithdraw = userReward * stake.token2.tokenDecimals;
    try {
      if (stake.stakingType === "public") {
        contract = new Contract(
          stake.stakingAddress,
          PublicStakingAbi,
          library.getSigner()
        );
    }
      if (contract) {
        const tx = await contract.getReward();
        await tx.wait();
      }

      toast.success("Tokens Withdrawn");
      window.location.reload();
    } catch (err) {
      console.log(err);
      closeLoadingModal();
    }

    closeLoadingModal();
  };

  return (
    <>
      <div className="px-9 pb-6 bg-white dark:bg-dark-1 rounded-[20px]">
        <div className="w-full flex justify-center">
          <div className="w-1/2 py-3 flex justify-center items-center border-b-2 border-primary-green ">
            <span className="font-bold text-primary-green">User Panel</span>
          </div>
        </div>

        <PreviewDetails
          name={"My Deposit"}
          value={stakedBalance + " " + stake.token1.tokenSymbol}
        />
        <PreviewDetails
          name={"My Rewards"}
          value={userReward + " " + stake.token2.tokenSymbol}
        />
        {participated && participated[0] && (
          <div className="flex flex-col items-center">
            <div className="mt-3 flex">
              <img src={stake.image2} alt="pool-icon" className="w-6 h-6 mr-2" />
              <span className="font-bold text-dark-text dark:text-light-text text-xl">
                {userReward} {stake.token2.tokenSymbol}
              </span>
            </div>
          </div>
        )}
        {participated && participated[0] && (
          <div className="mt-7">
            <button
              onClick={withdrawTokens}
              className="w-full bg-primary-green rounded-md text-white font-bold py-2 disabled:bg-dim-text "
            >
              HARVEST
            </button>
          </div>
          )}
      </div>
    </>
  );
}
