import React from "react";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import Features from "./Components/features";
import Data from "./Components/data";
import Header from "./Components/header";
import How from "./Components/Participate";
import Tokenomics from "./Components/Tokenomic";
import Team from "./Components/team";
import SupportChain from "./Components/Support";
import Partners from "./Components/Partner";
import Roadmap from "./Components/Roadmap";
import Footer from "./Components/Footer";

export default function Home({ totalSales }) {
  return (
    <BaseLayout>
      <Header />
      <How />
      <SupportChain />
      <Tokenomics />
      <Partners />
      <Roadmap />
    </BaseLayout>
  );
}
