import React from "react";
import Web3 from 'web3';
import PreviewHeader from "../../Common/PreviewHeader";
import BackArrowSVG from "../../../svgs/back_arrow";
import PreviewDetails from "../../Common/PreviewDetails";
import { formatBigToNum } from "../../../utils/numberFormat";
import { useState } from "react";
import { parseEther } from 'ethers/lib/utils';
import getDeploymentFeePublic from "utils/getDeploymentFeePublic";
import { ethers } from "ethers";
import { useEthers } from "@usedapp/core";
import { useEffect } from "react";
import { useModal } from "react-simple-modal-provider";
import {
  approveTokens,
  deployPrivateSale,
  deployPublicSaleMainnet,
  deployPublicSaleVesting,
  deployFairLaunchSale,
  deployPublicSaleERCMainnet,
  deployFairLaunchSaleERC20,
  deployPrivateErcSale,
} from "utils/deploySale";
import axios from "axios";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";
import getCalcMax from "utils/calcMax";
import { useDefaultChainId } from "config/useDefaultChainId";

export default function PreviewSale({
  token,
  setActive,
  saleObject,
  saleType,
  saleData,
}) {
  const [deploymentFee, setDeploymentFee] = useState(0.0);
  const chainId = useDefaultChainId();
  console.log("chainId",chainId)
  console.log("saleData",saleData)
  console.log("saleType",saleType)
  console.log("saleObject",saleObject)
  const { account, library } = useEthers();
  const [max, setMax] = useState(null);
  const [deployFee, setDeployFee] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [lockup, setLockup] = useState(null);
  const [releaseDate1, setReleaseDate1] = useState(null);
  const [releaseDate2, setReleaseDate2] = useState(null);
  const [releaseDate3, setReleaseDate3] = useState(null);
  const [releaseDate4, setReleaseDate4] = useState(null);
  const [amt, setAmt] = useState(0);
  //calc max takes, hardcap, tokenPrice, listingPrice, tokenDecimals
  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");

  async function calcMax() {
    const result = await getCalcMax(saleObject, token,chainId,saleType)
    setMax(result)
  }
  async function getFee() {
    const res = await getDeploymentFeePublic(saleType)
    const deployFee = parseEther('1');
    setDeployFee(res)
    setDeploymentFee(ethers.utils.formatEther(res))
  }


  useEffect(() => {
    calcMax();
  }, [saleObject]);

  useEffect(() => {
    if(!max) return;
    try {
      setAmt(parseFloat(saleObject.hardCap) + parseFloat(formatBigToNum(max)))
    } catch (err) {
      console.log(err);
    }
  }, [max]);
  useEffect(() => {}, [startTime]);
  useEffect(() => {
    setStartTime(new Date(saleObject.startDate * 1000));
    setEndTime(new Date(saleObject.endDate * 1000));
    setLockup(new Date(saleObject.lockup * 1000));
    setReleaseDate1(new Date(saleObject.releaseDate1 * 1000));
    setReleaseDate2(new Date(saleObject.releaseDate2 * 1000));
    setReleaseDate3(new Date(saleObject.releaseDate3 * 1000));
    setReleaseDate4(new Date(saleObject.releaseDate4 * 1000));

    getFee();
  }, [deployFee]);

  console.log(deploymentFee, "deploymentFee")
  const handleSubmit = async () => {
    openLoadingModal();

    const web3 = new Web3(window.ethereum);
    const chainId = await web3.eth.getChainId();

    if (saleType === "public") {
      let finalSaleObject;
      const coinName = saleObject.currency.name;
      if (saleObject.currency.name === coinName) {
        if(chainId === chainId) {
        finalSaleObject = await deployPublicSaleMainnet(
          token,
          saleObject,
          library,
          account,
          deploymentFee,
          saleData,
          chainId,
          closeLoadingModal
        );
       }
      } else {
        if(chainId === chainId) {
          console.log("mainnet",chainId)
        finalSaleObject = await deployPublicSaleERCMainnet(
          token,
          saleObject,
          library,
          account,
          deploymentFee,
          saleData,
          chainId,
          closeLoadingModal
        );
       }
      }
      await axios.post(
        `${BACKEND_URL}/api/sale`,
        {
          sale: finalSaleObject,
        },
        {
          withCredentials: true,
        }
      );
    } else if (saleType === "publicvesting" ) {
      let finalSaleObject;
      const coinName = saleObject.currency.name;
      if (saleObject.currency.name === coinName) {
        console.log( "publicvesting" , coinName)
        if(chainId === chainId) {
        finalSaleObject = await deployPublicSaleVesting(
          token,
          saleObject,
          library,
          account,
          deploymentFee,
          saleData,
          chainId,
          closeLoadingModal
         );
       }
    }
      await axios.post(
        `${BACKEND_URL}/api/sale`,
        {
          sale: finalSaleObject,
        },
        {
          withCredentials: true,
        }
      );
    } else if (saleType === "private") {
      let finalSaleObject;
      const coinName = saleObject.currency.name;
      if (saleObject.currency.name === coinName) {
        if(chainId === chainId) {
          console.log(chainId, "chainId")
        finalSaleObject = await deployPrivateSale(
          token,
          saleObject,
          library,
          account,
          deploymentFee,
          saleData,
          chainId,
          closeLoadingModal
        );
       }
      } else {
        if(chainId === chainId) {
          console.log("mainnet",chainId)
        finalSaleObject = await deployPrivateErcSale(
          token,
          saleObject,
          library,
          account,
          deploymentFee,
          saleData,
          chainId,
          closeLoadingModal
        );
      }
      }

      await axios.post(
        `${BACKEND_URL}/api/sale`,
        {
          sale: finalSaleObject,
        },
        {
          withCredentials: true,
        }
      );
    } else if (saleType === "fairlaunch") {
      let finalSaleObject;
      const coinName = saleObject.currency.name;
      if (saleObject.currency.name === coinName) {
        if(chainId === chainId) {
          console.log(chainId, "chainId")
        finalSaleObject = await deployFairLaunchSale(
          token,
          saleObject,
          library,
          account,
          deploymentFee,
          saleData,
          chainId,
          closeLoadingModal
        );
        }
      } else {
        finalSaleObject = await deployFairLaunchSaleERC20(
          token,
          saleObject,
          library,
          account,
          deploymentFee,
          saleData,
          chainId,
          closeLoadingModal
        );
      }
      await axios.post(
        `${BACKEND_URL}/api/sale`,
        {
          sale: finalSaleObject,
        },
        {
          withCredentials: true,
        }
      );
    }
    closeLoadingModal();
    //redirect to home page
    window.location.href = "/pools";
  };

  const fairAmount = parseInt(saleObject.presalePrice);
  const fairLiqAmount = parseInt(saleObject.presalePrice) / 100 * parseFloat(saleObject.amountLiquidity);
  const soldAmount = fairAmount + fairLiqAmount;


  console.log('soldAmount:', soldAmount);
  console.log('fairAmount:', saleObject.presalePrice);
  console.log('fairLiqAmount:', fairLiqAmount);


  // Cek tipe data fairLiqAmount
  console.log('Type of fairLiqAmount:', typeof fairLiqAmount);


  return (
    <div className="">
      <div className="flex items-center">
        <img src={token.image} alt={token.name} className="w-[54px] h-[54px]" />

        <div className=" ml-4">
          <div className="flex items-center">
            <h3 className=" font-bold dark:text-light-text">{token.name}</h3>
          </div>
        </div>
      </div>

      <PreviewHeader heading={"Token address Details"} />

      <PreviewDetails name="Name" value={saleData.tokenName} />
      <PreviewDetails name="Symbol" value={saleData.tokenSymbol} />
      <PreviewDetails name="Decimals" value={saleData.tokenDecimals} />
      <PreviewDetails
        name="Total Supply"
        value={`${formatBigToNum(
          saleData.tokenSupply,
          saleData.tokenDecimals
        )} ${saleData.tokenSymbol}`}
      />

      <PreviewHeader heading={"Presale Details"} />

      {saleType === "fairlaunch" && (
        <PreviewDetails
        name={"Amount to be sold"}
        value={soldAmount + " " + token.tokenSymbol }
        />
      )}
      {saleType === "fairlaunch" && (
        <PreviewDetails
        name={"Amount for liquidity %"}
        value={saleObject.amountLiquidity + "%"}
        />
      )}

      {saleType === "public" && (
        <PreviewDetails
        name={"Presale Rate"}
        value={
          saleObject.presalePrice +
          " " +
          token.tokenSymbol +
          " = 1 " +
          saleObject.currency.symbol
        }
        />
      )}
      <div>
        <PreviewDetails name={"Soft Cap"} value={saleObject.softCap + " " + saleObject.currency.symbol} />
        {(saleType === "private" || saleType === "public" || saleType === "publicvesting" ) && (
        <PreviewDetails name={"Hard Cap"} value={saleObject.hardCap + " " + saleObject.currency.symbol} />
        )}
          <PreviewDetails
            name={"Minimum Allocation"}
            value={saleObject.minAllocation + " " + saleObject.currency.symbol}
          />
          <PreviewDetails
            name={"Maximum Allocation"}
            value={saleObject.maxAllocation + " " + saleObject.currency.symbol}
          />
          {saleType === "public" && (
          <PreviewDetails
            name={"Amount to be sold"}
            value={saleObject.presalePrice * saleObject.hardCap + " " + token.tokenSymbol}
          />
          )}
        </div>
      {saleType === "fairlaunch" && (
        <PreviewDetails name={"Sale Type"} value={"Fairlaunch"} />
      )}
      {saleType === "public" && (
        <PreviewDetails
          name={"Sale Type"}
          value={
            saleObject.whiteisting ? "Whitelist Enabled" : "Whitelist Disabled"
          }
        />
      )}
      {saleType === "private" && (
        <PreviewDetails
          name={"Sale Type"}
          value={
            saleObject.whiteisting
              ? "Private Sale, Whitelist Enabled"
              : "Private Sale, Whitelist Disabled"
          }
        />
      )}

      {saleType !== "private" && (
        <div>
          <PreviewHeader heading={"Listing Details"} />

          <PreviewDetails
            name={"To be listed on"}
            value={saleObject.dex.name}
            icon={saleObject.dex.icon}
          />
          <PreviewDetails
            name={"Amount to be used for liquidity"}
            value={saleObject.amountLiquidity + "%"}
          />
        </div>
      )}
      {saleType === "public" && (
        <PreviewDetails
          name={"Listing rate"}
          value={
            saleObject.listing +
            " " +
            token.tokenSymbol +
            " = 1 " +
            saleObject.currency.symbol
          }
        />
      )}

      <PreviewHeader heading={"Time Details"} />
      {startTime && (
        <PreviewDetails
          name={"Presale Start Date"}
          value={startTime.toUTCString()}
        />
      )}

      {endTime && (
        <PreviewDetails
          name={"Presale End Date"}
          value={endTime.toUTCString()}
        />
      )}
      {saleType !== "private" && (
        <div>
          <PreviewHeader heading={"More Details"} />

          <PreviewDetails
            name={"Unsold Tokens"}
            value={saleObject.unsoldToken}
          />
          {lockup && (
          <PreviewDetails
            name={"Liquidity Lockup Time"}
            value={lockup.toUTCString()}
          />
          )}
        </div>
      )}

      {(saleType === "private" || saleType === "publicvesting" ) && (
        <div>
          <PreviewHeader heading={"Token Vesting Details"} />
          {endTime && (
          <PreviewDetails
            name={"First % Release & Release Time"}
            value={`${saleObject.firstRelease}% , ${endTime.toUTCString()}`}
          />
          )}
          {releaseDate1 && (
          <PreviewDetails
            name={"Portion 1 & Release Time"}
            value={`${saleObject.percent1}% , ${releaseDate1.toUTCString()}`}
          />
          )}
          {releaseDate2 && (
          <PreviewDetails
            name={"Portion 2 & Release Time"}
            value={`${saleObject.percent2}% , ${releaseDate2.toUTCString()}`}
          />
          )}
          {releaseDate3 && (
          <PreviewDetails
            name={"Portion 3 & Release Time"}
            value={`${saleObject.percent3}% , ${releaseDate3.toUTCString()}`}
          />
          )}
          {releaseDate4 && (
          <PreviewDetails
            name={"Portion 4 & Release Time"}
            value={`${saleObject.percent4}% , ${releaseDate4.toUTCString()}`}
          />
          )}
        </div>
      )}
      <div className="mt-10">
        <div className="flex justify-end items-center mb-10">
          <button
            className="bg-white dark:bg-transparent mr-5 flex items-center gap-2 py-[10px] px-5"
            onClick={() => setActive("Project Details")}
          >
            <BackArrowSVG className="fill-dark-text dark:fill-light-text" />
            <span className="font-gilroy font-medium text-sm text-dark-text dark:text-light-text">
              Go Back
            </span>
          </button>

          <button
            className="bg-primary-green hover:opacity-40 disabled:bg-light-text text-white font-gilroy font-bold px-8 py-3 rounded-md"
            onClick={handleSubmit}
          >
            Create Sale
          </button>
        </div>
      </div>
    </div>
  );
}
