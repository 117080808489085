import Options from 'components/LockedAsset/Preview/Subcomponents/Options'
import { ThemeContext } from 'context/ThemeContext/ThemeProvider'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import DribbleSVG from 'svgs/Socials/dribble'
import GithubSVG from 'svgs/Socials/github'
import TelegramSVG from 'svgs/Socials/telegram'
import WhitePaperSVG from 'svgs/Socials/whitepaper'
import TwitterSVG from 'svgs/Socials/twitter'
import DiscordSVG from 'svgs/Socials/discord'
import { useDefaultChainId } from "config/useDefaultChainId";

export default function Info({ icon, name, is_private, currency, tags, pool, setEdit,edit,admin }) {
  const { theme } = useContext(ThemeContext);
  const chainId = useDefaultChainId();

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <img src={icon} alt={name} className="w-[75px] h-[75px] top-[-40px] rounded-[50px] border border-solid border-2px border-black relative z-0" />
        {pool.chainID === 1116 && pool.currency.icon && (
          <img className="absolute top-[200px] left-20 w-6 h-6 m-1 z-10" src={pool.currency.icon} alt="Currency Icon" />
        )}
        {pool.chainID === 2040 && pool.currency.icon && (
         <img className="absolute top-[200px] left-20 w-6 h-6 m-1 z-10" src={pool.currency.icon} alt="Currency Icon" />
        )}

        <div className=" ml-4">
          <div className="flex items-center">
            <h3 className=" text-lg font-bold text-black">{name}</h3>
          </div>

          <div className="flex items-center mt-2">
          {pool.audit !== "" && (
            <Link to={pool.audit} target="_blank" rel="noopener noreferrer">
              <span className="py-1 px-4 text-[12px] font-bold bg-[#79cdff] dark:bg-[#79cdff] py-[3px] px-2 text-black rounded-[4px] pulsate mr-2">AUDIT</span>
            </Link>
          )}
          {pool.kyc !== "" && (
            <Link to={pool.kyc} target="_blank" rel="noopener noreferrer">
              <span className="py-1 px-4 text-[12px] font-bold bg-[#79ff82] dark:bg-[#79ff82] py-[3px] px-2 text-black rounded-[4px] pulsate mr-2">KYC</span>
            </Link>
          )}
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2">
          {pool.github !== "" &&
          <Link to={pool.github} target="_blank" rel="noopener noreferrer" >
          <GithubSVG
            className="w-5 h-5 hidden md:block  "
            outer={`${theme === "dark" ? "#fff" : "#464754"}`}
            inner={`${theme === "dark" ? "#464754" : "#fff"}`}
          />
          </Link>
          }
        {pool.twitter !== "" &&
        <Link to={pool.twitter} target="_blank" rel="noopener noreferrer" >
          <TwitterSVG className="fill-dark-text dark:fill-light-text hidden md:block" />
          </Link>
        }
        {pool.website !== "" &&
        <Link to={pool.website} target="_blank" rel="noopener noreferrer" >
        <DribbleSVG className="fill-dark-text dark:fill-light-text hidden md:block" />
        </Link>
        }
        {pool.whitepaper !== "" &&
        <Link to={pool.whitepaper} target="_blank" rel="noopener noreferrer" >
        <WhitePaperSVG className="fill-dark-text dark:fill-light-text hidden md:block" />
        </Link>
        }
        {pool.telegram !== "" &&
        <Link to={pool.telegram} target="_blank" rel="noopener noreferrer" >
          <TelegramSVG className="fill-dark-text dark:fill-light-text hidden md:block" />
          </Link>
        }
        {pool.discord !== "" &&
        <Link to={pool.discord} target="_blank" rel="noopener noreferrer" >
          <DiscordSVG className="fill-dark-text dark:fill-light-text hidden md:block" />
          </Link>
        }
        {admin &&
        <Options width={'w-7'} height={'h-7'} color={'[#FAF8F5]'} dark_color={'dark-2'} edit ={edit} setEdit={setEdit} />
        }
      </div>
    </div>
  )
}
