import React, { useState } from "react";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import SlideContent from "./SlideContent";

export default function Carousel({ mobileView }) {
  // Data banner statis
  const staticBanners = [
    { id: 1, url: "https://i.ibb.co.com/sKKSNVS/new-Banner1.png", name: "Banner1", link:"https://t.me/bullpadmining_bot" },
    { id: 2, url: "https://i.ibb.co.com/sHKpdb1/Integration.png", name: "Banner2", link:""  },
    { id: 3, url: "/images/slider/banner3.png", name: "Banner3", link:""  },
  ];

  // State untuk menyimpan banners
  const [banners, setBanners] = useState(staticBanners);

  if (mobileView) {
      return (
        <CarouselProvider
          naturalSlideWidth={10}
          naturalSlideHeight={5}
          totalSlides={banners.length}
          isPlaying={true}
          interval={3000}
          visibleSlides={1}
        >
          <Slider>
            {banners.map((banner, index) => (
              <Slide key={index} index={index}>
                <SlideContent img={banner.url} name={banner.name} link={banner.link} />
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>
      );
    }

  // For non-mobile view, assuming each object represents a group of banners
  return (
    <CarouselProvider
      naturalSlideWidth={70}
      naturalSlideHeight={15}
      totalSlides={1} // Adjust based on your logic for non-mobile view
      isPlaying={true}
      interval={3000}
    >
      <Slider>
        <Slide index={0}>
          <div className="banner-group gap-5 flex">
            {banners.map((banner, bannerIndex) => (
              <SlideContent key={bannerIndex} img={banner.url} name={banner.name} link={banner.link} />
            ))}
          </div>
        </Slide>
      </Slider>
    </CarouselProvider>
  );
}
