import Timer from "components/LockedAsset/Amount/Timer/Timer";
import PreviewDetails from "components/Common/PreviewDetails";
import React, { useEffect, useState } from "react";
import getSaleInfo from "utils/getStakingInfo";
import { Contract } from "ethers";
import { useEthers } from "@usedapp/core";
import { formatBigToNum } from "utils/numberFormat";
import StakingAbi from "config/abi/Staking.json";
import useParticipated from "utils/getStakingParticipated";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PercentFilled from "../Pools/Subcomponents/PercentFilled";

export default function SaleBox({
  ends_on,
  showModal,
  status,
  token,
  stake_address,
  currency,
  start_date,
  end_date,
  stake,
  visible,
  isFinished,
  isCancelled
}) {
  const [filled_percent, setFilledPercent] = useState(0);
  const { account, library } = useEthers();
  const [stakeInfo, setStakeInfo] = useState(null);
  const [bought, setBought] = useState(0);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [stakedBalance, setStakedBalance] = useState(0);
  const [contributors, setContributors] = useState(null);
  const participated = useParticipated(stake_address, account);
  useEffect(() => {
    const result = getSaleInfo(stake_address, stake.stakingType).then((res) => {
      setStakeInfo(res);
      // console.log(res, "saleingooo");
    });
  }, []);

  const getUserParticipation = async () => {
      try {
          if (!stake) {
              return; // Return early if 'sale' is not available
          }

          let contract;
          if (stake.stakingType === "public") {
              contract = new Contract(stake.stakingAddress, StakingAbi, library.getSigner());
          } else {
              console.error("Unsupported staking type:", stake.stakingType);
              return;
          }
          const decimal = stake.token1.tokenDecimals;
          const userParticipation = await contract.userToParticipation(account);
          console.log("fetching user participation:",userParticipation);
          setBought(formatBigToNum(userParticipation.toString(), decimal, 4));
      } catch (error) {
          console.error("Error fetching user participation:", error);
      }
  };

  const getContributors = async () => {
    try {
      let abi;
      if (stake.stakingType === "public") {
        abi = StakingAbi;
      }
      const contract = new Contract(
        stake.stakingAddress,
        abi,
        library.getSigner()
      );
      const contributors = await contract.numberOfParticipants();
      setContributors(contributors.toNumber());
      const stakedBalance = await contract.getStakedBalance(account);
      setStakedBalance(stakedBalance);
      console.log("stakedBalance:",stakedBalance);
      const totalDeposit = await contract.getTotalStakingToken();
      console.log("totalDeposit:",totalDeposit);
      setTotalDeposit(formatBigToNum(totalDeposit.toString(), 18, 4))
    } catch (err) {
      console.log(err,"ahahahah");
    }
  };

  const withdrawFunds = async () => {
    if (participated[0] === false) {
     toast.error("You have not participated in this sale");
    return;
    }

    let contract;

      if (stake.stakingType === "public") {
        contract = new Contract(
          stake.stakingAddress,
          StakingAbi,
          library.getSigner()
        );
      }

    try {
      const tx = await contract.withdraw(stakedBalance);
      await tx.wait();
      toast.success("Funds withdrawn successfully");
    } catch (err) {
      toast.error("Error withdrawing funds");
    }
  };



  useEffect(() => {
    getContributors();
  }, []);

  const endDuration = stake.duration * 86400;
  const endTime = stake.startDate + endDuration;


  return (
    <>
      <div className="p-9 bg-white dark:bg-dark-1 rounded-[20px]">
        <div className="w-full justify-center">

          <div className="bg-primary-green text-center bg-opacity-[0.08] px-3 py-[3px] rounded-[10px] border-[0.5px] border-dashed border-primary-green">
            <span className="rounded-[10px] text-primary-green">{status}</span>
          </div>
        </div>
          <div className="flex items-center justify-between mt-3">
            <span className="text-xs  text-gray dark:text-gray-dark">
              Total Deposit:
            </span>

            <span className="text-xs  text-dim-text dark:text-dim-text-dark">
              {totalDeposit} {stake.token1.tokenSymbol}
            </span>
          </div>
          <div className="flex items-center justify-between mt-3">
            <span className="text-xs  text-gray dark:text-gray-dark">
              Remaining Rewards:
            </span>

            <span className="text-xs  text-dim-text dark:text-dim-text-dark">
              {((stakeInfo / 10**stake.token2.tokenDecimals) - (stake.rewardAmount / 10**stake.token2.tokenDecimals)).toLocaleString()} {stake.token2.tokenSymbol}
            </span>
          </div>

        {contributors != null && (
          <div>
            <PreviewDetails name={"Stakers"} value={contributors + " " + "Users"} />
          </div>
        )}

        {status === "Upcoming" ? (
          <div>
            <div className="flex justify-center mt-7">
              <span className="text-sm font-medium text-gray dark:text-gray-dark">
                Staking Starts in
              </span>
            </div>
            <Timer date={new Date(start_date * 1000)} />
          </div>
        ) : (
          <div className="flex mt-10">
            <button
              disabled={
                status === "Ended" || isFinished || isCancelled }
              className={`w-full ${
                status !== "Ended" && !isFinished
                  ? "bg-primary-green"
                  : "bg-dim-text bg-opacity-50 dark:bg-dim-text-dark"
              } rounded-md text-white font-bold py-2 disabled:bg-dim-text disabled:opacity-50 disabled:dark:bg-dim-text-dark mr-2`}
              onClick={() => showModal(true)}
            >
              {status === "Ended"}
              STAKE
            </button>
            <button
              className={`w-full bg-primary-green rounded-md text-white font-bold py-2 disabled:bg-dim-text disabled:opacity-50 disabled:dark:bg-dim-text-dark`}
              onClick={(withdrawFunds)}
            >
              {status === "Ended"}
              UNSTAKE
            </button>
          </div>
        )}

        {status !== "Upcoming" && status !== "Ended" && visible !== false && !isFinished &&(
          <>
            <div className="flex justify-center mt-4">
              <span className="text-sm font-medium text-gray dark:text-gray-dark ">
                {isCancelled? "You can with draw in":"Staking Ends in"}
              </span>
            </div>
          </>
        )}

        {/* if sale ended then just write Sale has ended */}
        {/* if sale is live then show timer */}
        {status !== "Ended" && status !== "Upcoming" && visible !== false && !isFinished &&(
          <Timer date={new Date(end_date * 1000)} />
        )}
      </div>
    </>
  );
}
