import React from "react";
import Web3 from 'web3';
import PreviewHeader from "../../Common/PreviewHeader";
import BackArrowSVG from "../../../svgs/back_arrow";
import PreviewDetails from "../../Common/PreviewDetails";
import { formatBigToNum } from "../../../utils/numberFormat";
import { useState } from "react";
import { parseEther } from 'ethers/lib/utils';
import getDeploymentFeePublic from "utils/getDeploymentFeePublic";
import { ethers } from "ethers";
import { useEthers } from "@usedapp/core";
import { useEffect } from "react";
import { useModal } from "react-simple-modal-provider";
import { deployStaking } from "utils/deployStake";
import axios from "axios";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";
import { useDefaultChainId } from "config/useDefaultChainId";

export default function PreviewSale({
  token,
  setActive,
  stakingObject,
  stakingType,
  stakingTokenData,
  rewardTokenData,
  stakingData,
}) {
  const [deploymentFee, setDeploymentFee] = useState(0.0);
  const chainId = useDefaultChainId();
  const { account, library } = useEthers();
  const [max, setMax] = useState(null);
  const [deployFee, setDeployFee] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [amt, setAmt] = useState(0);
  //calc max takes, hardcap, tokenPrice, listingPrice, tokenDecimals
  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");

    async function getFee() {
      const res = await getDeploymentFeePublic(stakingType)
      const deployFee = parseEther('1');
      setDeployFee(res)
      setDeploymentFee(ethers.utils.formatEther(res))
    }


  useEffect(() => {}, [startTime]);
  useEffect(() => {
    setStartTime(new Date(stakingObject.startDate * 1000));
    setEndTime(new Date(stakingObject.endDate * 1000));
    getFee();
  }, [deployFee]);

  console.log(deploymentFee, "deploymentFee")

  // UI
  const handleSubmit = async () => {
    openLoadingModal();
      try {
        const web3 = new Web3(window.ethereum);
        const chainId = await web3.eth.getChainId();

        if (stakingType === "public") {
          let finalStakingObject;
          const coinName = stakingObject.currency.name;
          if (stakingObject.currency.name === coinName) {
            if(chainId === chainId) { // This condition will always be true; did you mean to compare with another variable?
              finalStakingObject = await deployStaking(
                token,
                stakingObject,
                library,
                account,
                deploymentFee,
                stakingData,
                chainId,
                closeLoadingModal
              );
            }
          }

          await axios.post(
            `${BACKEND_URL}/api/staking`,
            {
              staking: finalStakingObject,
            },
            {
              withCredentials: true,
            }
          );
          closeLoadingModal();
          window.location.href = "/stakes";
        }
      } catch (error) {
        console.error("There was an error!", error);
      }
    };

  return (
    <div className="">
      <div className="flex items-center">
        <img src={token.image1} alt={token.name} className="w-[54px] h-[54px]" />
        <img src={token.image2} alt={token.name} className="w-[54px] h-[54px]" />

        <div className=" ml-4">
          <div className="flex items-center">
            <h3 className=" font-bold dark:text-light-text">{token.name}</h3>
          </div>
        </div>
      </div>

      <PreviewHeader heading={"Staking Token address Details"} />

      <PreviewDetails name="Name" value={stakingTokenData.tokenName} />
      <PreviewDetails name="Symbol" value={stakingTokenData.tokenSymbol} />
      <PreviewDetails name="Decimals" value={stakingTokenData.tokenDecimals} />
      <PreviewDetails
        name="Total Supply"
        value={`${formatBigToNum(
          stakingTokenData.tokenSupply,
          stakingTokenData.tokenDecimals
        )} ${stakingTokenData.tokenSymbol}`}
      />

      <PreviewHeader heading={"Rewards Token address Details"} />

      <PreviewDetails name="Name" value={rewardTokenData.tokenName} />
      <PreviewDetails name="Symbol" value={rewardTokenData.tokenSymbol} />
      <PreviewDetails name="Decimals" value={rewardTokenData.tokenDecimals} />
      <PreviewDetails
        name="Total Supply"
        value={`${formatBigToNum(
          rewardTokenData.tokenSupply,
          rewardTokenData.tokenDecimals
        )} ${rewardTokenData.tokenSymbol}`}
      />

      <PreviewHeader heading={"Staking Details"} />

        <PreviewDetails
        name={"Reward Amount"}
        value={
          stakingObject.rewardAmount +
          " " +
          rewardTokenData.tokenSymbol }
        />

        <PreviewDetails
          name={"Minimum Allocation"}
          value={stakingObject.minAllocation + " " + stakingTokenData.tokenSymbol}
        />
        <PreviewDetails
          name={"Maximum Allocation"}
          value={stakingObject.maxAllocation + " " + stakingTokenData.tokenSymbol}
        />

      <PreviewHeader heading={"Time Details"} />
      <PreviewDetails
        name={"Duration"}
        value={stakingObject.duration + " " + "days"}
      />
      <div className="mt-10">
        <div className="flex justify-end items-center mb-10">
          <button
            className="bg-white dark:bg-transparent mr-5 flex items-center gap-2 py-[10px] px-5"
            onClick={() => setActive("Project Details")}
          >
            <BackArrowSVG className="fill-dark-text dark:fill-light-text" />
            <span className="font-gilroy font-medium text-sm text-dark-text dark:text-light-text">
              Go Back
            </span>
          </button>

          <button
            className="bg-primary-green hover:opacity-40 disabled:bg-light-text text-white font-gilroy font-bold px-8 py-3 rounded-md"
            onClick={handleSubmit}
          >
            Create Sale
          </button>
        </div>
      </div>
    </div>
  );
}
