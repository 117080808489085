import StakingAbi from "config/abi/Staking.json";
import Web3 from "web3";
//public
async function getAmountParticipated(stakingAddress,account,stakingType) {
  console.log(stakingAddress,account,"stakingAddress,account")
  const web3 = new Web3(window.ethereum);
  let contract
  if (stakingType === 'public'){
    contract = new web3.eth.Contract(StakingAbi, stakingAddress);
  }
  try {
    const amount = await contract.methods.userToParticipation(account).call();
    console.log(amount,"amount")
    return amount;
  } catch (err) {
    console.log(err);
  }
}

export default getAmountParticipated;
