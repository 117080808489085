import React from "react";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import HomeLayout from "../../components/HomeLayout";
import LaunchpadSVG from "../../svgs/Sidebar/launchpad";
import { useDocumentTitle } from "../../hooks/setDocumentTitle";
import PoolsBase from "../../components/Launchpad/Pools";
import Slider from "../../components/Launchpad/Pools/ads"
import { useEffect, useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";
import { useModal } from "react-simple-modal-provider";
import { useDefaultChainId } from "config/useDefaultChainId";

const Tabs = [
  {
    id: 1,
    tabName: "All",
  },
  {
    id: 2,
    tabName: "Live",
  },
  {
    id: 3,
    tabName: "Upcoming",
  },
  {
    id: 4,
    tabName: "Ended",
  },
];

export default function Pools() {
  const chainId = useDefaultChainId();
  useDocumentTitle("Pools");
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [pools, setPools] = useState([]);
  const [filteredPools, setFilteredPools] = useState([]);
  const { open: openLoadingModal, close: closeLoadingModal } =
    useModal("LoadingModal");
  //we will get data for pools from api
  useEffect(() => {
    //api request to localhost:8080/api/sale

    async function getPools() {
      openLoadingModal();
      setLoading(true);
      try {
        const res = await axios.get(`${BACKEND_URL}/api/sale`);
        const poolsOfCurrentChain = res.data.filter(
          (pool) => pool.sale.chainID
        );
        // console.log("poolsOfCurrentChain", poolsOfCurrentChain);
        let filteredPools;
      if (Tabs[activeTab - 1].tabName === "All") {
        // For the "All" tab, include both active and upcoming sales
        filteredPools = poolsOfCurrentChain.filter(
          (pool) => pool.sale.status === 'Live' || pool.sale.status === 'Upcoming' || pool.sale.status === 'Upcoming'
        );
      } else {
        // For other tabs, filter based on the tab's status
        filteredPools = poolsOfCurrentChain.filter(
          (pool) => pool.sale.status === Tabs[activeTab - 1].tabName
        );
      }

      filteredPools.sort((a, b) => {
        if (activeTab === 4) {
          // Jika activeTab adalah 4, urutkan dengan urutan b, a
          return (
            new Date(b.sale.startDate).getTime() -
            new Date(a.sale.startDate).getTime()
          );
        } else {
          // Jika activeTab bukan 4, urutkan dengan urutan standar a, b
          return (
            new Date(a.sale.startDate).getTime() -
            new Date(b.sale.startDate).getTime()
          );
        }
      });
        setPools(filteredPools);
        setFilteredPools(filteredPools);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
      closeLoadingModal();
    }

    getPools();
  }, [activeTab]);

  return (
    <BaseLayout
    title={"list"}
    >
      <HomeLayout
        tabs={Tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        pools={pools}
        setFilteredPools={setFilteredPools}
        launchpad={true}
      >
        <PoolsBase pools={filteredPools} loading={loading} />
      </HomeLayout>
    </BaseLayout>
  );
}
