import React from 'react';
import Web3 from 'web3';
import { FACTORY_ADDRESS } from 'config/constants/address';
import { Contract } from '@ethersproject/contracts';
import { parseEther } from "ethers/lib/utils";
import { ethers } from "ethers";
import ERCAbi from "../config/abi/ERC20.json";
import LockFactoryAbi from 'config/abi/LockFactory.json';
import axios from 'axios';
import { BACKEND_URL } from 'config/constants/LaunchpadAddress';

export const handleApprove = async (openLoadingModal, closeLoadingModal, lockData, library, account, chainId) => {
  openLoadingModal();
  const contract = new Contract(lockData.tokenAddress, ERCAbi, library.getSigner(account));
  try {
    console.log("Approve", FACTORY_ADDRESS[chainId], chainId, "chainId");
    const approval = await contract.approve(FACTORY_ADDRESS[chainId], ethers.constants.MaxUint256);
    await approval.wait();
  } catch (error) {
    console.log(error, "error");
  }
  closeLoadingModal();
};

export const handleLockToken = async (
  lockData,
  library,
  account,
  chainId,
  feeInfo,
  closeLoadingModal
) => {
  let deployedAddress;
  let finalLockObject;
  const factoryContract = FACTORY_ADDRESS[chainId];
  const contract = new Contract(factoryContract, LockFactoryAbi, library.getSigner(account));
  const lockId = await contract.getTotalLiquidityLock();

  try {
    console.log("Lock token", FACTORY_ADDRESS[chainId], chainId, "chainId");

    const createLock = await contract.createTokenLock(
      account,
      lockData.tokenAddress,
      lockData.lockAmount,
      lockData.unlockDate,
      lockData.image,
      {
        value: parseEther(feeInfo.normalFee),
      },
    );

    await createLock.wait();

    deployedAddress = await contract.tokenLockIdToAddress(lockId.toNumber());
    finalLockObject = {
      lockId: lockId.toNumber(),
      lockAddress: deployedAddress,
      lockType: lockData.type,
      tokenAddress: lockData.tokenAddress,
      tokenName: lockData.tokenName,
      tokenSymbol: lockData.tokenSymbol,
      tokenDecimals: lockData.tokenDecimals,
      tokenSupply: lockData.tokenSupply,
      lockAmount: lockData.lockAmount,
      lockAmountBN: lockData.lockAmountBN,
      unlockDate: lockData.unlockDate,
      chainID: chainId,
      image: lockData.image,
      owner: account,
      isCancelled: false,
    };
    return finalLockObject;
  } catch (error) {
    console.log(error, "error");
    closeLoadingModal();
    return null;
  }
};

  export const handleLockLP = async (
    lockData,
    library,
    account,
    chainId,
    feeInfo,
    closeLoadingModal
  ) => {
    let deployedAddress;
    let finalLockObject;
    const factoryContract = FACTORY_ADDRESS[chainId];
    const contract = new Contract(factoryContract, LockFactoryAbi, library.getSigner());
    const lockId = await contract.getTotalLiquidityLock();
    console.log("Lock LP", FACTORY_ADDRESS[chainId], chainId, "chainId");
    try {
      const createLock = await contract.createLiquidityLock(
        account,
        lockData.tokenAddress,
        lockData.lockAmount,
        lockData.unlockDate,
        '',
        {
          value: parseEther(feeInfo.liquidityFee),
        },
      );
      await createLock.wait();
      deployedAddress = await contract.liquidityLockIdToAddress(lockId.toNumber());
      const lockObject = {
        lockId: lockId.toNumber(),
        lockAddress: deployedAddress,
        lockType: lockData.type,
        tokenAddress: lockData.tokenAddress,
        tokenName: lockData.tokenName,
        tokenSymbol: lockData.tokenSymbol,
        tokenDecimals: lockData.tokenDecimals,
        tokenSupply: lockData.tokenSupply,
        token0: {
          address: lockData.token0.Address,
          tokenName: lockData.token0.Name,
          symbol: lockData.token0.symbol,
          tokenDecimal: lockData.token0.Decimal,
          tokenSupply: lockData.token0Supply,
        },
        token1: {
          address: lockData.token1.Address,
          tokenName: lockData.token1.Name,
          symbol: lockData.token1.symbol,
          tokenDecimal: lockData.token1.Decimal,
          tokenSupply: lockData.token1Supply,
        },
        lockAmount: lockData.lockAmount,
        lockAmountBN: lockData.lockAmountBN,
        unlockDate: lockData.unlockDate,
        chainID: chainId,
        image: lockData.image,
        owner: account,
        liquidity: lockData.liquidity,
      };
      console.log("Lock Object Created:", lockObject);  // Tambahkan log ini
      return finalLockObject;
    } catch (error) {
      console.error(error);
      console.log("Revert Reason:", error.reason || "N/A");
      closeLoadingModal();
      alert("Transaction Failed");
      return null;
    }
  };
