import { useState, useEffect } from 'react';
// import { BSC } from '@usedapp/core';
import { Vanar, CoreChain } from 'config/constants/testchain';

export function useDefaultChainId() {
  const [defaultChainId, setDefaultChainId] = useState(() => {
    const storedChainId = localStorage.getItem('network-switch');
    if (storedChainId) {
      const items = JSON.parse(storedChainId);
      const activeItem = items.find((item) => item.isActive);

      if (activeItem) {
        if (activeItem.title === 'CORE') {
          return CoreChain.chainId;
        } else if (activeItem.title === 'VANAR') {
          return Vanar.chainId;
        }
      }
    }

    const isPreferredBsc = true;
    return isPreferredBsc ? CoreChain.chainId :  Vanar.chainId;
  });

  useEffect(() => {
    const handleStorageChange = (e) => {
      const network = localStorage.getItem('network-switch');
      if (network) {
        const items = JSON.parse(network);
        const activeItem = items.find((item) => item.isActive);

        if (activeItem) {
          if (activeItem.title === 'CORE') {
            setDefaultChainId(CoreChain.chainId);
          } else if (activeItem.title === 'VANAR') {
            setDefaultChainId(Vanar.chainId);
          }
        }
      }

      //window.location.reload()
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return defaultChainId;
}
