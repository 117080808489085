import { Contract } from "@ethersproject/contracts";
import Web3 from 'web3';
import StakingFactoryAbi from "../config/abi/StakingFactory.json";
import { Staking_Factory } from "config/constants/LaunchpadAddress";
import { FACTORY_ADDRESS } from "config/constants/address";
import { parseEther, parseUnits } from "ethers/lib/utils";
import { ethers, utils } from "ethers";
import ERCAbi from "../config/abi/ERC20.json";
import { useDefaultChainId } from "config/useDefaultChainId";

export const deployStaking = async (
  token,
  stakingObject,
  library,
  account,
  deploymentFee,
  stakingData,
  chainId,
  closeLoadingModal
) => {
  const stakingFactory = Staking_Factory[chainId];
  const contract = new Contract(
    stakingFactory,
    StakingFactoryAbi,
    library.getSigner()
  );

  console.log('publicFactory:', stakingFactory);
  console.log('publicFactory:', token.stakingToken.tokenAddress);
  console.log('publicFactory:', token.rewardToken.tokenAddress);
  console.log('publicFactory:', stakingObject.minAllocation);
  console.log('publicFactory:', stakingObject.maxAllocation);
  console.log('publicFactory:', account);
  console.log('publicFactory:', stakingObject.rewardAmount);

  const stakingId = await contract.getNumberOfStakeDeployed();
  let deployedAddress;
  let finalStakingObject;

  const deployFee = ethers.utils.parseEther("0.01");

  try {
    const tx = await contract.createStakingContract(
      account,
      token.stakingToken.tokenAddress,
      token.rewardToken.tokenAddress,
      parseUnits(stakingObject.rewardAmount.toString(),token.tokenDecimals).toString(),
      [
      parseUnits(stakingObject.minAllocation.toString(),token.tokenDecimals).toString(),
      parseUnits(stakingObject.maxAllocation.toString(),token.tokenDecimals).toString(),
     ],
     stakingObject.startDate,
     stakingObject.duration,
      {
        value: deployFee,
        gasLimit: 5000000,
      }
    );
    await tx.wait();

    deployedAddress = await contract.stakeIdToAddress(stakingId.toNumber());

    finalStakingObject = {
      stakingId: stakingId.toNumber(),
      stakingAddress: deployedAddress,
      stakingType: stakingData.type,
      github: stakingData.github,
      website: stakingData.website,
      twitter: stakingData.twitter,
      whitepaper: stakingData.whitepaper,
      chainID: chainId,
      linkedin: stakingData.linkedin,
      discord: stakingData.discord,
      telegram: stakingData.telegram,
      description: stakingData.description,
      image1: stakingData.image1,
      image2: stakingData.image2,
      token1: token.stakingToken,
      token2: token.rewardToken,
      currency: stakingObject.currency,
      rewardAmount: stakingObject.rewardAmount,
      minAllocation: stakingObject.minAllocation,
      maxAllocation: stakingObject.maxAllocation,
      startDate: stakingObject.startDate,
      endDate: stakingObject.endDate,
      duration: stakingObject.duration,
      owner: account,
      isFinished: false,
    };
    return finalStakingObject;
    console.log('finalStakingObject:', finalStakingObject);
  } catch (error) {
    console.error(error);
    console.log("Revert Reason:", error.reason || "N/A");
    closeLoadingModal();
    alert("Transaction Failed");
  }
};
