import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Styles from "./BaseLayout.module.css";

import { SidebarContext } from "../../context/SidebarContext/GlobalProvider";
import { useActiveItem } from "../../hooks/setActiveItem";
import { ThemeContext } from "../../context/ThemeContext/ThemeProvider";
import Topbar from "../Topbar/Topbar";
import Menu from "../Topbar/Menu";
import { ToastContainer } from "react-toastify";

export default function BaseLayout({
  children,
  noTopbar,
  noSidebar,
  title,
  subpage,
  title_img,
  page_name,
  page_description,
  admin,
  setAdminMode,
}) {
  const { showSidebar, setShowSidebar } = useContext(SidebarContext);
  const { theme, setTheme } = useContext(ThemeContext);
  const [sideBarMobile, setSideBarMobile] = useState(false);
  const [tempfixed, setTempFixed] = useState(true);
  const [activeItem] = useActiveItem();

  const handleTempFixed = () => {
    setTheme(!tempfixed ? "light" : "dark");
    setTempFixed(!tempfixed);
  };

  const hideSidebar = () => {
    if (sideBarMobile) {
      setSideBarMobile(false);
    }
  };

  useEffect(() => {
    if (theme === "dark") {
      setTempFixed(false);
    }
  }, [theme]);

  return (
    <div className="sticky w-full bg-[#F0FDFA]">
      <div className={`${Styles.menu} w-full`}>
        <Menu />
      </div>
      <div className="sticky w-full dark:bg-dark">
        {noTopbar ? null : (
          <div className={`${Styles.topBar} w-full`}>
            <Topbar
              subpage={subpage}
              page_name={page_name}
              admin={admin}
              setAdminMode={setAdminMode}
            />
          </div>
        )}
        <div className="pb-10  font-gilroy bg-tree-pattern-mobile md:bg-tree-pattern bg-center bg-no-repeat bg-contain">
          {children}
        </div>
      </div>
    </div>
  );
}
