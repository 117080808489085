import React from 'react';

export default function SlideContent({ name, img, mobile, link }) {
  if (mobile) {
    return (
      <div href={link} className="w-full h-[80%] px-2">
        <img className="w-full h-[80%]" src={img} alt={name} />
      </div>
    );
  }
  return (
    <div href={link} className="w-full h-[80%] pr-2">
      <img className="w-full h-[80%] rounded-xl" src={img} alt={name} />
    </div>
  );
}
