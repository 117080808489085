import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 32 32"
    xmlSpace="preserve"
    {...props}
  >
    <circle
      cx={16}
      cy={16}
      r={13}
      style={{
        fill: "#e54d2e",
      }}
    />
    <path
      d="M16 3v26c7.18 0 13-5.82 13-13S23.18 3 16 3"
      style={{
        fill: "#f27261",
      }}
    />
    <path
      d="M16 3c7.168 0 13 5.832 13 13s-5.832 13-13 13S3 23.168 3 16 8.832 3 16 3m0-3C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0m2.121 16 2.475-2.475a1.5 1.5 0 1 0-2.121-2.121L16 13.879l-2.475-2.475a1.5 1.5 0 1 0-2.121 2.121L13.879 16l-2.475 2.475a1.5 1.5 0 1 0 2.122 2.121L16 18.121l2.475 2.475c.293.293.677.439 1.061.439s.768-.146 1.061-.439a1.5 1.5 0 0 0 0-2.121z"
      style={{
        fill: "#f4d6b0",
      }}
    />
  </svg>
);
export default SVGComponent;
