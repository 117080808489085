import React from "react";
import stepImage from "./images/icons/steps.png"
import frame1 from "./images/bg/RoadMapV2Fram.png"
import frame2 from "./images/bg/RoadMapV2Frame2.png"
import plusIcon from "./images/kycimg/RMcheckicon.svg"
import minusIcon from "./images/kycimg/munies.svg"
import roadLine from "./images/bg/roadMap_Line.svg"
import roadCheck from "./images/icons/roadmapChackicon.svg"
import "./style.css";
import "./animate.css";
import "./responsive.css";

export default function Roadmap() {
  return (
    <div class="RoadMapV2_section main-project-area">
            <div class="container">
                <div class="sec-inner align-items-center d-flex justify-content-between mb-30">
                    <div class="sec-heading">
                        <div class="flex sub-inner mb-15">
                            <span class="sub-title">Our Goals</span>
                            <img class="heading-left-image" src={stepImage} alt="Steps-Image" />
                        </div>
                        <h2 class="title">ROADMAPs</h2>
                    </div>
                    <div class="gamfi-btn-area RoadmapTabBtns">
                        <ul>
                            <li class="tablinks m-0" onclick="openCity(event, 'RoadmapV2_2021')">
                                 <button class="readon white-btn black-shape">
                                    <span class="btn-text">2024</span>
                                    <span class="hover-shape1"></span>
                                    <span class="hover-shape2"></span>
                                    <span class="hover-shape3"></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="RoadMapV2_TabContentSect">
                    <div class="RoadMapShape">
                        <img src={roadLine} alt="" class="img-fluid" />
                    </div>
                    <div id="RoadmapV2_2021" class="tabcontent animate_opacity">
                        <div class="RoadmapV2TabContent RoadmapV2TabContent_2021">
                           <div class="RoadMapSilkSliderSection">
                               <div class="row m-0">
                                   <div class="col-md-4">
                                       <div class="RoadMapContents">
                                           <h3>Q1-Q2 2024</h3>
                                           <div class="RoadmapListSect">
                                               <div class="CheckIconBg"><img src={roadCheck} alt="" class="img-fluid" /></div>
                                               <ul>
                                                   <li><span></span>Launchpad Development</li>
                                                   <li><span></span>Beta Testing</li>
                                                   <li><span></span>Core chain Integration</li>
                                                   <li><span></span>MultiChain Integration</li>
                                                   <li class="m-0"><span></span>Build Community</li>
                                               </ul>
                                           </div>
                                       </div>
                                   </div>
                                   <div class="col-md-4">
                                       <div class="RoadMapContents">
                                           <h3>Q3 2024</h3>
                                           <div class="RoadmapListSect">
                                               <div class="CheckIconBg"><img src="assets/images/icons/roadmapChackicon.svg" alt="" class="img-fluid" /></div>
                                               <ul>
                                                   <li><span></span>Launch Bull Token </li>
                                                   <li><span></span>Launch Staking Platform</li>
                                                   <li><span></span>Bull CEX Listing</li>
                                                   <li><span></span>More DEX Partnership</li>
                                                   <li class="m-0"><span></span>More Chain Integration</li>
                                               </ul>
                                           </div>
                                       </div>
                                   </div>
                                   <div class="col-md-4">
                                       <div class="RoadMapContents">
                                           <h3>Q4 2024</h3>
                                           <div class="RoadmapListSect">
                                               <div class="CheckIconBg"><img src="assets/images/icons/roadmapChackicon.svg" alt="" class="img-fluid" /></div>
                                               <ul>
                                                   <li><span></span>New Feature Release</li>
                                                   <li><span></span>Upgrade The Website</li>
                                                   <li><span></span>Launch VVIP Launchpad</li>
                                                   <li class="m-0"><span></span>More Coming Soon</li>
                                               </ul>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
            <span class="Frame1"><img src={frame1} alt="" class="img-fluid" /></span>
            <span class="Frame2"><img src={frame2} alt="" class="img-fluid" /></span>
        </div>
  );
}
