import React, { useState, useEffect } from "react";
import { getTokenLockInfos } from "utils/getLockList";
import BaseLayout from "../../components/BaseLayout/BaseLayout";
import HomeLayout from "../../components/HomeLayout";
import LockerBase from "../../components/Locker";
import SheildSecuritySVG from "../../svgs/Sidebar/shield_security";
import { useDefaultChainId } from "config/useDefaultChainId";
import { useModal } from "react-simple-modal-provider";
import axios from "axios";
import { BACKEND_URL } from "config/constants/LaunchpadAddress";

export default function Locker() {
  const [cardFormat, setCardFormat] = useState("grid");
  const [itemSelected, setItemSelected] = useState("liquidity");
  const [ready, setReady] = useState(false);
  const [tokenList, setTokenList] = useState([]);
  const [liquidityList, setLiquidityList] = useState([]);
  const chainId = useDefaultChainId();
  const { open: openLoadingModal, close: closeLoadingModal } = useModal("LoadingModal");

  const fetchLp = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/lock/`, {
        params: {
          liquidity: true,
          chainId: chainId,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching LP locks:", error);
      return [];
    }
  };

  const fetchTokens = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/api/lock/`, {
        params: {
          liquidity: false,
          chainId: chainId,
        },
      });
      console.log("response:", response);
      return response.data;
    } catch (error) {
      console.error("Error fetching token locks:", error);
      return [];
    }
  };

  const handleFetch = async () => {
    setReady(false);
    openLoadingModal();
    try {
      const tokens = await fetchTokens();
      console.log("Tokens fetched:", tokens);

      if (tokens.length > 0) {
        const tokenAddresses = tokens.map(token => token.lock.lockAddress);
        const tokenInfos = await getTokenLockInfos(tokenAddresses, chainId);
        console.log("Token Infos:", tokenInfos);
        if (tokenInfos.success) {
          setTokenList(tokenInfos.data);
        } else {
          console.error("Failed to fetch token lock infos");
        }
      }

      const lpInfos = await fetchLp();
      console.log("Liquidity Infos:", lpInfos);
      if (lpInfos.length > 0) {
        const formattedLpInfos = lpInfos.map(item => ({ ...item, ...item.lock }));
        setLiquidityList(formattedLpInfos);
      }
    } catch (error) {
      console.error("Error in handleFetch:", error);
    } finally {
      closeLoadingModal();
      setReady(true);
    }
  };


  useEffect(() => {
    handleFetch();
  }, [chainId]);

  return (
    <BaseLayout
      title={"Locker"}
      page_name={"Locked Assets"}
      title_img={<SheildSecuritySVG className="md:hidden fill-dim-text" />}
    >
      <HomeLayout
        cardFormat={cardFormat}
        setCardFormat={setCardFormat}
        itemSelected={itemSelected}
        setItemSelected={setItemSelected}
        locker
      >
        {ready ? (
          <LockerBase
            cardFormat={cardFormat}
            itemSelected={itemSelected}
            tokenList={tokenList}
            liquidityList={liquidityList}
          />
        ) : (
          <p>Loading...</p>
        )}
      </HomeLayout>
    </BaseLayout>
  );
}
