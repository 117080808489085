import React, { useState, useEffect } from "react";
import Web3 from 'web3';
import BackArrowSVG from "../../../svgs/back_arrow";
import PreviewDetails from "../../Common/PreviewDetails";
import PreviewHeader from "../../Common/PreviewHeader";
import HeadingTags from "../../TokenLocker/Subcomponents/HeadingTags";
import { getTokenInfo } from "utils/tokenInfo";
import { useModal } from "react-simple-modal-provider";
import { isAddress } from "ethers/lib/utils";
import { formatBigToNum } from "../../../utils/numberFormat";
import { useDefaultChainId } from "config/useDefaultChainId";

const coreFees = 100;
const vanarFees = 1000;

const coreSymbol = "CORE";
const vanarSymbol = "VANRY";

export default function TokenInfo({
  setActive,
  setStakingType,
  stakingData,
  setStakingTokenData,
  setRewardTokenData
}) {
  const [enable, setEnable] = useState(stakingData.isValid);
  const [visible, setVisible] = useState(stakingData.showDetails);
  const [fees, setFees] = useState(vanarFees);
  const [nativeSymbol, setNativeSymbol] = useState(vanarSymbol);
  const [stakingToken, setStakingToken] = useState({});
  const [rewardToken, setRewardToken] = useState({});
  const chainId = useDefaultChainId();

  useEffect(() => {
    if (chainId === 1116) {
      setFees(coreFees);
    } else if (chainId === 2040) {
      setFees(vanarFees);
    }
  }, [chainId]);

  useEffect(() => {
    if (chainId === 1116) {
      setNativeSymbol(coreSymbol);
    } else if (chainId === 2040) {
      setNativeSymbol(vanarSymbol);
    }
  }, [chainId]);

  const { open: openLoadingModal, close: closeLoadingModal } = useModal("LoadingModal");

  const handleChange1 = async (e) => {
    setEnable(false);
    setVisible(false);
    if (isAddress(e.target.value)) {
      openLoadingModal();
      const tokenInfo = await getTokenInfo(chainId, e.target.value);
      if (tokenInfo.success) {
        setStakingToken({
          name: tokenInfo.data.name,
          tokenAddress: e.target.value,
          tokenName: tokenInfo.data.name,
          tokenSymbol: tokenInfo.data.symbol,
          tokenDecimals: tokenInfo.data.decimals,
          tokenSupply: tokenInfo.data.totalSupply,
          showDetails: true,
          isValid: true,
        });
        setVisible(true);
        setEnable(true);
      } else {
        setStakingToken({
          showDetails: false,
          isValid: false,
        });
      }
      closeLoadingModal();
    } else {
      setStakingToken({
        showDetails: false,
        isValid: false,
      });
    }
  };

  const handleChange2 = async (e) => {
    setEnable(false);
    setVisible(false);
    if (isAddress(e.target.value)) {
      openLoadingModal();
      const tokenInfo = await getTokenInfo(chainId, e.target.value);
      if (tokenInfo.success) {
        setRewardToken({
          name: tokenInfo.data.name,
          tokenAddress: e.target.value,
          tokenName: tokenInfo.data.name,
          tokenSymbol: tokenInfo.data.symbol,
          tokenDecimals: tokenInfo.data.decimals,
          tokenSupply: tokenInfo.data.totalSupply,
          showDetails: true,
          isValid: true,
        });
        setVisible(true);
        setEnable(true);
      } else {
        setRewardToken({
          showDetails: false,
          isValid: false,
        });
      }
      closeLoadingModal();
    } else {
      setRewardToken({
        showDetails: false,
        isValid: false,
      });
    }
  };

  useEffect(() => {
    setStakingTokenData(stakingToken);
  }, [stakingToken, setStakingTokenData]);

  useEffect(() => {
    setRewardTokenData(rewardToken);
  }, [rewardToken, setRewardTokenData]);

  return (
    <div className="w-full">
      <HeadingTags name={"Staking Token Address"} required />
      <input
        className="bg-transparent mt-2 w-full px-3 py-3 font-gilroy placeholder:font-medium placeholder:text-dim-text font-semibold text-dark-text dark:text-light-text focus:outline-none border-[1.5px] rounded-lg border-dim-text border-opacity-50"
        type={"text"}
        placeholder="0xc197033c129839ED4740c29919Bd88fD42bbde"
        onChange={(e) => handleChange1(e)}
      />
      <PreviewHeader heading={"Token address Details"} />
      {stakingToken.showDetails && (
        <div className="mt-3 mb-5">
          <>
            <PreviewDetails name="Name" value={stakingToken.tokenName} />
            <PreviewDetails name="Symbol" value={stakingToken.tokenSymbol} />
            <PreviewDetails name="Decimals" value={stakingToken.tokenDecimals} />
            <PreviewDetails
              name="Total Supply"
              value={`${formatBigToNum(stakingToken.tokenSupply, stakingToken.tokenDecimals)} ${stakingToken.tokenSymbol}`}
            />
          </>
        </div>
      )}
      <HeadingTags name={"Reward Token Address"} required />
      <input
        className="bg-transparent mt-2 w-full px-3 py-3 font-gilroy placeholder:font-medium placeholder:text-dim-text font-semibold text-dark-text dark:text-light-text focus:outline-none border-[1.5px] rounded-lg border-dim-text border-opacity-50"
        type={"text"}
        placeholder="0xc197033c129839ED4740c29919Bd88fD42bbde"
        onChange={(e) => handleChange2(e)}
      />
      <PreviewHeader heading={"Token address Details"} />
      {rewardToken.showDetails && (
        <div className="mt-3 mb-5">
          <>
            <PreviewDetails name="Name" value={rewardToken.tokenName} />
            <PreviewDetails name="Symbol" value={rewardToken.tokenSymbol} />
            <PreviewDetails name="Decimals" value={rewardToken.tokenDecimals} />
            <PreviewDetails
              name="Total Supply"
              value={`${formatBigToNum(rewardToken.tokenSupply, rewardToken.tokenDecimals)} ${rewardToken.tokenSymbol}`}
            />
          </>
        </div>
      )}
      <PreviewHeader heading={`Launch Fee: ${fees} ${nativeSymbol}`} />
      <div className="mt-10">
        <div className="flex justify-end items-center mb-10">
          <button className="bg-white dark:bg-transparent mr-5 flex items-center gap-2 py-[10px] px-5" onClick={() => setStakingType(null)}>
            <BackArrowSVG className="fill-dark-text dark:fill-light-text" />
            <span className="font-gilroy font-medium text-sm text-dark-text dark:text-light-text">
              Go Back
            </span>
          </button>
          <button
            className="bg-primary-green hover:opacity-40 text-white rounded-md px-[42px] py-4 font-bold"
            disabled={!enable}
            onClick={() => setActive('Staking')}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
