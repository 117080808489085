import { Chain } from '@usedapp/core'

export const CoreChain: Chain = {
  chainId: 1116,
  chainName: 'Core Chain',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0xFBc9360C283BFFB8792B0424c1FcB60972b2bfaA',
  getExplorerAddressLink: (address) => `https://scan.coredao.org/address/${address}`,
  getExplorerTransactionLink: (transactionHash) => `https://scan.coredao.org/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: 'https://rpc-core.icecreamswap.com',
  blockExplorerUrl: 'https://scan.coredao.org/',
  nativeCurrency: {
    name: 'CORE MAINNET',
    symbol: 'CORE',
    decimals: 18,
  },
}

export const Vanar: Chain = {
  chainId: 2040,
  chainName: 'VanarChain',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0x73C98555cEd0d331D7B94f7B1eCd2cbF617D068d',
  getExplorerAddressLink: (address) => `https://explorer.vanarchain.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash) => `https://explorer.vanarchain.com/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: 'https://rpc-partners.vanarchain.com',
  blockExplorerUrl: 'https://explorer.vanarchain.com/',
  nativeCurrency: {
    name: 'Vanguard',
    symbol: 'VANRY',
    decimals: 18,
  },
}

export const Base: Chain = {
  chainId: 84532,
  chainName: 'Base Testnet',
  isTestChain: true,
  isLocalChain: false,
  multicallAddress: '0xF6b74a6ca157e990B55B822558CBE5D3147BE508',
  getExplorerAddressLink: (address) => `https://base-sepolia.blockscout.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash) => `https://base-sepolia.blockscout.com/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: 'https://sepolia.base.org',
  blockExplorerUrl: 'https://base-sepolia.blockscout.com',
  nativeCurrency: {
    name: 'BASE ETH',
    symbol: 'ETH',
    decimals: 18,
  },
}

export const BccTest: Chain = {
  chainId: 1919,
  chainName: 'BCC',
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
  getExplorerAddressLink: (address) => `https://explorer.bcchaindev.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash) => `https://explorer.bcchaindev.com/tx/${transactionHash}`,
  // Optional parameters:
  rpcUrl: 'https://rpc.bcchaindev.com/',
  blockExplorerUrl: 'https://explorer.bcchaindev.com/',
  nativeCurrency: {
    name: 'BCC Chain',
    symbol: 'BCC',
    decimals: 18,
  },
}
