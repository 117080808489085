import React from "react";
import rocketImage from "./images/homeV2/rocket01.png"
import rocketImage1 from "./images/homeV2/rocketStar.svg"
import rocketImage2 from "./images/homeV2/rocket-front-color.png"
import rocketImage3 from "./images/homeV2/rocket-front-color.png"
import "./style.css";
import "./animate.css";
import "./responsive.css";

export default function Header() {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="gamfi_V2_hero_Left">
              <h2 className="wow fadeInUp text-black" data-wow-delay="0.4s" data-wow-duration="0.7s">
                Multichain DeFi Web <span>3.0</span> Ecosystem
              </h2>
              <p className="wow fadeInUp text-black" data-wow-delay="0.5s" data-wow-duration="0.8s">
                BullPad helps small projects grow by providing secure and decentralized fundraising, comprehensive pre-IDO support, and ongoing post-IDO assistance!
              </p>
              <div className="gamfi_V2_hero_Btns wow fadeInUp" data-wow-delay="0.6s" data-wow-duration="0.9s">
                <a href="/pools">
                  <button type="button" className="readon white-btn hover-shape VIEW_Projects_Btn">
                    <span className="btn-text">VIEW Projects</span>
                    <span className="hover-shape1"></span>
                    <span className="hover-shape2"></span>
                    <span className="hover-shape3"></span>
                  </button>
                </a>
                <a className="readon black-shape Apply_Project_Btn" href="https://www.canva.com/design/DAGGoX6euGQ/w4LTGae9QkUlImy8r38v9g/view#16">
                  <span className="text-black">PitchDeck</span>
                  <span className="hover-shape1"></span>
                  <span className="hover-shape2"></span>
                  <span className="hover-shape3"></span>
                </a>
              </div>

            </div>
          </div>
          <div className="col-md-6">
            <div className="gamfi_V2_hero_right">
              <div className="gamfi_V2_heroRocket">
                <img
                  src={rocketImage}
                  alt="Rocket Img"
                  className="Img-fluid RedRocketImg wow fadeInUp"
                />
                <span className="rocketshadow1">
                  <img src={rocketImage1} alt="" className="img-fluid" />
                </span>
                <span className="rocketshadow2">
                  <img src={rocketImage1} alt="" className="img-fluid" />
                </span>
                <span className="rocketshadow3">
                  <img src={rocketImage1} alt="" className="img-fluid" />
                </span>
                <span className="rocketshadow4">
                  <img src={rocketImage1} alt="" className="img-fluid" />
                </span>
                <span className="rocketshadow5">
                  <img src={rocketImage1} alt="" className="img-fluid" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
