import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import { CandlestickController, CandlestickElement } from 'chartjs-chart-financial';
import 'chartjs-adapter-date-fns';
import { Chart } from 'react-chartjs-2';

// Register the necessary components and controllers with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  CandlestickController,
  CandlestickElement
);

const CandlestickChart = () => {
  const [candlestickData, setCandlestickData] = useState([]);
  console.log('candlestickData:', candlestickData)
  const chartRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    // Panggil API untuk mendapatkan data candlestick
    axios.get('http://localhost:5000/api/swaps/candle')
      .then(response => {
        const data = response.data.candlestickData.map(item => ({
          t: new Date(item.timestamp),
          o: item.open,
          h: item.high,
          l: item.low,
          c: item.close,
        }));
        setCandlestickData(data);
      })
      .catch(error => {
        console.error('Error fetching candlestick data:', error);
      });
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = canvasRef.current.getContext('2d');

    chartRef.current = new ChartJS(ctx, {
      type: 'candlestick',
      data: {
        datasets: [{
          label: 'Candlestick Chart',
          data: candlestickData,
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 2,
        }],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
            },
            title: {
              display: true,
              text: 'Date',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Price',
            },
          },
        },
      },
    });
  }, [candlestickData]);

  return (
    <div style={{ width: '100%', height: 400 }}>
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default CandlestickChart;
