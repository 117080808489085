import React from 'react'
import Preview from '../Preview'
import SaleBox from '../Sale'
import UserPanel from '../UserPanel/UserPanel';
import AdminPanel from '../Admin/AdminPanel';
import FundRaised from '../Admin/FundRaised';
import LPStatus from '../Admin/LPStatus';
import FairDeposit from '../Admin/FairDeposit';
import { useState } from 'react';
import { useEffect } from 'react';
import Web3 from 'web3';
import SaleAbi from '../../../config/abi/PublicSale.json';

export default function StakePageBase({ stake, visible, showModal, admin, objId ,isFinished,isCancelled}) {
  const [status, setStatus] = useState('Live')
  const [liquidityTokens, setLiquidityTokens] = useState(0);
  const [whitelistedUser, setWhitelistedUser] = useState(false);

  useEffect(() => {

    //if endDate is less than current date, set status to ended
    if (stake.endDate < new Date().getTime()/1000) {
      setStatus('Ended')
    } else if (stake.startDate > new Date().getTime()/1000){
      setStatus('Upcoming')
    }
  }, [])

  return (
    stake && (
      <div className="w-full flex justify-center">
        <div className="w-full px-4 md:px-0 md:flex md:w-10/12 md:gap-7">
          <div className="w-full md:w-[65%] bg-white dark:bg-dark-1 rounded-[10px] overflow-hidden border-2 border-[#00bb41]">
            <Preview
            rewardAmount={stake.rewardAmount}
            symbol1={stake.image1}
            symbol2={stake.image2}
            currency={stake.currency}
            chainId={stake.chainID}
            stake={stake}
            name1={stake.token1.name}
            name2={stake.token2.name}
            icon1={stake.image1}
            icon2={stake.image2}
            stakingType={stake.saleType}
            token={stake.token}
            description={stake.description}
            min_buy={stake.minAllocation}
            max_buy={stake.maxAllocation}
            start_date={stake.startDate}
            address={stake.saleAddress}
            ends_on={stake.duration}
            objId={objId}
            admin = {admin}
            finished = {isFinished}
            cancelled = {isCancelled}
          />
        </div>

          <div className="mt-14 md:mt-0 md:w-[35%] ">

          <div className="rounded-[10px] border-2 border-[#00bb41]">

           <SaleBox
              isCancelled={isCancelled}
              currency={stake.currency}
              chainId={stake.chainID}
              start_date={stake.startDate}
              end_date={stake.endDate}
              ends_on={stake.duration}
              status={stake.status}
              showModal={showModal}
              token = {stake.token}
              stake_address={stake.stakingAddress}
              stake={stake} visible={visible}
              finished = {isFinished}
              cancelled = {isCancelled}
            />
            </div>
              <div className='mt-[30px] rounded-[10px] border-2 border-[#00bb41]'>
                <UserPanel icon={stake.image} stake={stake} stake_address={stake.stakingAddress} status={stake.status} isFinished={isFinished} isCancelled={isCancelled}/>
              </div>
          </div>
        </div>
      </div>
    )
  )
}
