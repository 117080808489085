import React from "react";
import { Link } from "react-router-dom";
import Timer from "./Subcomponents/Timer";
import { useEffect, useState } from "react";
import PreviewDetails from "components/Common/PreviewDetails";
import getSaleInfo from "utils/getSaleInfo";
import baseLogo from "./Logo/Base.png";
import coreLogo from "./Logo/core.png";
import vanarLogo from "./Logo/Vanar.png";
import PercentFilled from "./Subcomponents/PercentFilled";
import RewardBlock from "./Subcomponents/RewardBlock";
import { useDefaultChainId } from "config/useDefaultChainId";

const vanarBlock = 5;
const coreBlock = 3;

export default function StakesBase({
  stakes,
  stake,
  address,
  ends_on,
  min_buy,
  max_buy,
  rewardAmount,
  status,
  loading,
  icon1,
  icon2,
  finished,
  isCancelled,
  stakeAddress,
}) {
  console.log("ends_on:", ends_on)
  const [progress, setProgress] = useState(0);
  const [block, setBlock] = useState(vanarBlock);
  const chainId = useDefaultChainId();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (chainId === 1116) {
      setBlock(coreBlock);
    } else if (chainId === 2040) {
      setBlock(vanarBlock);
    }
  }, [chainId]);

  return (
  <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-8">
    {!loading &&
      stakes?.map((stake) => (
        <Link to={`/stakes/${stake._id}`} key={stake._id}>
          <div className="flex flex-col relative overflow-hidden">
            <div className="overflow-hidden"></div>
            <div className="bg-white dark:bg-dark-1 rounded-[20px] border-2 border-[#00bb41] py-[-1px] px-[-1px] pb-4 pr-4 pl-4">
              <div className="flex items-center justify-between pt-2 pb-2">
                <div className="col-end-7 col-span-2">
                  {stake.staking.chainID === 1116 && stake.staking.image1 && (
                    <span className="flex ml-2 text-[12px] font-bold bg-[#d4faf9] dark:bg-[#B86363] py-[3px] px-2 text-black rounded-[4px] pulsate2">
                      <img className="w-4 h-4" src={coreLogo} alt="Currency Icon" />
                      &nbsp;CORE
                    </span>
                  )}
                  {stake.staking.chainID === 2040 && stake.staking.image1 && (
                    <span className="flex ml-2 text-[12px] font-bold bg-[#d4faf9] dark:bg-[#B86363] py-[3px] px-2 text-black rounded-[4px] pulsate2">
                      <img className="w-4 h-4" src={vanarLogo} alt="Currency Icon" />
                      &nbsp;CORE
                    </span>
                  )}
                </div>
                <div className="col-end-7 col-span-2">
                  {stake.staking.status === "Live" && (
                    <span className="ml-4 text-[12px] font-bold bg-[#d4faf9] dark:bg-[#B86363] py-[3px] px-2 text-black rounded-[4px] pulsate">
                      🟢&nbsp;Live Staking
                    </span>
                  )}
                  {stake.staking.status === "Upcoming" && (
                    <span className="ml-4 text-[12px] font-bold bg-[#faf7d4] dark:bg-[#faf7d4] py-[3px] px-2 text-#ffae52 rounded-[4px] pulsate">
                      🟠&nbsp;Upcoming
                    </span>
                  )}
                  {stake.staking.status === "Ended" && (
                    <span className="ml-4 text-[12px] font-bold bg-[#d7fcf0] dark:bg-[#e72ab0] py-[3px] px-2 text-black rounded-[4px] pulsate">
                      🔴&nbsp;Ended
                    </span>
                  )}
                </div>
              </div>
              <img
                src={stake.staking.banner || "https://cdna.artstation.com/p/assets/images/images/060/746/238/original/feon-cityundermoon-pixel-art.gif"}
                alt={stake.staking.name}
                className="w-full h-[100px] rounded-[10px] border border-2px border-black"
              />
              <div>
                <div className="col-end-7 col-span-2">
                  <div className="items-center relative">
                    <div className="flex items-center">
                      <img
                        src={stake.staking.image1}
                        alt={stake.staking.name}
                        className="w-[35px] h-[35px] left-[45%] align-center justify-center -translate-x-1/2 -translate-y-1/2 rounded-[50px] border border-solid border-2px border-black relative z-0"
                      />
                      <img
                        src={stake.staking.image2}
                        alt={stake.staking.name}
                        className="w-[35px] h-[35px] left-[45%] align-center justify-center -translate-x-1/2 -translate-y-1/2 rounded-[50px] border border-solid border-2px border-black relative z-0"
                      />
                    </div>
                    <div className="relative flex justify-center items-center">
                      <div className="top-[30px] transform -translate-y-1/2 flex justify-center items-center">
                        <div>
                          <h3 className="font-bold text-black text-[15px] ">
                            {stake.staking.token1.tokenSymbol}/{stake.staking.token2.tokenSymbol} STAKING
                          </h3>
                        </div>
                      </div>
                    </div>

                    <div className="relative flex justify-center items-center pulsate2">
                      <div className="top-[20px] transform -translate-y-1/2 flex justify-center items-center">
                        <h3 className="text-black text-[10px]">
                          Stake {stake.staking.token1.tokenSymbol} Earn {stake.staking.token2.tokenSymbol}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 flex items-center justify-between mt-3">
                <span className="text-xs font-medium text-gray dark:text-gray-dark">
                  Total Rewards:
                </span>
                <div className="flex items-center">
                  <span className="text-sm ml-2 font-bold text-dark-text dark:text-light-text">
                    {stake.staking.rewardAmount} {stake.staking.token2.tokenSymbol}
                  </span>
                </div>
              </div>
              <div className="mt-3 flex items-center justify-between mt-3">
                <span className="text-xs font-medium text-gray dark:text-gray-dark">
                  Reward perBlock:
                </span>
                {(stake.staking.chainID === 84532 || stake.staking.chainID === 84532) && (
                <div className="flex items-center">
                  <span className="text-sm ml-2 font-bold text-dark-text dark:text-light-text">
                    {(stake.staking.rewardAmount / (stake.staking.duration * 86400 / 3)).toFixed(8)} {stake.staking.token2.tokenSymbol}
                  </span>
                </div>
              )}
              {stake.staking.chainID === 56 && (
              <div className="flex items-center">
                <span className="text-sm ml-2 font-bold text-dark-text dark:text-light-text">
                  {(stake.staking.rewardAmount / (stake.staking.duration * 86400 / 15)).toFixed(8)} {stake.staking.token2.tokenSymbol}
                </span>
              </div>
              )}
              </div>

                <div className="flex items-center justify-between mt-3 mb-2">
                  <span className="text-xs font-medium text-gray dark:text-gray-dark">
                    Duration:
                  </span>
                  <span className="text-sm ml-2 font-bold text-dark-text dark:text-light-text">
                    {stake.staking.duration + " " + "days"}
                  </span>
                </div>
                {stake.staking.status !== "Ended" && (
                  <div className="flex items-center justify-between mt-3 p-1 border border-dashed border-dim-text borderRaduis border-opacity-30">
                    <span className="text-xs font-medium text-gray dark:text-gray-dark">
                      {stake.staking.status === "Live" ? "Ends in:" : "Starts in:"}
                    </span>
                    <Timer
                      date={
                        stake.staking.status === "Live"
                          ? new Date(stake.staking.endDate * 1000)
                          : new Date(stake.staking.startDate * 1000)
                      }
                    />
                  </div>
                )}
            </div>
          </div>
        </Link>
      ))}
  </div>
);
}
