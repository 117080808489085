// import { BSC } from '@usedapp/core'
// import { BscTestnet } from './chain'
import { Vanar, CoreChain, BccTest } from './testchain'

export const SWAP_FACTORY = {
  [CoreChain.chainId]: "",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const SWAP_ROUTER = {
  [CoreChain.chainId]: "",
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const MULTICALL_ADDRESS = {
  [CoreChain.chainId]: "0xFBc9360C283BFFB8792B0424c1FcB60972b2bfaA",
  [Vanar.chainId]: "0x73C98555cEd0d331D7B94f7B1eCd2cbF617D068d",
  [BccTest.chainId]: "",
}

export const RPC_ADDRESS = {
  [CoreChain.chainId]: 'https://rpc-core.icecreamswap.com',
  [Vanar.chainId]: "https://rpc-partners.vanarchain.com",
  [BccTest.chainId]: "",
}

export const ADMIN_ADDRESS = {
  [CoreChain.chainId]: '0xbC191d3a91903997661d6572fC3ABE686613A395',
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const FACTORY_ADDRESS = {
  [CoreChain.chainId]: '0xae1C1C2703C9F145298BBFee37a8Cfe3F0B8a558',
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}

export const AIRDROP_FACTORY_ADDRESS = {
  [Vanar.chainId]: "",
  [BccTest.chainId]: "",
}
