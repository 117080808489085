import { useEffect, useState } from 'react';

export const useNativePrice = (chainId) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (chainId === 8082) {
          response = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=wrapped-core&vs_currencies=usd`);
        } else if (chainId === 2040) {
          response = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=vanry&vs_currencies=usd`);
        } else {
          console.error('Invalid chainId:', chainId);
          return;
        }

        const responseData = await response.json();
        setData(responseData);
      } catch (error) {
        console.error('Unable to fetch data:', error);
      }
    };

    fetchData();
  }, [chainId, setData]);

  return data;
};
