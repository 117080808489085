import React, { useContext, useState, useEffect } from "react";
import PreviewHeader from "../../Common/PreviewHeader";
import Info from "./Subcomponents/Info";
import TabSwitch from "./Subcomponents/TabSwitch";
import DonutChart from "./Subcomponents/DonutChart";
import TwitterSVG from "svgs/Socials/twitter";
import DribbleSVG from "svgs/Socials/dribble";
import PreviewDetails from "components/Common/PreviewDetails";
import DiscordSVG from "svgs/Socials/discord";
import TelegramSVG from "svgs/Socials/telegram";
import AuditSVG from "svgs/Socials/audit";
import KycSVG from "svgs/Socials/kyc";
import { formatBigToNum } from "utils/numberFormat";
import { Link } from "react-router-dom";
import GithubSVG from "svgs/Socials/github";
import { ThemeContext } from "context/ThemeContext/ThemeProvider";
import YouTubeEmbed from "./Subcomponents/YoutubeImbed";
import ProjectDetails from "../CreateStaking/ProjectDetails";
import ConfirmModal from "../Admin/subComponents/ConfirmModal";

const vanarBlock = 3;
const coreBlock = 3;

export default function Preview({
  rewardAmount,
  symbol1,
  symbol2,
  currency,
  chainId,
  stake,
  name1,
  name2,
  min_buy,
  max_buy,
  icon1,
  icon2,
  stakingType,
  token,
  description,
  address,
  ends_on,
  objId,
  admin,
  finished,
  cancelled,
}) {
  const { theme } = useContext(ThemeContext);
  const [slide, setSlide] = useState("Info");
  const [edit, setEdit] = useState(false);
  const [stakingData, setStakingData] = useState({ ...stake });
  const [showModal, setShowModal] = useState(false);
  const [block, setBlock] = useState(vanarBlock);

  useEffect(() => {
    if (chainId === 1116) {
      setBlock(coreBlock);
    } else if (chainId === 2040) {
      setBlock(vanarBlock);
    }
  }, [chainId]);


  return (
    <>
      {edit ? (
        <ProjectDetails
          setActive={setEdit}
          saleData={stakingData}
          setSaleData={setStakingData}
          edit={edit}
          objId={objId}
        />
      ) : (
        <div className="px-9 py-9 relative ">
          <div className="flex flex-col">

          <img
            src={stake.banner || "https://cdna.artstation.com/p/assets/images/images/060/746/238/original/feon-cityundermoon-pixel-art.gif"}
            className="w-full h-[150px] border border-2px border-black rounded-[20px]"
            />

          <Info
            name1={name1}
            name2={name2}
            icon1={icon1}
            icon2={icon2}
            stake={stake}
            setEdit={setShowModal}
            edit={showModal}
            admin={admin}
          />
          </div>
          <div className="mt-6 flex md:hidden gap-5 ml-[70px]">
            {stake.github !== "" && (
              <Link to={stake.github} target="_blank" rel="noopener noreferrer">
                <GithubSVG
                  className="w-5 h-5"
                  outer={`${theme === "dark" ? "#fff" : "#464754"}`}
                  inner={`${theme === "dark" ? "#464754" : "#fff"}`}
                />
              </Link>
            )}
            {stake.discord !== "" && (
              <Link to={stake.discord} target="_blank" rel="noopener noreferrer">
                <DiscordSVG className="fill-dark-text dark:fill-light-text " />
              </Link>
            )}
            {stake.telegram !== "" && (
              <Link
                to={stake.telegram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TelegramSVG className="fill-dark-text dark:fill-light-text " />
              </Link>
            )}
            {stake.twitter !== "" && (
              <Link to={stake.twitter} target="_blank" rel="noopener noreferrer">
                <TwitterSVG className="fill-dark-text dark:fill-light-text" />
              </Link>
            )}{" "}
            {stake.website !== "" && (
              <Link to={stake.website} target="_blank" rel="noopener noreferrer">
                <DribbleSVG className="fill-dark-text dark:fill-light-text " />
              </Link>
            )}
          </div>

          <TabSwitch slide={slide} setSlide={setSlide} />

          {slide === "Info" && (
            <div className="mt-5">
            <div className="mt-10">
              <span className="font-medium text-sm text-gray dark:text-gray-dark">
                {stake.description}
                {stake.youtube && stake.youtube !== "" && (
                  <YouTubeEmbed embedCode={stake.youtube} />
                )}
              </span>
            </div>
            </div>
          )}

          {slide === "Staking" && (
            <div className="mt-5">
              <PreviewDetails
                name={"Staking Address"}
                value={stake.stakingAddress}
                enable_copy
                address={true}
              />
              <PreviewDetails
                name={"Staking Token Address"}
                value={stake.token1.tokenAddress}
                enable_copy
                address={true}
              />
              <PreviewDetails
                name={"Reward Token Address"}
                value={stake.token2.tokenAddress}
                enable_copy
                address={true}
              />
              <PreviewDetails
                name={"Total Rewards"}
                value={rewardAmount + " " + stake.token2.tokenSymbol}
              />
              {(stake.chainID === 84532 || stake.chainID === 1116 || stake.chainID === 2040) && (
                <PreviewDetails
                  name={"Reward perBlock"}
                  value={(stake.rewardAmount / (stake.duration * 86400 / 3)).toFixed(8) + " " + stake.token2.tokenSymbol}
                />
            )}
            <PreviewDetails
              name={"Min Stake"}
              value={stake.minAllocation + " " + stake.token1.tokenSymbol}
            />
            <PreviewDetails
            name={"Max Stake"}
            value={stake.maxAllocation + " " + stake.token1.tokenSymbol}
            />
              <PreviewDetails
                name={"Duartion (days)"}
                value={stake.duration + " " + "Days"}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}
