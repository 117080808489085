import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useEthers, useTokenBalance } from '@usedapp/core';
import { useDefaultChainId } from 'config/useDefaultChainId';
import { formatEther } from 'ethers/lib/utils';
import { Line } from 'react-chartjs-2';
import CandlestickChart from './chart'
import 'chart.js/auto';

const shortenAddress = (address) => {
  if (!address) return "";
  return `${address.substring(0, 6)}....${address.substring(address.length - 4)}`;
};

const getMonthName = (monthIndex) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[monthIndex];
};

const App = () => {
  const [portfolio, setPortfolio] = useState([
    { name: 'Bitcoin', symbol: 'btc', amount: 1.5 },
    { name: 'Ethereum', symbol: 'eth', amount: 10 },
    { name: 'Cardano', symbol: 'ada', amount: 500 },
  ]);
  const chainId = useDefaultChainId();

  const [prices, setPrices] = useState({});
  const { account } = useEthers();
  const [ethBalance, setEthBalance] = useState(0);
  const [balanceHistory, setBalanceHistory] = useState([]);

  const { library } = useEthers();

  const tokenAddresses = {
    eth: '0x0000000000000000000000000000000000000000',
  };

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await axios.get('https://api.coingecko.com/api/v3/simple/price', {
          params: {
            ids: portfolio.map((coin) => coin.symbol).join(','),
            vs_currencies: 'usd',
          },
        });
        setPrices(response.data);
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    };

    fetchPrices();
  }, [portfolio]);

  useEffect(() => {
    if (account && library) {
      const fetchBalance = async () => {
        const balance = await library.getBalance(account);
        const formattedBalance = parseFloat(formatEther(balance)).toFixed(4);

        // Get the current date and time
        const now = new Date();
        const monthName = getMonthName(now.getMonth()); // Get month name abbreviation

        // Check if balance has changed
        if (formattedBalance !== ethBalance) {
          setEthBalance(formattedBalance);

          // Update balance history
          const newBalanceHistory = [...balanceHistory, { time: monthName, balance: formattedBalance }];
          setBalanceHistory(newBalanceHistory);
          localStorage.setItem('balanceHistory', JSON.stringify(newBalanceHistory));

          // Send balance data to backend
          await axios.post('http://localhost:5000/api/balances', {
            account,
            balance: formattedBalance,
            time: monthName,
            chainId
          });
        }
      };

      fetchBalance();

      // Fetch balance at regular intervals (e.g., every 10 minutes)
      const interval = setInterval(fetchBalance, 60 * 60 * 1000);

      return () => clearInterval(interval);
    }
  }, [account, library, ethBalance, balanceHistory]);

  useEffect(() => {
    // Load balance history from localStorage
    const savedBalanceHistory = localStorage.getItem('balanceHistory');
    if (savedBalanceHistory) {
      setBalanceHistory(JSON.parse(savedBalanceHistory));
    }
  }, []);

  const tokenBalance = useTokenBalance(tokenAddresses.eth, account);

  const chartData = {
    labels: balanceHistory.map(entry => entry.time),
    datasets: [{
      label: 'ETH Balance',
      data: balanceHistory.map(entry => entry.balance),
      fill: true,
      backgroundColor: 'rgba(0, 0, 0, 0)', // Menghilangkan warna latar belakang
      borderColor: 'rgb(75, 192, 192)', // Warna garis chart
      tension: 0.1,
      pointRadius: 3,
      pointBackgroundColor: 'rgb(75, 192, 192)',
      pointBorderColor: 'rgb(75, 192, 192)',
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgb(75, 192, 192)',
      pointHoverBorderColor: 'rgb(75, 192, 192)',
    }],
  };

  const chartOptions = {
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          callback: function(value, index, values) {
            if (typeof value === 'string') {
              return '$'; // Mengembalikan simbol $
            }
            return value;
          },
          maxRotation: 0,
          minRotation: 0
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    },
    elements: {
      line: {
        borderWidth: 2
      }
    }
  };




  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <header className="flex flex-col lg:flex-row justify-between bg-gradient-to-r from-cyan-500 to-blue-500 text-white p-4 mb-4">
        <div>
          <h1 className="text-xl font-bold">Hi {shortenAddress(account)}</h1>
          <p>Welcome Back 👋</p>
        </div>
        {chainId === 2040 && (
          <div className="flex flex-col lg:flex-row gap-2 lg:gap-10 text-xl font-bold mt-4 lg:mt-0">
            <p className="p-2 border-2 border-blue-500 rounded-md">Vanry Balance: {ethBalance} Vanry</p>
            <p>Token Balance: {tokenBalance ? parseFloat(formatEther(tokenBalance)).toFixed(4) : 'Loading...'}</p>
          </div>
        )}
        {chainId === 1116 && (
          <div className="flex flex-col lg:flex-row gap-2 lg:gap-10 text-xl font-bold mt-4 lg:mt-0">
            <p>CORE Balance: {ethBalance}</p>
            <p>Token Balance: {tokenBalance ? parseFloat(formatEther(tokenBalance)).toFixed(4) : 'Loading...'}</p>
          </div>
        )}
      </header>
      <main className="bg-white p-4 rounded shadow">
        <h2 className="text-lg font-semibold mb-2">ETH Balance History</h2>
        <div className="h-96 flex items-center justify-center">
          <Line data={chartData} options={chartOptions} />
        </div>
      </main>
      <section className="bg-white p-4 rounded shadow mt-4">
        <h2 className="text-lg font-semibold mb-2">History</h2>
        <div className="h-64 bg-gray-200 flex items-center justify-center">
          <CandlestickChart />
        </div>
      </section>
    </div>
  );
};

export default App;
