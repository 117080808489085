// Updated Networkswitch.js
// import { BSC } from '@usedapp/core';
import { Vanar, CoreChain } from 'config/constants/testchain';

export const Networkswitch = [
  {
    iconimg: "/images/cards/Vanar.png",
    title: "VANAR",
    subtitle: "Connected",
    status: false,
    isActive: false,
    chainId: Vanar.chainId,
  },
  {
    iconimg: "/images/headericons/core.png",
    title: "CORE",
    subtitle: "Not Connected",
    status: false,
    isActive: false,
    chainId: CoreChain.chainId,
  },
  // {
  //   iconimg: "/images/headericons/zeta.png",
  //   title: "ZETA",
  //   subtitle: "Not Connected",
  //   status: false,
  //   isActive: false,
  //   chainId: ZetaChain.chainId,
  // },
];
